import React from 'react';
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";


const LogoutComponent = ({onClickNo, deleteItem, loading, feathersStore})=> {
  
  let common = useTranslate(feathersStore.language, commonEL, commonEN); 

  const logoutFromServerAndGoogle = () => {   
    deleteItem();
  }

  return (
    <div className="column-flex-center grey-background pt-1">
      <h3 id="logouttitle">{common.warning}</h3>
      <p>{common.logoutWarning}</p>
      <div className="addNewAddressButtonsContainer">
        <GreenSubmitButton
        aria-labelledby='No'
          title={common.no}
          loading={false}
          disabled={false}
          onClick={onClickNo}
          autoFocus
        />
        <h3 style={{color: 'grey'}}>|</h3>
        <GreenSubmitButton
          title={common.yes}
          loading={loading}
          disabled={false}
          aria-labelledby='Yes'
          onClick={ logoutFromServerAndGoogle}
        />
    </div>
  </div>
  );
}

export default inject('feathersStore')(observer(LogoutComponent));