import React, {useState, useEffect} from 'react';
import Camera from './camera';
import Colors from '../../components/assets/colors'
import Lock from '@mui/icons-material/Lock'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ExpandMore from '@mui/icons-material/ExpandMore';
import 'react-slidedown/lib/slidedown.css'
import ChangePassword from './changePassword'
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import { 
    FormControl,
    FormHelperText,
    Input    
  } from '@mui/material';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils';
import { CSSTransition } from 'react-transition-group';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {accountEL} from "../../locales/el/account";
import {accountEN} from "../../locales/en/account";
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

let firstNameElement;
let lastNameElement;
let phoneElement;

const ProfileSettings = ({feathersStore})=> {

  let account = useTranslate(feathersStore.language, accountEL, accountEN);
  let common = useTranslate(feathersStore.language, commonEL, commonEN); 
  
  const [email, setEmail] = useState(feathersStore.user?.email); 
  const [firstName, setFirstName] = useState(feathersStore.user?.firstname); 
  const [lastName, setLastName] = useState(feathersStore.user?.lastname); 
  const [phone, setPhone] = useState(feathersStore.user?.phone); 
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [loading, setLoading] = useState(false);  
  const [ activeDropdown, setActiveDropdown] = useState(false);
    
  const firstNameChange = event => {    
    setFirstName(event.target.value);
    firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    phoneValidation(event.target.value);   
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  } 

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
      phoneElement.focus();
    }
  }  

  const enterPhonePressed = (ev) => {   
    if (ev.key === 'Enter') {
      firstNameElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      updateUser();
    }
  }

  const updateUser = () => {
    setLoading(true);
    feathersStore.patchUser(feathersStore.user._id, firstName, lastName, phone)
    .then(user => {  
      feathersStore.setUser(user);
       setLoading(false);   
      alert(account.updateAlert);
    })
    .catch(error => {
      setLoading(false);   
      alert(`${account.updateError}:  ${error.message}`)
    });    
  };

  return(
    <div className="column-flex-start-wrap">
      <div className="change-pass-container">
        <div>
          <Camera />
        </div>
        <div className="settingsFieldSub">
          <FormControl fullWidth={false}> 
            <Input
              value={firstName}
              error={firstNameError}
              inputRef={r => {
                firstNameElement = r;
              }}
              aria-label='firstname'
              onChange={firstNameChange}
              onKeyPress={enterFirstNamePressed}
              placeholder={common.firstName + "*"}
            />
            {firstNameError && <FormHelperText id="component-error-text" >{common.firstNameError}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={false}>
            <Input
              value={lastName}
              error={lastNameError}
              inputRef={r => {
                lastNameElement = r;
              }}
              aria-label='lastname'
              onChange={lastNameChange}
              onKeyPress={enterLastNamePressed}
              placeholder={common.lastName + "*"}
            />
            {lastNameError && <FormHelperText id="component-error-text" >{common.lastNameError}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={false} disabled={true}> 
            <Input
              value={email} 
              placeholder={common.email + "*"}
              aria-label='email'
            />
          </FormControl>
          <FormControl fullWidth={false}>    
            <Input
              value={phone}     
              error={phoneError}
              inputRef={r => {
                phoneElement = r;
              }}
              aria-label='phone'
              onChange={phoneChange}              
              onKeyPress={enterPhonePressed}   
              placeholder={common.phone + "*"}
            />
            {phoneError && <FormHelperText id="component-error-text" >{common.phoneError}</FormHelperText>}
          </FormControl>
        </div>
      </div>
      <div className="row-flex-center">
        <GreenSubmitButton 
          title={common.save}
          disabled = {!firstName || !lastName || !phone|| 
            firstNameError || lastNameError || phoneError}
          loading={loading}
          onClick={updateUser} 
        /> 
      </div>  
      <div className="change-password-container">
        <div className="changePassTitle">
          <Lock style={{color: Colors.settingsText, fontSize: 30}}/>
          <h4 style={{color: Colors.settingsText}}>{account.psw}</h4>
        </div>
        {activeDropdown ? 
        <button onClick={() => setActiveDropdown(false)} aria-label='Cancel icon'>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => setActiveDropdown(true)} aria-label='Expand more'>
          <ExpandMore style={{color: 'red', fontSize: 35}}/>
        </button>   
        }
      </div>
        <CSSTransition
          in={activeDropdown}
          timeout={300}
          classNames="my-alert"
          unmountOnExit
        >
        <ChangePassword />
      </CSSTransition>
    </div>
  )
}

export default inject('feathersStore')(observer(ProfileSettings));