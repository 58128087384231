export const commonEN = {
    firstName : "First Name",
    lastName : "Last Name",
    street : "Street",
    streetNumber : "Number",
    city : "City",
    origin : "Region",
    postcode : "ZIP",
    phone : "Phone number",
    apartment : "Apartment",
    email : "E-mail",
    emailMessage : "We'll never share your email with anyone else",
    checkout: "Checkout",
    streetAndNumber : "Street & Number",
    privacyStatement : "Privacy Statement",
    termsOfService : "Terms of service",
    termsOfServiceNoun : "Terms of service",
    aboutUs : "About us",
    product : "Product",
    products : "Products",
    subTotal : "Sub-total",
    transportation : "Transportation",
    total : "Total",
    price : "Price",
    quantity : "Quantity",
    items: "Items",
    firstNameError : "Please Enter a first name",
    lastNameError : "Please Enter a last name",
    phoneError : "Please Enter a valid phone number",
    emailError : "Please Enter a valid Email",
    save : "SAVE",
    updateSuccess : "The update was successful",
    updateError : "Error while updating",
    updated : "Updated!",
    cartUpdated : "You updated succesfully your shopping cart!",
    wishlistUpdated : "You updated succesfully your wishlist!",
    streetError : "Please Enter a street",
    streetNumberError : "Please Enter a street number",
    cityError : "Please Enter a city",
    order : "Order",
    logout : "Logout",
    warning : "WARNING",
    logoutWarning : "Are you sure you want to logout?",
    yes : "YES",
    no : "NO",
    loginRegistration : "Login/Register",
    login : "LOGIN",
    passwordHelperText : "at least 8 chars including numbers, uppercase and lowercase",
    forgotPassword : "Forgot password?",
    or : "or",
    facebookLogin : "LOGIN WITH FACEBOOK",    
    googleLogin : "LOGIN WITH GOOGLE",
    register : "Registration",
    registerButton : "NEW CUSTOMER",
    exit : "EXIT",
    resetPassword : "Reset Password",
    resetPasswordInfoA : "Please enter your e-mail",
    resetPasswordInfoB : "and you will get a new password.",
    registerA : "REGISTER",
    registerInfoA : "With my registration, I accept the",
    registerInfoB : "general terms of service",
    registerInfoC : "the",
    registerInfoD : "Privacy Statement",
    registerInfoE : "and the",
    registerInfoF : "terms of service of the offers",
    verificationCode : "Confirm Code",
    verificationInfo : "Please enter the 2 digit code that was sent to :",
    enterCode : "Enter Code",
    errorCode : "Wrong Verification Code, Please try again",
    repeat : "RESEND",
    youSee : "You see",
    from : "from",
    stockWarning: "There is no more stock.",
    leaveAComment: "Leave a comment...",
    saveReview: "Save the Review",
    reviews: "REVIEWS",
    qrCodeBuy: "Buy fast and easy with one click",
    qrCodeScanAndroid: "Scan to download our app for Android",
    qrCodeDownload: "Download our app in Android",
    qrCodeBuyMobile: "Download our app with one click and start shopping",    
    qrCodeScanIos: "Scan to download our app for IOs",    
    qrCodeForAndroid: "For Android",
    qrCodeForIos: "For IOs",    
    photoUpload: "Photo upload",    
    edit: "EDIT",
    delete: "DELETE",
    addCart: "Add to cart",
    noEvaluation: "No evaluation yet.",
    onlyLoggedInCustomers: "Only logged in customers who have bought the product can write a review.",
    beTheFirstReview: "Be the first who writes a review for this product.",
    seeMoreProducts: "See more products",
    favorite: "FAVORITES",
    noFavoritesYet: "You don't have favorite products yet",
    deleteProduct: "Are you sure you want to remove this product?",
    deleteProduct1: "Are you sure you want to remove this product",
    fromCart: "from the cart?",
    deleteAddress: "Are you sure you want to delete the address?",
    danger: "WARNING",
    connectionIssue: "Caution! Sending the order was not successful. Please check your connection.",
    sendOrder: "Send Order",
    pleaseWait: "Please wait...",
    noMoreProductsInStock: "You can not add in your cart more products than the stock",
    productAddedCart: "Product added to your cart!",
    home: "Home",
    myAccount: "myAccount",
    ckeckout: "CHECKOUT",
    all:"All",
    signIn:"SignIn",
    search: "Search",
    productSKU: "SKU",
    productSKUFull: "Product SKU:",
    language: "Language"
}