import React from 'react';
import {Link} from 'react-router-dom';

const FaqText = ()=> {
  return(
    <div className="terms-text">
      <h2>Ποιούς τρόπους πληρωμής δέχεστε;</h2>
      <p>
        Μπορείτε να δείτε τους τρόπους πληρωμής <Link className="privacy-text" to="/paymentoptions">εδώ</Link>.
      </p>    
      <h2>
        Με ποιούς τρόπους μπορώ να παραλάβω την παραγγελία μου;
      </h2>
      <p>
        Μπορείτε να δείτε τους τρόπους αποστολής <Link className="privacy-text" to="/deliveryoptions">εδώ</Link>.
      </p>
      <h2>
        Μπορώ να επιστρέψω ένα προϊόν;
      </h2>
      <p>        
        Εάν ο πελάτης είναι φυσικό πρόσωπο (καταναλωτής) που καταρτίζει την πώληση για προσωπική χρήση κι όχι για την εξυπηρέτηση της ατομικής του δραστηριότητας, τότε δικαιούται να υπαναχωρήσει από την πώληση εντός 14 ημερολογιακών ημερών, επιστρέφοντας το προϊόν στην αρχική του κατάσταση, επιβαρυνόμενος με τα έξοδα επιστροφής, σύμφωνα με την § 10 του άρθρου 4 Ν. 2251/1994.<br/>
        
        Δικαιούστε να υπαναχωρήσετε αναιτιολογήτως από την εξ’ αποστάσεως αγορά, εγγράφως, εντός 14 ημερολογιακών ημερών επιστρέφοντας το προϊόν στην αρχική του κατάσταση. Το δικαίωμα αυτό εκκινεί από την ημέρα παραλαβής των προϊόντων. Η εντός 14 ημερολογιακών ημερών αποστολή της δήλωσης υπαναχώρησης ή αποστολή των προϊόντων, θα θεωρείται αρκετή για την εμπρόθεσμη άσκηση του δικαιώματος υπαναχώρησης. Η δήλωση αποστέλλεται προς το κατάστημα με εμπορικό σήμα woodpeckerart.gr με τη μορφή φόρμας RMA.<br/>
        
        Τα προϊόντα επιστρέφονται με έξοδα του πελάτη ή προσκομίζονται από τον ίδιο τον πελάτη στην έδρα της επιχείρησης που εδρεύει Ηρώων Πολυτεχνείου 77, Ελευσίνα.<br/>
        
        Συνέπειες υπαναχώρησης<br/>
        
        Σε περίπτωση έγκυρης άσκησης του δικαιώματος υπαναχώρησης, θα πρέπει να αποκατασταθεί με χρηματική αποζημίωση κάθε τυχόν απομείωση της αξίας του εμπορεύματος, μόνο στην περίπτωση και στον βαθμό που αυτή οφείλεται στη μεταχείριση του προϊόντος που εκφεύγει της ειθισμένης εξέτασης των χαρακτηριστικών και της λειτουργικότητας του προϊόντος. Ως «ειθισμένη εξέταση των χαρακτηριστικών και της λειτουργικότητας του προϊόντος», εννοείται η δοκιμή των σχετικών αγαθών, όπως αυτή είναι δυνατό να ελάμβανε χώρα και όπως συνηθίζεται σε ένα φυσικό κατάστημα.<br/>
        
        Ο πελάτης θα βαρύνεται με τα έξοδα επιστροφής. Κάθε χρηματική υποχρέωση για την καταβολή ποσών (π.χ. εξόδων μεταφοράς, αποζημίωσης) θα πρέπει να εκπληρώνεται εντός 30 ημερών, εκκινώντας από τον χρόνο αποστολής της δήλωσης υπαναχώρησης από εσάς ή της επιστροφής του εμπορεύματος.<br/>
        
        Το δικαίωμα υπαναχώρησης εκ του άρθρου 4 § 10 ν. 2251/1994 δεν εφαρμόζεται:<br/>
        
        Στις περιπτώσεις που το τίμημα των προϊόντων έχει καταβληθεί στο φυσικό μας κατάστημα ( Ηρώων Πολυτεχνείου 77, Ελευσίνα ) και ταυτόχρονα, αυτά έχουν παραληφθεί από το φυσικό κατάστημα, καθώς η πώληση δεν θεωρείται ότι τελέστηκε από απόσταση.<br/>
        
        Σε περίπτωση έγκυρης άσκησης του δικαιώματος υπαναχώρησης και με την προϋπόθεση ότι έχει επιστραφεί το προϊόν στην έδρα του woodpeckerart.gr, η αξία του προϊόντος θα επιστρέφεται στον πελάτη. Ρητώς συμφωνείται και ο πελάτης παρέχει ήδη την ανέκκλητη εντολή και συναίνεσή του προς τούτο, η εταιρεία μας να δικαιούται να παρακρατά από το επιστρεφόμενο ποσό τα έξοδα επιστροφής, τα οποία βαρύνουν τον πελάτη καθώς και τυχόν αποζημίωση σε περίπτωση καταστροφής ή απομείωσης της αξίας του προϊόντος λόγω φθοράς ή βλάβης του προϊόντος οφειλόμενης σε ενέργειες του πελάτη.<br/>
        
        Ανάλογα με τον τρόπο πληρωμής που έχει επιλέξει ο πελάτης κατά την παραγγελία του θα  γίνει αντίστοιχα και η επιστροφή χρημάτων:<br/>
        
        Για Πιστωτική κάρτα on-line η επιστροφή των χρημάτων θα γίνει στην κάρτα.<br/>
        Για Αντικαταβολή ή κατάθεση σε τράπεζα θα πρέπει να μας δώσετε το IBAN ενός τραπεζικού λογαριασμού, το ονοματεπώνυμο του δικαιούχου όπως και την τράπεζα την οποία αφορά.
      </p>
      <h2>
        Μπορώ να κάνω κάποια αλλαγή στην παραγγελία μου;
      </h2>
      <p>
        Μπορείτε να κάνετε οποιαδήποτε αλλαγή πριν την αποστολή της παραγγελίας σας. 
        Τηλεφωνήστε μας στο 2114115591 ή επικοινωνήστε μαζί μας μέσω της φόρμας επικοινωνίας εδώ 
        ή στο email info.appdate@gmail.com αναφέροντας τον αριθμό της παραγγελίας σας και τις αλλαγές που θέλετε να κάνετε. 
        Θα επιβεβαιώσουμε τη νέα παραγγελία με νέα αποστολή email.
      </p>
      <h2>
        Υπάρχει κάποιο κατάστημα που μπορώ να επισκεφθώ;
      </h2>
      <p>
        Μπορείτε να μας επισκεφθείτε στις ώρες καταστημάτων στη διέυθυνση μας Οδός Μεσογείων 131, Μαρούσι 15126.
      </p>
    </div>
  )
}
    
export default FaqText;