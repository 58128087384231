import React from 'react';
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { setConfig } from 'react-google-translate'
import reportWebVitals from './reportWebVitals';
import './index.css';
 
setConfig({
  clientEmail: "appdate-e-menu@appspot.gserviceaccount.com",
  privateKey: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCzYZPJNmHZijbQ\naWA2ivl/PWQIpw4ON/HlG0VjOQ7WDJqtc8nBQcYRE/mLyPHUOfZs2yhF6CfEWfV6\n8PHaBrK7kXf9NqoDiUbpvRPGUfAgh/nRl60aDDfkwoFKCBYfWj+d2hKB4IpTwL/l\n4tJIh4paFzZ22hoidi77KcAugjoamtpNfwQpRxe/OJPqJ/ZQxoh2d8t6F3t9ehkg\nPWCHmbj1XSsJKVFG7hHVHAuXDWP6H7+UxVB191Vv7CtUDX7PPqAm1ZyhyHU/U/NW\nYtIBbV0mnS3q4RAlOJ+KYFFrMLUNVUM4g/uyBqVrwV5xVjF6YAOZirZLuVUAQusH\n1sfcpO2zAgMBAAECggEABSYC0N6ftleyNQ8hNM+OU6mWam0pCR1v8uxydGOZ2mjo\nM5xEKSjTH4BcRDfTQQ0c1oj/wenk4kzWr92kg2Z30MxfDkR4YruY7nJ/08xcQQLG\nF87rp/i7zfQLM+3BfMrhUo2ExHeGYNsCZv24nV5OiUFrTcP6xgxwUsMjAfK46jSF\nRWhGKPfrdHbW7UOHSozDBFKRw7InQZsykSBuv2OnKvGOztlYSxeWW5rHCScWmj/u\n6SyvzDfBknN6BodO1f3r8FBEYig41kOI3fC48QCSPY/lE4aDgESdwiVBHP3ILA7x\nLKWkVBgDW3gOTflXXE+5hzFc0HQ0KQ18235HO30xiQKBgQDlCzExHm7o3pza+VMi\nzcPlrNez5ux7kZIi6UQ6g96dETBfLCXbC795DfgvTy3mNoJkFl9+4Eoxhw2Rwgpi\npdTKDlb0vN/N9YtyGbL8P/wVcA+fTOMRVMLsnrok31cGr0jtJjkjR3C4wGxjVDJN\nZiYD1kA/998w0SPj2wIkWknCrwKBgQDIfhzjV1IRsz9lFyYjHkprHLOK0El9+xZY\n+kYPlSo5nZPbL+BUSIktPAeZ3+CdBUbp1l8nVtwfY+U0LUEe+qD3IqyP4hq8/2xZ\nS7an7ZrBxlJnXSEa4VHmzmLoDj8NP9GleO9kFrPw0pulgRWOHMoDLTCaSEgGV/+a\nBvoaWrGWPQKBgQDUnw0tUdkXp//obuc0OduOzGL5CHejRkFo0y6ZO21eVWSYFP2X\nWFKI5+Tq1g//0747d2FzAHcffwWjt4O4i8T7RVCHpQYFxdmLNDwpWqSCqiJ2eQOZ\nRRwmWDrom8PH2tRSAdes3lbPyyFTHAgOPbMwgKXoPAwe3yb4EIjugjpS1QKBgClj\nZ2FDEG0Y/LhIrBXJ1gEAnsvELbe1Eqrxl272Ew5ryi8QVhXwF2lgIA2S2tJvWXFC\nUMhVNQHB5Hn98ZiMkpgR71+QhIaJj/trFtzZvv3LjA1Me14KL5ce+en/ruJ0TzoC\nmiCmUyUexQTIfgOlS8qWFJd03nF4zwoj8JNqL4jdAoGAA0Q3LB+e6XrphHIsCR2g\nxCmrBNHBst7v+yeKdlGXovWcLHsaV4o+zvjk0C6oi0qltI6DZWH1VnJkeATwv40b\ngKv+EX35eIBDy0iz3BSIuelEbpQR0PjBsNH8tV8/oxXyzRgQ2MQ0Ybua9tJCtZB3\nHdib9p0tfrUqELdmucmtGUg=\n-----END PRIVATE KEY-----\n",
  projectId: "109020526420410730649"
})

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();