export const footerEN = {
    contactHours : "Contact Hours",
    week : "Monday – Friday",
    contactUs : "Contact us",
    address : "131 Mesogeion str. Marousi 15126",
    findUs : "Find us on the ",
    map : "map",
    contact: "Contact us",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    stayConnected: "Stay connected",
    cookiesTitle: "Cookies Usage",
    aboutUs : "About us",
    cookiesText: "We only use the minimum required cookies. Your personal data are not stored in any of them.",
    cookiesAccept: "Accept",  
    accessibilityTitle:  "Accessibility",
    fontIncrease: "Font size increase",
    fontDecrease: "Font size decrease",
    greyscale: "Greyscale",
    highContrast: "High contrast",
    negativeContrast: "Negative contrast",
    lightBackground: "Light Background",
    linksUnderline: "Links underline",
    readableFont: "Readable font",
    reset: "Reset",
    paymentOptions: "Payment Methods",
    deliveryOptions: "Delivery Methods"  ,
    messagingTitle: "Support",
    messagingSubTitle: "Send a message with your questions!",
    message: "Message",
    faq: "FAQ's"  
}