import React from 'react';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import ProductCard from '../cards/productCard/ProductCard';
import {LeftArrow, RightArrow} from '../buttons/arrows';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {offersEL} from "../../locales/el/offers";
import {offersEN} from "../../locales/en/offers";

const Popular = ({ feathersStore, handleAddToCart, getPrice, getOldPrice, getBadges, handlePress
  , popularProducts,  onPressAddToFavorites })=> {

  let offers = useTranslate(feathersStore.language, offersEL, offersEN);
  
  const getMainImage = (product)=> {
    let list = getImageArr(product);
    const mainImg = list.find(img => img.focused);  
    return mainImg;          
  }

  const getImageArr = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images
          .map(image => Object.assign(image, {variation: variation.name}))) //Add variation name to the image
        .flat();
    }else{
      imageArr = product.images              
    }  
    return imageArr;
  } 

  const bestSellersIterator = popularProducts.map((product, index) => {    
    return (  
     // <MenuItem key={product._id}>
      <ProductCard
        key={product._id}
        itemId={index}
        stock={product.handleStock && product.stock}
        image={getMainImage(product)}
        title={(feathersStore.language === 'en') && product?.nameEnglish ? product.nameEnglish : product.name}
        price={getPrice(product)}
        oldPrice={getOldPrice(product)}
        onClick={()=> handlePress(product, true)}
        onClickCart={() => handleAddToCart(product, index)}
        hasVariation={product.hasVariation}
        hasOtherFeatures={product.hasOtherFeatures}
        onClickForward={()=> handlePress(product)}
        badges={getBadges(product)}
        favorite={feathersStore.favoritesArray.includes(product._id)}
        onPressAddToFavorites={() => onPressAddToFavorites(product)}
      /> 
   //   </MenuItem>     
    );
  });

  const Left = () => {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
  
    return (
      <LeftArrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}/>    
    );
  }
  
  const Right = () => {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  
    return (
      <RightArrow disabled={isLastItemVisible} onClick={() => scrollNext()}/>       
    );
  }
  

  return(
    <>    
      <h2>{offers.topSellers}</h2>
    
      <ScrollMenu        
         LeftArrow={LeftArrow}
         RightArrow={RightArrow}     
      >
        {bestSellersIterator}
      </ScrollMenu>
       
    </>
  )


}

export default inject('feathersStore')(observer(Popular));