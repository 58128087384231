import React,{ useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { inject, observer } from "mobx-react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import TreeItemCustom from '../../cards/treeItemCustom';
//import { debounce } from "lodash";
import Collapse from '@mui/material/Collapse';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";

import { useSearchParams } from "react-router-dom";

const style = {
  root: {   
    maxHeight: 350,
    flexGrow: 1,
    maxWidth: 450, 
    paddingRight: 1   
  },
  positioning: {
    position: 'absolute',
    top: 30,
  }
};


const SearchBar = ({feathersStore})=> {
  
  let navigate = useNavigate(); 
  let [searchParams, setSearchParams] = useSearchParams({});
  let common = useTranslate(feathersStore.language, commonEL, commonEN); 
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [anchorEl, setAnchorEl] = useState(null);  
  const [searchText, setSearchText] = useState("");  
  const [products, setProducts] = useState([]); 
  const [openSlider, setOpenSlider] = useState(false);
  const [mouseOverCollapse, setMouseOverCollapse] = useState(false);

  const inputElement = useRef(null);
  
  useEffect(() => {   
    setSearchText(feathersStore.searchCategory.searchText || "");   
  }, [feathersStore?.searchCategory, feathersStore.language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePress = item => () => { 
    setSearchText("");    
    const payload =  {    
      categoryId: item.id,  
      title: item.name, 
      titleEnglish: item.options?.categoryNameEnglish,
      searchText: ""
    }; 
    feathersStore.setSearchCategory(payload);   
    navigate(`/Home/${feathersStore.translate('en', item.name, item.options.categoryNameEnglish)}`);
    handleClose();   
  }

  useEffect(() => {
    //feathersStore.categories && debounce(fetchProducts, 2000)
    feathersStore.categories && searchText && fetchProducts();
  }, [searchText, feathersStore.categories]);

  useEffect(() => {
    setSearchText(transcript);
  }, [transcript]);

  const fetchProducts = async() => {       
    let feathersProducts;    
    if(feathersStore.searchCategory?.categoryId){
      const { categoryId } = feathersStore?.searchCategory;
      feathersProducts = await feathersStore.productsPerCategory(+categoryId, searchText);     
    }
    else {
      feathersProducts = await feathersStore.products(searchText);          
    }
    setProducts([...feathersProducts]);   
  }

  const searchChange = event => {
    setSearchText(event.target.value);    
  }

  const searchClicked =  () => {
    const payload =  {    
      categoryId: feathersStore.searchCategory?.categoryId,  
      title: feathersStore.searchCategory?.title,
      titleEnglish: feathersStore.searchCategory?.titleEnglish,  
      searchText: searchText
    }; 
    feathersStore.setSearchCategory(payload);    
    navigate(`/Home/${payload.categoryId ? 
      feathersStore.translate('en', payload?.title, payload.titleEnglish) : ""}?search=${payload.searchText}`);    
  }

  const micClicked = async() => {
    if (!browserSupportsSpeechRecognition) {
      console.log("Browser doesn't support speech recognition.")
      return;
    }
    if (!listening){
      inputElement.current.focus();
      await SpeechRecognition.startListening({ 
        language: feathersStore.language === 'en' ? 'en-US' : 'el-GR'  
      });
      console.log("Listening")     
    }else {
      await SpeechRecognition.stopListening();
      resetTranscript();
      console.log("Stopped Listening")
    }
  }

  const goToProduct = product => () => {    
    setSearchText("");
    navigate(`/product/${feathersStore.translate('en', product.name, product.nameEnglish)}`,{
      state: {id: `${product._id}`,  payload: product}      
    }); 
    setOpenSlider(false);   
  }

  const renderProducts = products.map( (product, index) => (
    <div className="collapse-list-item" onClick={goToProduct(product)}  key={product._id}>      
      {feathersStore.translate('en', product.name, product.nameEnglish)}     
    </div>
  )
);

  const renderTree = (nodes) => (
   
    <TreeItemCustom 
      key={nodes.id} 
      nodeId={nodes.id.toString()}   
      lbl={(feathersStore.language === 'en') && nodes.options?.categoryNameEnglish ? nodes.options.categoryNameEnglish : nodes.name}
      onLabelClick={handlePress(nodes)}
      collapseIcon={<ExpandMoreIcon />}
      expandIcon={<ChevronRightIcon />}
      nodes={nodes} 
      renderTree={renderTree}      
    >         
    </TreeItemCustom>
  );
 
  return(
    <>
     
      <div className="search-dropdown">
        
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}           
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} 
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={style.positioning} 
        >
          <TreeView
            sx={style.root}            
            defaultExpanded={[]}            
          >
            {feathersStore.categories ? 
            feathersStore.categories?.map(renderTree) 
            :
            <CircularProgress 
              color="secondary" 
              style={{ margin: 6 }} 
              size={'4rem'}            
            />
            }
          </TreeView>
        </Menu>
      </div> 
        
      <div className="search-container" >         
        <button
            aria-labelledby='Dropdown'     
            className="search-menu-button nav-button-SignIn "              
            onClick={handleClick}
          >{feathersStore.translate('en', feathersStore.searchCategory?.title, feathersStore.searchCategory?.titleEnglish)}
          <span>{<ArrowDropDownIcon />}</span>
        </button>
        <div className="search-wrapper">          
          <input 
            ref={inputElement}
            onFocus={() => setOpenSlider(true)} 
            onBlur={() => !mouseOverCollapse && setOpenSlider(false)} 
            aria-labelledby='search Text'
            className="search-input"           
            type="text"       
            placeholder={common.search}
            aria-label="Search"
            value={searchText}
            onChange={searchChange}
          /> 
          {products.length > 0 && searchText && openSlider &&
            <div className="search-collapse" 
              onMouseOver={() => setMouseOverCollapse(true)} 
              onMouseOut={() => setMouseOverCollapse(false)}
           >             
              {renderProducts}           
            </div>
          } 
        </div>        
        <button 
          aria-labelledby='search-button'
          className="search-menu-button nav-button-SignIn  search-button"         
          onClick={searchClicked}>
            <span>{<SearchIcon />}</span>
        </button> 
        <button 
          aria-labelledby='voice-search-button'
          className="search-menu-button nav-button-SignIn mic-button"         
          onClick={micClicked}>
            <span>{<MicIcon />}</span>
        </button>         
      </div>
       
    </>
  )
}

export default inject('feathersStore')(observer(SearchBar));