import React, { useState, useEffect } from 'react';
import { CarouselData } from './CarouselData'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import espaGR from '../img/espa-banner-greek-728x90.jpg'
import espaEN from '../img/espa-banner-english-730x90.jpg'
import './carousel.css';
import { inject, observer } from "mobx-react";

const Carousel = ({slides, feathersStore}) => {
  
  const [current, setCurrent] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const length = slides.length; 

  useEffect(() => {
    //preloading images to avoid flickering
    let preloadedArray = [];
    slides.forEach((s) => {
      const img = new Image();
      img.src = s.image;
      img.text1 = s.text1;
      img.text1EN = s.text1EN;
      preloadedArray.push(img)
    });
    setPreloadedImages(preloadedArray);
  }, []);
  
  useEffect(() => {   
    let slideShow = setTimeout(
      () => nextSlide(),
      7000); 
      return () => clearTimeout(slideShow); //needed because it stops abruptly when navigating to another page
  }, [current])

  if(!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  return (
    <div className='carousel-container'>
     <img src={feathersStore.language === 'en' ?  espaEN : espaGR } 
        alt={feathersStore.language === 'en' ?  "ESPA" : "ΕΣΠΑ"}  className='img-espa' />
      
      <ArrowBackIosIcon className='left-arrow' onClick={prevSlide} />
      <ArrowForwardIosIcon className='right-arrow' onClick={nextSlide} />
      {preloadedImages?.map((slide, index) => {
        return (
          <div className={index === current ? 'slide active' : 'slide'} key={index}>
            {index === current && (
              <>
                <img src={slide.src} alt="slider" className="image-carousel" />
               {/* <div className="carousel-text">{feathersStore.language === 'en' ? slide.text1EN : slide.text1}</div> */}
              </>
            )}
          </div>
        )
      })}
    </div>
  );
}
 
export default inject('feathersStore')(observer(Carousel));
