import React from 'react'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'

const AddToWishListButton = ({ onClick, favorite })=> {

  return(
    <button 
      aria-label='Add to wishlist'
      className="wishList-btn"
      onClick={onClick}
      style={{backgroundColor: favorite ? '#0a639e':'#ff9900'}}
    >
      {favorite ? 'WishList Remove' : 'WishList Add'  }
      <FavoriteBorder/>
    </button>
  )
}

export default AddToWishListButton;