export const accountEL = {
    myAccount : "Λογαριασμός",
    profile : "Προφίλ",
    addresses : "Διευθύνσεις",
    history : "Ιστορικό",
    comments : "Σχόλια",
    settings : "Ρυθμίσεις",
    psw : "Αλλαγή Password",
    updateAlert : "Επιτυχής Αλλαγή Στοιχείων",
    updateError : "Αποτυχία Ενημέρωσης",
    addAddress : "Προσθέστε μία διεύθυνση",
    addNewAddress : "Προσθέστε νέα διεύθυνση",
    myLocation : "Η Τοποθεσία μου",  
    modalHeaderUpdate : "Αλλαγή Διεύθυνσης",
    otherLocation : "Άλλη Τοποθεσία",
    home : "Οικία",
    work : "Εργασία",
    helperText : "Εισάγετε τα στοιχεία της διεύθυνσής σας",
    addressComments : "Σημειώσεις για τη διεύθυνση",
    historyPlaceholder : "Δεν έχετε κάνει ακόμα καμία αγορα",
    commentPlaceholder : "Δεν έχετε κάνει ακόμα κάποιο σχόλιο",
    emptyHistory: "Δεν έχετε κάνει ακόμη κάποια παραγγελία",
    uploadAvatar: "Κάνετε κλικ στην εικόνα για να επιλέξετε διαφορετική εικόνα.",
    submit: "ΥΠΟΒΟΛΗ",
    uploadSuccess: "Ανέβασμα εικόνας επιτυχές",    
    uploadError: "Ανέβασμα εικόνας λανθασμένο",
    bigFile: "Η εικόνα είναι πολύ μεγάλη. Θα πρέπει να είναι έως 1MB. Παρακαλώ επιλέξτε μία διαφορετική εικόνα"
}
