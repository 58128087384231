import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 350,  
  },
  height: 150,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};


const ConfirmationModal = ({visible, product, onClickNo, onClickYes, feathersStore})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  
  return (      
    <Dialog     
      open={visible}
      onClose={onClickNo}
      aria-labelledby="Confirmation"
      aria-describedby="Delete Product"  
    >     
      <div className="modalContainer">
        <div className="modalTitleContainer">
          <p style={{color: AppColors.secondary}}>{common.deleteProduct1}
          <a style={{color: '#000'}}>{product}</a><br/>
          {common.fromCart}</p>
        </div>
        <div className="confirmationModalButtonsView">
          <Button
            aria-labelledby='No'
            style={{color: AppColors.red, fontSize: 18}} 
            onClick={onClickNo}
          >{common.no}</Button>
          <h3 style={{color: AppColors.secondary, marginTop: 10}}>|</h3>
          <Button 
            style={{color: AppColors.amazonColor, fontSize: 18}} 
            onClick={onClickYes}
            aria-labelledby='Yes'
          >{common.yes}</Button>
        </div>
      </div>   
    </Dialog>
  );  
      
}

export default inject('feathersStore')(observer(ConfirmationModal));