import React from 'react';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const ProductPageCounter = ({currentPage, productsPerPage, dataLength, listlength, feathersStore})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);  

  return(
    <div className="row-flex-start-wrap counter-container">
      <div className="total-list">
        <p style={{alignSelf: 'baseline'}}>
          {common.youSee} {currentPage === 0 ?  1 : 
          productsPerPage * currentPage +1}-{currentPage === 0 ? dataLength : 
          (productsPerPage * currentPage + dataLength)}  {common.from} {listlength} {common.products}</p>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(ProductPageCounter));