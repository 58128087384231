import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const GreenSubmitButton = ({ type="button", title, loading, disabled, onClick, color="rgb(0, 128, 0)" })=> {

    function changeCursor(e) {
        e.target.style.cursor = 'pointer';
      }      
      
    const theme = createTheme({
      palette: {
        primary: {
          main: `${color}` 
        },
        secondary: {
          main: "#fff"
        },
      },
    });
       
    return(
        <ThemeProvider theme={theme}>
          <Button
            sx={{margin: 1}}
            aria-label='Submit'
            type={type}
            onClick={onClick} 
            onMouseOver={changeCursor}
            variant="contained" 
            color="primary"
            style={{color: '#fff'}}         
            disabled={disabled}>
            {title}
            {loading && 
              <CircularProgress 
                color="secondary" 
                style={{ marginLeft: 6 }} 
                size={'1.5rem'}            
              />                  
            }
          </Button>
        </ThemeProvider> 
    )
}

export default GreenSubmitButton;