export const checkoutEN = {
  less255: "Maximum 255 characters",
  less50: "Maximum 50 characters",
  less20: "Maximum 20 characters",
  less10: "Maximum 10 characters",
  required: "Required",
  invalidEmail: "Invalid email",
  invalidPhone: "Invalid number",
  textareaMax: "Maximum 1000 characters",
  invalidPayment: "Invalid payment method",
  terms: "You must accepted terms and conditions",
  createAccount : "Create Account" ,
  differentDeliveryAddress : "Deliver to a different address",
  orderComments : "Order Comments",
  payment : "Payment" ,
  cash : "Pay on delivery",
  cashText : "Pay cash when items are delivered",
  clickAway : "Click Away",
  clickAwayText : "Pick up items from the store",
  paypalRadio : "Credit card or PayPal",
  paypalError : "Please fill in the form correctly !",
  personalDataInfo : "Your personal data will be used for the needs of this site and for the handling of your account.",
  readThe : "Read the" ,
  iHaveRead : "I have read and agree with the" ,
  ofThisSite : "of this site" ,
  yourOrder : "Your Order",
  sendOrder : "Send Order",
  alreadyCustomer : "I am already a customer",
  orderCompleteTitle: "ORDER COMPLETED!",
  orderCompleteLine1: "Your order has been successfully received.",
  orderCompleteLine2: "Thank you for your order.",
  isCompany: "Issue Invoice",
  companyAfm: "VAT number",
  companyDoy: "Tax Office",
  companyName: "Company Name",
  companyProfession: "Profession",
  bankTransfer: "Direct Bank Transfer",
  bankTransferText: "Transfer to one of our bank accounts.",
  bankLargeText: "Make your payment directly to our bank account. Please include your order ID in your bank transfer. Your order will be shipped after your money transfer has been verified.",
  bank: "Bank",
  nbgRadio: "Credit card through NBG Bank"
}