import React, {useState, useEffect} from 'react';
import Fab from '@mui/material/Fab';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

import TextField from '@mui/material/TextField';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {footerEL} from "../../locales/el/footer";
import {footerEN} from "../../locales/en/footer";

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
     // marginTop: theme.spacing(1),
      width: 300,  
      height: 450,    
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        fontSize:14
      },
    },
  }));

const MessagingBtn = ({feathersStore}) => {

  let footer = useTranslate(feathersStore.language, footerEL, footerEN);

  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    feathersStore.isAuthenticated && initList();  
  }, [ feathersStore.isAuthenticated, feathersStore.messageCreatedEvent]); 

  const initList = async() => {   
    const databaseList = await feathersStore.findMessages();
    setMessages(databaseList);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setMessageText(event.target.value);
  };

  const sendMessage = async() => {
    const payload = {messageText};
    await feathersStore.createMessage(payload);
    setMessageText("");
  }

  const renderItems = messages.map((item, index) => (   
    <div key={index} 
      className={"message-card " + (item.adminAnswer ? "message-card-admin" : "message-card-customer")}>
      {item.messageText}
    </div>   
  ))

  return (
    <>
      <Fab 
        size="small" 
        color="primary" 
        id="messaging-button"
        aria-label="messaging-button"
        aria-controls="messaging-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ChatSharpIcon />
      </Fab>
      <StyledMenu
        id="messaging-menu"
        MenuListProps={{
          'aria-labelledby': 'messaging-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="messaging-title-container">
          <div className="messaging-title">{footer.messagingTitle}</div>
          <div className="messaging-sub-title">{footer.messagingSubTitle}</div>
        </div>
        <div className="content-container">
          <div className="messages-list">
            {renderItems}
          </div>
          <div className="input-container">
            <TextField
              id="outlined-multiline-flexible"
              label={footer.message}
              color="secondary"
              size="small"
              multiline
              maxRows={4}
              value={messageText}
              onChange={handleChange}
            />
            <IconButton  onClick={sendMessage}
              color="primary" aria-label="send message" component="span">
              <SendIcon />
            </IconButton>        
          </div>
        </div>
      </StyledMenu>  
          
    </>
  );
}

export default inject('feathersStore')(observer(MessagingBtn))