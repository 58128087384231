import React, { useEffect } from 'react'
import {FreeShipping, CashOnDelivery} from '../../cards/siteOffers/SiteOffers'
import Carousel from '../../carousel/Carousel'
import { CarouselData } from '../../carousel/CarouselData';
import ImageCategories from '../../cards/imageCategories/ImageCategories'
import ListProducts from '../listProducts';
import "./HomeBody.css";
import { inject, observer } from "mobx-react";
import { useParams, useSearchParams } from 'react-router-dom';

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {offersEL} from "../../../locales/el/offers";
import {offersEN} from "../../../locales/en/offers";
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";

const HomeBody = ({ feathersStore })=> {

  let offers = useTranslate(feathersStore.language, offersEL, offersEN); 

  const {name} = useParams(); 
  let [searchParams, setSearchParams] = useSearchParams();
    
  let catId;
  let title;
  let titleEnglish; 

  //Browser BackButton and Refresh usage
  useEffect(()=>{    
    let payload =  {searchText: "", title: commonEL.all, titleEnglish: commonEN.all};
    if ((name || searchParams) && feathersStore.categories) {    
      searchTree(feathersStore.categories, name);
     name && Object.assign(payload, {categoryId: catId, title: title, titleEnglish: titleEnglish});
     searchParams && Object.assign(payload, {searchText: searchParams.get('search') || ""});            
    }
    feathersStore.setSearchCategory(payload); 
 },[name, searchParams,  feathersStore.categories]);

 const searchTree = (tree, name) => {
   tree.forEach(element => {
    if(element.name === name || element.options.categoryNameEnglish === name){    
      catId = element.id;
      title =  element.name;
      titleEnglish = element.options.categoryNameEnglish;
    }else{
      element.childrens && searchTree(element.childrens, name)
    }
   }) 
  }  

  return(
    <>    
      <div className="home-container">
        {feathersStore?.isAuthenticated && (!name) ? (
          <>
            <div className="column-flex-center">
              <Carousel slides={CarouselData} />
            </div>       
          
            <div className="categories-card-container">
              <ImageCategories/>
            </div>
            <div className="site-offers">
              <div className="offer-container">
                <FreeShipping
                  header={offers.shippingTitle}
                  body={`${offers.shippingText1}${feathersStore.settings.transportation?.free || 30}${offers.shippingText2}`}
                />
                <CashOnDelivery
                   header={offers.cashTitle}
                   body={offers.cashText}
                />
              </div>
            </div> 
          </>)
          : null
         }
         
        <ListProducts />
            
     </div>       
    </>
  )
}

export default inject('feathersStore')(observer(HomeBody));