import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import Favorite from '@mui/icons-material/Favorite';


const WishListButton = ({ feathersStore }) => {  

  return ( 
    <Link to="/wishList" className="undecorated-link">
      <span className="nav-button-SignIn undecorated-link" aria-label='Add to wishlist'>
        <Favorite className="calendar-icon" style={{ fontSize: 30 }}/>
        <span className="nav-number">{feathersStore.favoritesArray?.length || 0}</span>
      </span>
    </Link>
  )
}


export default inject('feathersStore')(observer(WishListButton));