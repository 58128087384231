import React, {useState, useEffect, useRef} from 'react';
import { 
  FormControl,
  FormHelperText,
  Input, 
  Button, 
  Modal,
  InputAdornment,
  IconButton  
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils';
import FacebookLogin from 'react-facebook-login';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { DEFAULT_EMAIL, DEFAULT_PSW, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../assets/globalConstants";
import Box from '@mui/material/Box';
import ResetPassword from './resetPassword'

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

let emailElement;
let passwordElement;

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 600,  
  },
  height: 650,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,  
};

const Prompt = ({visible, feathersStore,
      onClickRegister, close, fromCart, goToAddress})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);  



  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");    
  const [loading, setLoading] = useState(false);
  const [fbLoading, setFbLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false);  
  const [secondTime, setSecondTime] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false) 
  
  const isMountedRef = useRef(null);  
    
  useEffect(() => {
    setLoading(false);
    isMountedRef.current = true;   
    if(feathersStore.isAuthenticated 
      && feathersStore.user?.firstname !== "default" 
      && fromCart && feathersStore.goToAddressHelper) goToAddress();
    return () => isMountedRef.current = false;
  }, [feathersStore.isAuthenticated, emailElement])
       
  const emailChange = event => {    
    setEmail(event.target.value);
    secondTime && emailValidation(event.target.value);   
  };

  const passwordChange = event => {    
    setPassword(event.target.value);
    passwordValidation(event.target.value);   
  };

  const emailValidation = val => {
     if (!Utils.validateEmail(val) ) {
      setEmailError(true);
    }else{
      setEmailError(false);
    }
  } 

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

  const passwordFocus = () => { 
    setSecondTime(true);
    emailValidation(email);
  };

  const enterPressed = (ev) => {   
    if (ev.key === 'Enter') {
        passwordElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      email && password && !emailError && !passwordError && await logIn();
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleExit = () => {
    setSecondTime(false);
    setEmailError(false);
    setPasswordError(false);
    setEmail('');
    setPassword('');
    close();
  }

  const logIn = async() => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();           
    feathersStore.login(email, password)
      .then(async(auth) => {                            
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        await loginAsDefault();
        handleExit();
      });      
  };

  const loginAsDefault = async()=> {
    isMountedRef.current && setLoading(true);
    try{
      feathersStore.isAuthenticated && await feathersStore.logout();      
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
           // history.push('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  }   
  
  const onLogoutSuccess = res => {
    console.log("Logged out from Google")
  }

  const onFailure = () => {
    console.log("Failed to logout from Google")
  }
  
  const { signOut } = useGoogleLogout({
    //  jsSrc,
      onFailure,
      clientId: GOOGLE_CLIENT_ID,
   //   cookiePolicy,
    //  loginHint,
    //  hostedDomain,
   //   fetchBasicProfile,
    //  discoveryDocs,
   //   uxMode,
    //  redirectUri,
   //   scope,
   //   accessType,
      onLogoutSuccess
    })
  
 

 
  
  const responseFacebook = async(response) => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setFbLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.facebookAuth(response.accessToken.toString())
      .then(async auth => {
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        await loginAsDefault();
        handleExit();
      });  
  }

  const responseGoogle = async(response) => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setGoogleLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.googleAuth(response.accessToken?.toString())
      .then(async auth => {
        await feathersStore.setObservables(); 
        signOut(); //--> SignOut from Google because the server handles auth and not Google with its cookies
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        signOut(); 
        await loginAsDefault();
        handleExit();
      });  
  }

  const responseGoogleFailed = (response) => {
    console.log(response);
  }

 
 const { signIn, loaded } = useGoogleLogin({
    onSuccess: responseGoogle,
    //onAutoLoadFinished,
    clientId: GOOGLE_CLIENT_ID,
    cookiePolicy: "single_host_origin",
    //loginHint,
    //hostedDomain,
    //autoLoad,
    isSignedIn: true,
    //fetchBasicProfile,
    //redirectUri,
    //discoveryDocs,
    onFailure: responseGoogleFailed,
 //   uxMode,
  //  scope,
 //   accessType,
 //   responseType,
 //   jsSrc,
  //  onRequest,
  //  prompt
  })
  

  const submitReset = ()=> {
    setResetPasswordModal(!resetPasswordModal);
  } 

  return (
    <section>
      <Modal 
        open={visible} 
        onClose={close}
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
      <Box sx={style}>
        <div className="modalContainer">
          <div className="modalTitleContainer">
            <h1>{common.loginRegistration}</h1>
          </div>
          <div className="modalFormContainer">
          <FormControl fullWidth={true} >
            <Input
            autoFocus
            aria-label='email'
              value={email}     
              error={emailError}
              inputRef={r => {
                emailElement = r;
              }}
              onChange={emailChange}              
              onKeyPress={enterPressed}
              placeholder="E-mail"
            />
            {emailError && <FormHelperText id="component-error-text" >{common.emailError}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={true}>
            <Input
            aria-label='password'
              value={password}
              error={passwordError}              
              inputRef={r => {
                passwordElement = r;
              }}             
              type={showPassword ? 'text' : 'password'}
              onChange={passwordChange}
              onFocus={passwordFocus}          
              onKeyPress={submitEditing}              
              placeholder="Password" 
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
             {passwordError && 
              <FormHelperText id="component-error-text" color="secondary">{common.passwordHelperText}</FormHelperText>}
          </FormControl>
            <GreenSubmitButton
              onClick={logIn} 
              disabled = {!email || !password || emailError || passwordError}
              title={common.login}
              loading={loading}
              aria-labelledby='Login'
            />             
            <Button 
              aria-labelledby='forgot password'
              onClick={submitReset}>
              <a style={{color: AppColors.secondary, fontSize: 12}}>{common.forgotPassword}</a>
              </Button>
          </div>
          <div className="maybe">
            <div className="OR">
              <hr className="hr"/>
            </div>
            <a style={{margin: 5}}>{common.or}</a>
            <div className="OR">
              <hr className="hr"/>
            </div>
          </div>
          <div className="modalFacebookContainer">
            <FacebookLogin
              appId={FACEBOOK_APP_ID}              
              fields="name,email,picture"          
              callback={responseFacebook}             
              icon={<FacebookIcon />}
              textButton={common.facebookLogin}
              cssClass="my-facebook-button-class"
              aria-labelledby='Facebook login'
            />            
            <Button
              sx={{
                marginLeft: "2vw",
                marginTop: "1vw"              
              }}
              onClick={signIn}             
              aria-labelledby='google login'
              variant="contained"
              color="warning"
            >
              <GoogleIcon/><span>{common.googleLogin}</span>
            </Button>             
          </div>         
        <div className="modalRegisterButtonContainer">
            <h4>{common.register}</h4>
            <GreenSubmitButton
            aria-labelledby='Register'
            title={common.registerButton}
            onClick={onClickRegister}
            />
        </div>
        <div className="modalExitButtonContainer">
          <Button 
            aria-labelledby='Exit'
            style={{color: AppColors.red, fontSize: 18}} 
            onClick={handleExit}
          >{common.exit}</Button>
        </div>
        </div>
        <ResetPassword
          visible={resetPasswordModal}
          onClickSubmit={submitReset}
          close={submitReset}
        />  
      </Box>      
      </Modal>
    </section>
        );
}

export default inject('feathersStore')(observer(Prompt));
