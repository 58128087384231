import React from 'react'

const PrivacyTextEnglish = ()=> {
    return(
        <p id="pt">For AppDate, the protection of personal data is paramount and is treated with the utmost seriousness. 
        Respect for the personal data we manage and ensuring their proper processing is one of the Company's priorities.<br/> 
        For this reason we take all appropriate technical and organizational measures to protect the personal data we process and to ensure that their processing is always carried out in accordance with the requirements set by the applicable legal framework and in particular by the General Data Protection Regulation (EU). ) 2016/679.<br/>
        <a>1. PROCESSING MANAGER - CONTACT DETAILS</a><br/>
        AppDate acts as the Processor for all personal data it collects, processes and stores.<br/>
        AppDate (APPNETIT SOLE SHAREHOLDER IKE)<br/>
        Address: 131 Mesogeion Street, 15126, Maroussi<br/>
        Telephone: 211 4115591<br/>
        E-mail: info.appdate@gmail.com<br/>
        Contact Person: Christidis Joseph<br/>
        <a>2. WHAT DATA DO WE COLLECT AND PROCESS</a><br/>
        In order to serve you and offer you our services and products, we collect and process all the data that you disclose to the representative of the call center when you place your order or the data that you fill in the corresponding fields when you open a user account on our website and more specifically, name, postal address, contact telephone (landline or mobile), e-mail, tax information (in case you want to issue an invoice).<br/>
        Please note that our Company website may contain links to other websites. Our Company is not responsible for the privacy and privacy practices and / or the content of these websites. If you are up to 15 years old, please obtain the permission of your parent or guardian before sharing any of your personal information with us.<br/>
        <a>3. TREATMENT PURPOSES & LEGAL TREATMENT BASES</a><br/>
        All the above personal data are collected and subjected to processing in order in principle to execute the contract of sale between us for our products and more specifically for the preparation and delivery of your order and our communication with you regarding it. Some of your data can be used for the following purposes always with your consent:<br/>
        - Contact you in connection with contests, draws and other events.<br/>
        - Information and sending offers and information material about our products and services.<br/>
        - Statistical purposes and surveys.<br/>
        - Promotion and promotion of our products and services.<br/>
        Legal bases for data processing may be as appropriate:<br/>
        i. The execution and completion of the contract between us for the sale of our products to you (preparation and delivery of your order). Without the collection and use of the above information we will not be able to serve you.<br/>
        ii. ii. The consent that you can give us in order to receive information about offers or promotional material for our products and services, the commercial actions and activities that we develop, etc. You can revoke your consent whenever you wish, by contacting us at contact details referred to in paragraph 1 or through our website.<br/>
        <a>4. WHERE WE DISCLOSE YOUR PERSONAL DATA</a><br/>
        Your personal data may be disclosed to the following recipients:<br/>
        - To distributors in order to prepare and deliver the order.<br/>
        - To a newsletter sending company (provided you have given your consent for us to send you a newsletter).<br/>
        - To an sms sending company (in case you have given your consent to receive updates).<br/>
        - In partner companies, in order to be able to receive your gift, if you are a winner in a competition in which you participated (every time we organize a competition, we mention in the terms of the competition the fact of disclosing your details to the partner company).<br/>
        - To an external partner who supports us in software matters.<br/>
        - In the cooperating legal department of the company, in case of legal or out-of-court dispute.<br/>
        - To police or other state authorities if we are asked to do so officially or after a prosecutor's order.<br/>
        <a>5. DATA STORAGE PERIOD</a><br/>
        Your data is stored in the AppDate database for 5 years after your last transaction with the company or for as long as the law may require (eg in the case of an invoice, as long as the tax legal framework stipulates).<br/>
        If the processing is based on your consent, the personal data is kept until you revoke that consent. It is clarified that the revocation of consent does not affect the legality of the processing based on the consent before its revocation.<br/>
        In any case, AppDate implements all appropriate technical and organizational measures to ensure the protection of personal data that it processes and continuously takes care to prevent any unauthorized access to this data.<br/>
        <a>6. RIGHTS RELATED TO YOUR PERSONAL DATA</a><br/>
        Regarding the processing of your personal data you can exercise the following rights:<br/>
        Right of access<br/>
        You have the right to know what data we hold and process, why other additional information about them, as well as to request a copy.<br/>
        Right of correction<br/>
        You have the right to request the correction, modification and completion of your personal data.<br/>
        Right of deletion ("right to be forgotten")<br/>
        You have the right to request the deletion of your personal data when it is processed with your consent. In cases where the processing is based on another legal basis (such as the execution of a contract, a legal obligation or the protection of the legal interests of the Company, etc.) your right may be subject to restrictions or may not exist.<br/>
        Right to restrict processing<br/>
        You have the right to request a restriction on the processing of your personal data<br/>
        - when their accuracy is questioned and until verified.<br/>
        - alternatively, instead of deleting them.<br/>
        - when they are no longer necessary for the processing purposes for which we collected them, but are necessary for the establishment, exercise or support of legal claims by you.<br/>
        - when you have objections to their processing and until it is verified that there are legal reasons for this processing by the Company.<br/>
        Right to object to the processing.<br/>
        You have the right to object to the processing of your personal data when it is done on the basis of a legitimate interest, as well as for the purposes of direct marketing and profiling.<br/>
        Right to portability<br/>
        You have the right to request and receive your personal data in a form that allows you to access, use and process it using commonly used processing methods. In addition, for your data which we process with automated means and based on your consent or for the execution of a contract, you can ask us to transfer it directly to another controller, if this is technically possible.<br/>
        Right to withdraw consent<br/>
        If the processing of your data is based on your consent, you have the right to revoke it at any time. Revoking your consent does not affect the legality of the processing based on your prior consent.<br/>
        For the exercise of your above rights and for any question, complaint or other information regarding the processing of your personal data you can contact us at the email info.appdate@gmail.com or the contact details referred to in paragraph 1.<br/>
        <a>7.  COMPLAINTS RIGHTS TO THE PERSONAL DATA PROTECTION AUTHORITY (APDP)</a><br/>
        You have the right to file a complaint to the Personal Data Protection Authority (www.dpa.gr) regarding issues related to the processing of your personal data.<br/>
        <a>8. CHANGES IN PERSONAL DATA PROTECTION POLICY</a><br/>
        Any future changes in the Personal Data Protection Policy of our Company will be posted on the website of www.appdate.gr and where required, will be notified.<br/>
        <a>9.COOKIES POLICY</a><br/>
        Because the online store uses modern technologies, cookies with your data are not used even in case you have registered in our online store.<br/> 
        The only cookies used concern the Google Analytics service, as long as it is activated and in this case general statistics are kept for the visits to the pages of the online store.
    </p>
    )
}

export default PrivacyTextEnglish;