import React, {useState, useEffect} from 'react';
import PageTitle from '../../components/screenComponents/pageTitle'
import Colors from '../../components/assets/colors'
import AccountBox from '@mui/icons-material/AccountBox'
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CommentIcon from '@mui/icons-material/Comment';
import ProfileSettings from './profileSettings'
import Address from './address';
import History from './history';
import Comments from './comments';
import Settings from './settings';
import "./Account.css";
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {accountEL} from "../../locales/el/account";
import {accountEN} from "../../locales/en/account";

const Account = ({ feathersStore })=> {

  let account = useTranslate(feathersStore.language, accountEL, accountEN);

  const [profile, setProfile] = useState(true);
  const [visibleAddress, setVisibleAddress] = useState(false);
  const [history, setHistory] = useState(false);
  const [comments, setComments] = useState(false);
  const [settings, setSettings] = useState(false);
  
  const profileClicked = () => {
    setProfile(true)
    setVisibleAddress(false)
    setHistory(false)
    setComments(false)
    setSettings(false)
  }
  
  const addressesClicked = () => {
    setProfile(false)
    setVisibleAddress(true);
    setHistory(false)
    setComments(false)
    setSettings(false)
  }

  const historyClicked = () => {
    setProfile(false)
    setVisibleAddress(false)
    setHistory(true)
    setComments(false)
    setSettings(false)
  }

  const commentsClicked = () => {
    setProfile(false)
    setVisibleAddress(false)
    setHistory(false)
    setComments(true)
    setSettings(false)
  }

  const settingsClicked = () => {
    setProfile(false)
    setVisibleAddress(false)
    setHistory(false)
    setComments(false)
    setSettings(true)
  }


  const handleMenuSelection = () => {

    if(profile) {

      return <ProfileSettings />

    } else if(history){

      return <History/>

    } else if(visibleAddress){

      return <Address/>

    } else if(comments) {
      
      return <Comments />
 
    } else if(settings){

      return <Settings/>
    }
  }

  return(
    <>
      <div className="account-container">
        <div className="header-container">
          <PageTitle title={account.myAccount}/>
        </div>     
        <div className="account-board">
          <div className="account-board-header">           
            <button onClick={profileClicked} aria-label='Account'>
              <AccountBox className={profile ? "selected-button" : undefined} style={{fontSize: 32}}/>
              <h4 className={profile ? "selected-button" : undefined}>{account.profile}</h4>
            </button>          
            <button onClick={addressesClicked} aria-label='Address'>
              <LocationOnIcon className={visibleAddress ? "selected-button" : undefined} style={{fontSize: 32}}/>
              <h4 className={visibleAddress ? "selected-button" : undefined}>{account.addresses}</h4>
            </button>          
            <button onClick={historyClicked} aria-label='History'>
              <HistoryIcon className={history ? "selected-button" : undefined} style={{fontSize: 32}}/>
              <h4 className={history ? "selected-button" : undefined}>{account.history}</h4>
            </button>          
            <button onClick={commentsClicked} aria-label='Comments'>
              <CommentIcon className={comments ? "selected-button" : undefined} style={{fontSize: 32}}/>
              <h4 className={comments ? "selected-button" : undefined}>{account.comments}</h4>
            </button>         
            <button onClick={settingsClicked} aria-label='Settings'>
              <SettingsIcon className={settings ? "selected-button" : undefined} style={{fontSize: 32}}/>
              <h4 className={settings ? "selected-button" : undefined}>{account.settings}</h4>
            </button>           
          </div>
          <div className="account-board-body">
            {handleMenuSelection()}
          </div>
        </div>
      </div>     
    </>
  )
}

export default inject('feathersStore')(observer(Account));