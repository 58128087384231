import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FlakyIcon from '@mui/icons-material/Flaky';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LinkIcon from '@mui/icons-material/Link';
import ReplayIcon from '@mui/icons-material/Replay';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {footerEL} from "../../locales/el/footer";
import {footerEN} from "../../locales/en/footer";

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,      
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        fontSize:14
      },
    },
  }));

const AccessibilityBtn = ({feathersStore}) => {

  let footer = useTranslate(feathersStore.language, footerEL, footerEN);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleBold = () => {
    feathersStore.setHtmlBold(!feathersStore.htmlBold)
  }

  const toggleGrayscale = () => {
    feathersStore.setHtmlGrayscale(!feathersStore.htmlGrayscale)
  }

  const toggleHighContrast = () => {
    feathersStore.setHtmlHighContrast(!feathersStore.htmlHighContrast)
  }

  const toggleNegativeContrast = () => {
    feathersStore.setHtmlNegativeContrast(!feathersStore.htmlNegativeContrast)
  }

  const toggleHtmlLightBackground = () => {
    feathersStore.setHtmlLightBackground(!feathersStore.htmlLightBackground)
  }

  const toggleIncreaseFontSize = () => {
    feathersStore.setHtmlIncreaseFontSize(!feathersStore.htmlIncreaseFontSize)
  }

  const toggleHtmlAUnderline = () => {
    feathersStore.setHtmlAUnderline(!feathersStore.htmlAUnderline)
  }

  const toggleDecreaseFontSize = () => {
    feathersStore.setHtmlDecreaseFontSize(!feathersStore.htmlDecreaseFontSize)
  }

  const resetAccessibility = () => {
    feathersStore.setHtmlBold(false);
    feathersStore.setHtmlGrayscale(false);
    feathersStore.setHtmlHighContrast(false);
    feathersStore.setHtmlNegativeContrast(false);
    feathersStore.setHtmlLightBackground(false);    
    feathersStore.setHtmlAUnderline(false);   
    feathersStore.setHtmlIncreaseFontSize(undefined);
  }

  return (
    <>
      <Fab 
        size="small" 
        color="secondary" 
        id="accessibility-button"
        aria-label="accessibility-button"
        aria-controls="accessibility-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccessibilityNewIcon />
      </Fab>
      <StyledMenu
        id="accessibility-menu"
        MenuListProps={{
          'aria-labelledby': 'accessibility-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="accessibility-title">{footer.accessibilityTitle}</div>
        <MenuItem onClick={toggleIncreaseFontSize} disableRipple>
          <ZoomInIcon />
          {footer.fontIncrease}
        </MenuItem>
        <MenuItem onClick={toggleDecreaseFontSize} disableRipple>
          <ZoomOutIcon />
          {footer.fontDecrease}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={toggleGrayscale} disableRipple>
          <ArchiveIcon />
          {footer.greyscale}
        </MenuItem>
        <MenuItem onClick={toggleHighContrast} disableRipple>
          <FlakyIcon />
          {footer.highContrast}
        </MenuItem>
        <MenuItem onClick={toggleNegativeContrast} disableRipple>
          <RemoveRedEyeIcon />
          {footer.negativeContrast}
        </MenuItem>
        <MenuItem onClick={toggleHtmlLightBackground} disableRipple>
          <LightbulbIcon />
          {footer.lightBackground}
        </MenuItem>
        <MenuItem onClick={toggleHtmlAUnderline} disableRipple>
          <LinkIcon />
          {footer.linksUnderline}
        </MenuItem>
        <MenuItem onClick={toggleBold} disableRipple>
          <FontDownloadIcon />
          {footer.readableFont}
        </MenuItem>
        <MenuItem onClick={resetAccessibility} disableRipple>
          <ReplayIcon />
          {footer.reset}
        </MenuItem>
      </StyledMenu>      
    </>
  );
}

export default inject('feathersStore')(observer(AccessibilityBtn))