import React, {useState} from 'react';
import Prompt from '../../modals/prompt';
import RegistrationCard from '../../modals/registrationCard'
import VerificationCode from '../../modals/verificationCode'
import ResetPassword from '../../modals/resetPassword'
import RegisteredCustomer from '../../buttons/registeredCustomer'
import "./Registration.css"


const Registration = ()=> { 

  const [visiblePrompt, setVisiblePrompt] = useState(false);
  const [visibleRegister, setRegistrationModal] = useState(false);
  const [visibleVerification, setVisibleVerification] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [exit, setExit] = useState(false);

  const togglePrompt = ()=> {
    setVisiblePrompt(oldVal => !oldVal); 
  }

  const openRegistrationModal = ()=> {
    setVisiblePrompt(false);
    setRegistrationModal(true);
  }

  const closeRegistrationModal = ()=> {
    setRegistrationModal(false);
  }

  const openVerification = ()=> {
    setRegistrationModal(false);
    setVisibleVerification(true);
  }

  const closeVerification = ()=> {
    setVisibleVerification(false);     
  } 

   const SubmitReset = ()=> {
    setResetPasswordModal(!resetPasswordModal);
  }
  return(
    <div >
      <Prompt 
        visible={visiblePrompt}
        onClickForgot={SubmitReset} 
        close={togglePrompt}       
        onClickAway={togglePrompt}
        onClickRegister={openRegistrationModal}       
        />
      <ResetPassword
        visible={resetPasswordModal}
        onClickSubmit={SubmitReset}
        close={SubmitReset}
      />  
      <RegistrationCard
        visible={visibleRegister} 
        close={closeRegistrationModal}
        onClickAway={closeRegistrationModal}
        onClick={openVerification}
      />       
      <VerificationCode
        visible={visibleVerification}
        onClickCloseCode={closeVerification}
        onClickAway={closeVerification}
      />     
      <RegisteredCustomer 
        onClickSignIn={togglePrompt}       
      />                
    </div>
  )
}

export default (Registration);