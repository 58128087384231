export const API_URL = 'https://ffi.appdate.gr';
export const ADMIN_ID = '5fb3b2dcad060c0a845047f3';
export const DEFAULT_EMAIL = "defaultUser@gmail.com";
export const DEFAULT_PSW = ")j~nKj/,N}N6,8&cVVV#G!=F*y";
export const PHONE_REGEX =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ ;
export const SANDBOX_CLIENT_ID = "Ab9xBziZRnJjCwgOIgQb-uTWsr6cWG1B5nCVup5m2EcrZib1dD7V70LViTk6rL3ZvPH0Sg3IAFQDBLFZ"
export const PRODUCTION_CLIENT_ID = "Aan6sxIsCGqkQgK57MaPULPsAmfwmuIxAQOEiM7qN1zD32LHzayz4PtlC4Z2AD0AMsdZBYS38rGdq6-x"
export const GOOGLE_CLIENT_ID = "597039682294-a9dbtm4j2u43o5io54kb3rnleg57vk87.apps.googleusercontent.com"
export const FACEBOOK_APP_ID = "1148106532315445"
export const NBG_PUBLIC_KEY = "lvpb_NzM1OWUxNjAtZjM3Ni00OTg0LWIwMjEtYTA3ZGUwZWEzODkw"
export const SPEECHLY_APP_ID = "fb9269b9-ea3d-481a-82f1-36f6059f7ed5"