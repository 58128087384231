import React, {useState, useEffect} from 'react';
import {
  FormControl,
  FormHelperText,
  Input,
  TextareaAutosize,
  Modal
} from '@mui/material';
import CloseButton from '../../buttons/closeButton'
import GreenSubmitButton from '../../buttons/greenSubmitButton';
import RoundedButton from '../../buttons/roundedButton'
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { inject, observer } from "mobx-react";
import Utils from '../../../Utils'; 
import "./AddOrEditAddressModal.css";
import Box from '@mui/material/Box';

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";
import {accountEL} from "../../../locales/el/account";
import {accountEN} from "../../../locales/en/account";

let streetElement;
let streetNumberElement;
let cityElement;
let postcodeElement;
let apartmentElement;
let addressCommentElement;

const style = {
  position: 'absolute',
   width: {
    xs: '100%',
    sm: '80%', 
    md: 600,  
  },  
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const AddOrEditaddressModal = ({visible, close, itemToEdit, feathersStore})=> { 

  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  let account = useTranslate(feathersStore.language, accountEL, accountEN);  
  
  const [otherSelected, setOther] = useState(false);
  const [homeSelected, setHome] = useState(false);
  const [workSelected, setWork] = useState(false);
  const [header, setHeader] = useState("");
  const [title, setTitle] = useState("Οικία");
  const [streetNumber, setStreetNumber] = useState("");
  const [street, setStreet] = useState("");
  const [apartment, setApartment] = useState(""); 
  const [addressComment, setAddressComment] = useState(""); 
  const [postcode, setPostcode] = useState(""); 
  const [city, setCity] = useState("");  
  const [active, setActive] = useState(false); 
  const [streetError, setStreetError] = useState(false);
  const [streetNumberError, setStreetNumberError] = useState(false);  
  const [cityError, setCityError] = useState(false);
  const [loading, setLoading] = useState(false); 

  
  useEffect(() => {    
    setHeader(account.addNewAddress);
    if(Object.keys(itemToEdit).length !== 0 && itemToEdit.constructor === Object){
      setHeader(account.modalHeaderUpdate);
      setTitle(itemToEdit.title);
      switch(itemToEdit.title){
        case "Οικία" : setHome(true);
        break;
        case "Έργασία" : setWork(true);
        break;
        case "Άλλη Τοποθεσία" : setOther(true);
        break;
      }
      setStreet(itemToEdit.street);
      setStreetNumber(itemToEdit.streetNumber);
      setCity(itemToEdit.city);
      setPostcode(itemToEdit.postcode);
      setApartment(itemToEdit.apartment);
      setAddressComment(itemToEdit.addressComment);
      setActive(itemToEdit.active);
    }   
  }, [feathersStore.language, account])

  const PressOther = ()=> {
    setOther(true);
    setHome(false);
    setWork(false);
    setTitle('Άλλη Τοποθεσία')
  }

  const PressHome = ()=> {
    setOther(false);
    setHome(true);
    setWork(false);
    setTitle('Οικία')
  }

  const PressWork = ()=> {
    setOther(false);
    setHome(false);
    setWork(true);
    setTitle('Έργασία');
  }

  const onChangeText = key => (event) => {
    
    switch(key){
      case "street" : {setStreet(event.target.value); streetValidation(event.target.value);}
      break;
      case "streetNumber" : {setStreetNumber(event.target.value); streetNumberValidation(event.target.value);}
      break;
      case "city" : {setCity(event.target.value); cityValidation(event.target.value);}
      break;
      case "postcode" : setPostcode(event.target.value); 
      break;
      case "apartment" : setApartment(event.target.value);
      break;
      case "addressComment" : setAddressComment(event.target.value);
      break;
    }
  };

  const enterPressed = key => (ev) => {   
    if (ev.key === 'Enter') {
      switch(key){
        case "street" : streetNumberElement.focus();
        break;
        case "streetNumber" : cityElement.focus();
        break;
        case "city" : postcodeElement.focus();
        break;
        case "postcode" : apartmentElement.focus();
        break;
        case "apartment" : addressCommentElement.focus();
        break;
        case "addressComment" : streetElement.focus();
        break;
      }
        
    }
  }

  const streetValidation = val => {
    if (!Utils.validateNonEmpty(val) ) {
      setStreetError(true);
    }else{
     setStreetError(false);
   }
  }

  const streetNumberValidation = val => {
    if (!Utils.validateNonEmpty(val) ) {      
      setStreetNumberError(true);
    }else{
     setStreetNumberError(false);
   }
  }

  const cityValidation = val => {
    if (!Utils.validateNonEmpty(val) ) {
      setCityError(true);
    }else{
     setCityError(false);
   }
  }

  const saveAddress = async () => {
    setLoading(true);  
    let clonedAddresses=[...feathersStore.user?.addresses];         
    const data = { title, city, street,  streetNumber, postcode, apartment, addressComment, active};        
    if(Object.keys(itemToEdit).length !== 0 && itemToEdit.constructor === Object){
      const index = clonedAddresses.indexOf(itemToEdit);
      clonedAddresses.splice(index, 1, data);
    }else {
      clonedAddresses.push(data);     
    }      
    try{
      const user = await feathersStore.patchAddresses( feathersStore.user._id, [...clonedAddresses]);
      feathersStore.setUser(user);
      setLoading(false);   
      alert(common.updateSuccess);
    }catch (error){
      setLoading(false);   
      alert(common.updateError + `:  ${error.message}`)
    }
  };

return(
  <section>    
    <Modal     
      open={visible}
      onClose={close}
      aria-labelledby="address-edit-modal"
      aria-describedby="modal-for-editing-an-address"
    >
    <Box sx={style}>
      <div className="add-address-container">
        <div className="address-modal-header">
          <div className="edit-address-title">
            <h5>{header}</h5>
          </div>
          <div className="edit-address-close-button">
            <CloseButton onClick={close} aria-labelledby='Close'/> 
          </div>          
        </div>      
        <div className="addNewAddressButtonsContainer">
          <div className="typeAddressButton">
            <RoundedButton
            aria-label='other'
            color={otherSelected? 'green' : '#ebebeb'}
            icon={<ApartmentIcon style={{color: otherSelected? '#fff' : 'gray'}}/>}
            onClick={PressOther}
            />
            <a style={{color: 'grey', fontSize: 12, marginTop: 5}}>{account.otherLocation}</a>
          </div>
          <div className="typeAddressButton">
            <RoundedButton
            aria-labelledby='Home'
            color={homeSelected? 'green' : '#ebebeb'}
            icon={<HomeIcon style={{color: homeSelected? '#fff' : 'gray'}}/>}
            onClick={PressHome}
            />
            <a style={{color: 'grey', fontSize: 12, marginTop: 5}}>{account.home}</a>
          </div>
          <div className="typeAddressButton">
            <RoundedButton
            aria-labelledby='Word'
            color={workSelected? 'green' : '#ebebeb'}
            icon={<BusinessCenterIcon style={{color: workSelected? '#fff' : 'gray'}}/>}
            onClick={PressWork}
            />
            <a style={{color: 'grey', fontSize: 12, marginTop: 5}}>{account.work}</a>
          </div>
        </div>
        <a style={{color: 'grey'}}>{account.helperText}</a>
        <FormControl fullWidth={false}> 
          <Input
            value={street}     
            error={streetError}
            inputRef={r => {
              streetElement = r;
            }}
            aria-label='street'
            onChange={onChangeText("street")}              
            onKeyPress={enterPressed("street")}                
            placeholder={common.street + "*"}
          />
          {streetError && <FormHelperText id="component-error-text" >{common.streetError}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth={false}> 
          <Input
            value={streetNumber}     
            error={streetNumberError}
            inputRef={r => {
              streetNumberElement = r;
            }}
            aria-label='street number'
            onChange={onChangeText("streetNumber")}              
            onKeyPress={enterPressed("streetNumber")}                
            placeholder={common.streetNumber + "*"}
          />
          {streetNumberError && <FormHelperText id="component-error-text" >{common.streetNumberError}</FormHelperText>}
        </FormControl>        
        <FormControl fullWidth={false}> 
          <Input
            value={city}     
            error={cityError}
            inputRef={r => {
              cityElement = r;
            }}
            aria-label='city'
            onChange={onChangeText("city")}              
            onKeyPress={enterPressed("city")}                
            placeholder={common.city + "*"}
          />
          {cityError && <FormHelperText id="component-error-text" >{common.cityError}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth={false}> 
          <Input
            value={postcode}         
            inputRef={r => {
              postcodeElement = r;
            }}
            aria-label='postcode'
            onChange={onChangeText("postcode")}              
            onKeyPress={enterPressed("postcode")}                
            placeholder={common.postcode + "*"}
          />       
        </FormControl>
        <FormControl fullWidth={false}> 
          <Input
            value={apartment}          
            inputRef={r => {
              apartmentElement = r;
            }}
            aria-label='apartment'
            onChange={onChangeText("apartment")}              
            onKeyPress={enterPressed("apartment")}                
            placeholder={common.apartment}
          />        
        </FormControl> 
        <FormControl style={{width: '80%'}}> 
          <TextareaAutosize          
            minRows={3}
            maxRows={4}
            value={addressComment}          
            ref={r => {
              addressCommentElement = r;
            }}
            onChange={onChangeText("addressComment")}              
            onKeyPress={enterPressed("addressComment")}                
            placeholder={account.addressComments}
          />        
        </FormControl>       
        <GreenSubmitButton   
        aria-labelledby='Save'      
          title={common.save}
          loading={loading}
          disabled={streetError || streetNumberError || cityError} 
          onClick={saveAddress}  
        /> 
      </div>
    </Box>
    </Modal>
  </section>
  )
}

export default inject('feathersStore')(observer(AddOrEditaddressModal));