export const commonEL = {
    firstName : "Όνομα",
    lastName : "Επώνυμο",
    street : "Οδός",
    streetNumber : "Αριθμός",
    city : "Πόλη",
    origin : "Περιφέρεια",
    postcode : "ΤΚ",
    apartment : "Όροφος",
    phone : "Τηλέφωνο",
    email : "E-mail",
    emailMessage : "Δεν θα κοινοποιήσουμε ποτέ το email σας αε τρίτους",
    checkout: "Tαμείο",
    streetAndNumber : "Οδός & αριθμός",
    privacyStatement : "Πολιτική απορρήτου",
    termsOfService : "Όρους και Προϋποθέσεις",
    termsOfServiceNoun : "Όροι και Προϋποθέσεις",
    aboutUs : "Σχετικά με εμάς",
    product : "Προϊόν",
    products : "Προϊόντα",
    subTotal : "Υποσύνολο",
    transportation : "Μεταφορικά",
    total: "Σύνολο",
    price : "Τιμή",
    quantity : "Ποσότητα",
    items : "Τεμάχια",
    firstNameError : "Παρακαλώ συμπληρώστε ένα όνομα",
    lastNameError : "Παρακαλώ συμπληρώστε ένα επώνυμο",
    phoneError : "Παρακαλώ συμπληρώστε ένα κανονικό αριθμό",
    emailError : "Παρακαλώ συμπληρώστε ένα κανονικό email",
    save : "ΑΠΟΘΗΚΕΥΣΗ",
    updateSuccess : "Επιτυχής Αλλαγή Στοιχείων",
    updateError : "Αποτυχία Ενημέρωσης",
    updated : "Ενημερώθηκε!",
    cartUpdated : "Ενημερώσατε επιτυχώς το καλάθι σας!",
    wishlistUpdated : "Ενημερώσατε επιτυχώς τα αγαπημένα σας!",
    streetError : "Εισάγετε μία οδό",
    streetNumberError : "Εισάγετε ένα αριθμό",
    cityError : "Εισάγετε μία πόλη",
    order : "Παραγγελία",
    logout : "Αποσύνδεση",
    warning : "ΠΡΟΣΟΧΗ",
    logoutWarning : "Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;",
    yes : "NAI",
    no: "OXI",
    loginRegistration : "Είσοδος/Εγγραφή",
    login : "ΕΙΣΟΔΟΣ",
    passwordHelperText : "τουλάχιστον 8 χαρακτήρες και να περιλαμβάνει αριθμούς, κεφαλαία και μικρά",
    forgotPassword : "Ξεχασες το password?",
    or : "ή",
    facebookLogin : "ΕΙΣΟΔΟΣ ΜΕ FACEBOOK",
    googleLogin : "ΕΙΣΟΔΟΣ ΜΕ GOOGLE",
    register: "Εγγραφή νέου χρήστη",
    registerButton : "ΝΕΟΣ ΠΕΛΑΤΗΣ",
    exit : "ΕΞΟΔΟΣ",
    resetPassword : "Αλλαγή password",
    resetPasswordInfoA : "Πλητρολόγησε το e-mail σου παρακάτω",
    resetPasswordInfoB : "και θα λάβεις νέο password.",
    registerA : "ΕΓΓΡΑΦΗ",
    registerInfoA : "Με την εγγραφή μου, αποδέχομαι τους",
    registerInfoB : "γενικούς όρους χρήσης",
    registerInfoC : "τους",
    registerInfoD : "όρους προσωπικών δεδομένων",
    registerInfoE : "και τους",
    registerInfoF : "όρους χρήσης των προσφορών",
    verificationCode : "Επιβεβαίωση Κωδικού",
    verificationInfo : "Παρακαλώ εισάγετε τον 4ψήφιο κωδικό που στάλθηκε στο:",
    enterCode : "Εισαγωγη κωδικου",
    errorCode : "Λανθασμένος Κωδικός Επιβεβαίωσης, Παρακαλώ προσπαθείστε ξανά",
    repeat : "ΕΠΑΝΑΛΗΨΗ",
    youSee : "Βλέπετε",
    from : "από",
    stockWarning: "Δεν υπάρχει άλλο απόθεμα!",
    leaveAComment: "Γράψετε ένα σχόλιο...",
    saveReview: "Προσθηκη αξιολογησης",
    reviews: "ΑΞΙΟΛΟΓΗΣΕΙΣ",
    qrCodeBuy: "Αγοράστε εύκολα και γρήγορα με ένα κλικ",
    qrCodeScanAndroid: "Σκανάρετε για να κατεβάσετε την εφαρμογή μας σε Android",
    qrCodeDownload: "Κατεβάστε την εφαρμογή μας σε Android",
    qrCodeBuyMobile: "Κατεβάστε με ένα κλικ την εφαρμογή για να κάνετε τα ψώνια σας αμέσως",    
    qrCodeScanIos: "Σκανάρετε για να κατεβάσετε την εφαρμογή μας σε IOs",    
    qrCodeForAndroid: "Για Android",
    qrCodeForIos: "Για IOs",
    photoUpload: "Ανέβασμα φωτογραφίας",
    edit: "ΕΠΕΞΕΡΓΑΣΙΑ",
    delete: "ΔΙΑΓΡΑΦΗ",
    addCart: "Προσθηκη στο καλαθι",
    noEvaluation: "Δεν υπάρχει ακόμη καμία αξιολόγηση.",
    onlyLoggedInCustomers: "Μόνο οι συνδεδεμένοι πελάτες που έχουν αγοράσει το προϊόν μπορούν να αφήσουν μια αξιολόγηση.",
    beTheFirstReview: "Γίνετε ο πρώτος που θα αξιολογήσει αυτό το προϊόν.",
    seeMoreProducts: "Δείτε περισσότερα",
    favorite: "ΑΓΑΠΗΜΕΝΑ",
    noFavoritesYet: "Δεν έχετε βάλει κάποιο προϊόν στα αγαπημένα",
    deleteProduct: "Είστε σίγουροι θέλετε να αφαιρέσετε αυτό το προϊόν;",
    deleteProduct1: "Είστε σίγουροι θέλετε να αφαιρέσετε αυτό το προϊόν",
    fromCart: "από το καλάθι;",
    deleteAddress: "Είστε σίγουροι θέλετε να διαγράψετε αυτή τη διεύθυνση;",
    danger: "ΠΡΟΣΟΧΗ",
    connectionIssue: "Προσοχή! Η αποστολή της παραγγελίας δεν ήταν επιτυχής. Παρακαλώ ελέγξτε την σύνδεσή σας.",
    sendOrder: "Αποστολή παραγγελίας",
    pleaseWait: "Παρακακλώ περιμένετε...",
    noMoreProductsInStock: "Δεν μπορείτε να προσθέσετε περισσότερα τεμάχια στο καλάθι σας από το απόθεμα!",
    productAddedCart: "Το προϊόν προσtέθηκε στο καλάθι σας!",
    home: "Αρχική",
    myAccount: "Λογαριασμός",
    ckeckout: "TAMEIO",
    all:"Όλα",
    signIn:"Είσοδος",
    search: "Αναζήτηση",
    productSKU: "Κωδικός",
    productSKUFull: "Κωδικός προϊόντος:",
    language: "Γλώσσα"
}