import React from 'react'

const TermsTextEnglish = ()=> {
    return(
        <p>To complete your orders you will be asked each time individually to confirm that you agree to the following terms of use.<br/>
         - The copyright of the Content (eg programs, information, data) and the Services of the website (site) shoppingcart.gr that have been introduced in the Network, are protected by Greek, EU and international copyright laws. No part of this site may be reproduced, stored in a retrieval system, or transmitted, in any form or by any means, without the prior written permission of the Content Website. Any playback, reissue, upload, release, Dissemination or transmission or any other use of the Content in any way or means for commercial or other purposes is permitted only with the prior written permission of shoppingcart.gr or any other copyright holder.<br/>
         - The online store shoppingcart.gr provides the Content (eg information, names, photos, illustrations), products and services available through the website "exactly as they are". In no case the online store shoppingcart.gr is responsible for any claims of legal or civil and / or criminal nature or for any damage (positive, special or negative, which indicatively and not restrictively, divisively or cumulatively consists in loss of profits, data, lost profits, monetary satisfaction, etc. ) by visitors of the website or third parties due to a reason related to the operation or not or the use of the website or the inability to provide services or information available from it or due to any unauthorized interventions of third parties in products or services and / or information available through it. The photos of the products are indicative and we do not bear any responsibility for any spelling mistakes.<br/>
         - Prices are subject to change without notice. Price commitment can only be made online and is completed with the shipment confirmation. The store with the brand name shoppingcart.gr is not obliged to provide any guarantee for the availability of its products, but undertakes to immediately inform its customers about any deficiencies or delayed shipments.<br/>
         - The customer from the moment the order is processed by the order department which has confirmed his order, ensures that he will receive the product at the price at which he "accepted" the transaction even if the price changes in the process. In case the products or services are mentioned on the website with a wrong price due to a typographical error then the orders are automatically canceled. Shoppingcart.gr is not responsible for any damages that arise to the customer / user of the online store from the execution or not of the orders or from the delay of execution for any reason.<br/>
         - The online store shoppingcart.gr reserves the right to modify, add, change the content or services of the website, as well as the terms of use, at any time it deems necessary and without prior notice, by simply announcing them through its website.<br/>
          ABOUT THE ISSUE OF INVOICE UNDER LAW 39A (Law 2859/2000) FOLLOW THE FOLLOWING INSTRUCTIONS:<br/>
            1. SEND THE NECESSARY DOCUMENTS<br/>
            2. REGARDING THE PAYMENT OF THESE ORDERS WILL BE STRICTLY MADE BY BANK DEPOSIT FROM A COMPANY ACCOUNT OR THROUGH A COMPANY CARD.<br/>
            3. THE ACCOUNTING OF OUR STORE MAKES CHECKS ON THE CORRECTNESS OF DATA YOU APPLY AS FOLLOWS: AS TO THE DELIVERY OF THE PRODUCT WILL BE MADE BY THE LEGAL REPRESENTATIVE OR ANY OTHER AUTHORIZED TO BRING THE ORIGINAL SIGNATURE OF THE HEADQUARTERS OF OUR STORE AND WITH PROOF OF IDENTITY.<br/>
            4. CUSTOMERS MUST BE AWARE THAT THE ORDER IS AN OFFER AND THAT THEY CAN PURCHASE UP TO ONE ITEM. FURTHERMORE THE CUSTOMERS MUST KMOW THAT IF ALL THE ABOVE ARE NOT FOLLOWED THE AUTOMATIC CANCELLATION OF RESERVATION WILL TAKE PLACE AFTER 48 HOURS FROM TELEPHONE CONFIRMATION.<br/>
         - Our company and you are subject to the exclusive jurisdiction of the Greek courts regarding the resolution of disputes that may arise from this Agreement. In the event that any provision of this Agreement is deemed illegal, invalid or for any reason not applicable, this provision shall be deemed divisive and shall not affect the validity and applicability of the other provisions.<br/>
          Return Policy - Right of Withdrawal<br/> 
          If the customer is a natural person (consumer) who purchases the product for personal use and not for the service of his individual activity, then he is entitled to withdraw from the sale within 14 calendar days, returning the product to its original state. , charged with the return costs, according to § 10 of article 4 of Law 2251/1994.<br/>
         You have the right to withdraw from the distance purchase without justification, in writing, within 14 calendar days returning the product to its original condition. This right starts from the day of receipt of the products. Sending the withdrawal statement or sending the products within 14 calendar days will be considered sufficient for the timely exercise of the right of withdrawal. The statement is sent to the store with the brand shoppingcart.gr in the form of an RMA form.<br/>
         The products are returned at the customer's expense or presented by the customer himself at the headquarters of the company based in MESOGEION 131 MAROUSI PC 15126.<br/>
         Consequences of withdrawal<br/>
         In the event of a valid exercise of the right of withdrawal, any impairment of the value of the goods should be remedied with monetary compensation, only in the case and to the extent that it is due to the treatment of the product which escapes the usual examination of features and functionality of the product. "Ordinary examination of the characteristics and functionality of the product" means the testing of the relevant goods, as it may have taken place and as is customary in a physical store.<br/>
         The customer will bear the return costs. Any financial obligation for the payment of amounts (eg transport costs, compensation) must be fulfilled within 30 days, starting from the time of sending the declaration of withdrawal by you or the return of the goods.<br/>
         The right of withdrawal from article 4 § 10 of Law 2251/1994 does not apply:<br/>
         In cases where the price of the products has been paid in our physical store (MESOGEION 131 MAROUSI PC 15126) and at the same time, they have been received from the physical store , as the sale is not considered to have been made remotely.<br/>
         In case of valid exercise of the right of withdrawal and provided that the product has been returned to the headquarters of shoppingcart.gr, the value of the product will be returned to the customer. It is expressly agreed and the customer already provides the irrevocable order and consent to this, our company is entitled to withhold from the refund the return costs, which are borne by the customer as well as any compensation in case of damage or impairment of the product due to wear or damage to the product due to customer actions.<br/>
         Depending on the payment method chosen by the customer when ordering, the refund will be made accordingly:<br/>
         For an online Credit Card, the refund will be made on the card.<br/>
         For COD or bank deposit you must give us the IBAN of a bank account, the name of the beneficiary as well as the bank to which it relates. </p>
    )
}

export default TermsTextEnglish;