import React from 'react';
import '../src/styles/style.css'
import Main from './screens/main'
import feathersStore from './feathersStore';
import { Provider } from 'mobx-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {

  return (
    <>
    <Provider feathersStore={feathersStore}>       
      <Main/>
    </Provider> 
    </>
  );
}

export default App;
