import React, { useState, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/PhonelinkRing';
import SignInButton from './navElements/signInButton'
import CartButton from './navElements/cartButton/CartButton';
import WishListButton from './navElements/wishListButton';
import LanguageButton from '../../buttons/languageButton'
import AccountButton from './navElements/accountButton'
import { inject, observer } from "mobx-react";
import SearchBar from './searchBar';
import Hamburger from './Hamburger';
import {Link} from 'react-router-dom';
import './TopBar.css';
import PaymentIcon from '@mui/icons-material/Payment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import topBarLogo from '../../img/shopp_Width220.png';
import Collapse from '@mui/material/Collapse';

// Translations
import useTranslate from '../../..//hooks/useTranslate';
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";

const TopBar = ({onClickSignIn, feathersStore})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);  

  const [settingsPhone, setSettingsPhone] = useState("");
  const [showResponsiveArea, setShowResponsiveArea] = useState(false) 

  const resetProducts = () => {
    feathersStore.setSearchCategory({searchText: "", title: commonEL.all, titleEnglish: commonEN.all});
  } 

  const toggleResponsiveArea = () => {
    setShowResponsiveArea(oldValue => !oldValue);
  }

  useEffect(() => {
    const phoneNumber = feathersStore.settings?.phoneNumbers &&
    feathersStore.settings?.phoneNumbers.find(p => p.numberEnabled).phoneNumber;
    setSettingsPhone(phoneNumber);
  },[feathersStore.settings?.phoneNumbers])
   
  return(
    <>
    <div className="top-bar-nav" >
      <div className="nav-nav-left">
        <Link to="/" onClick={() => resetProducts()} className="navbar-brand" >
          <img
          aria-label='appdate logo'
            width="120"
            alt="AppDate Logo"
            src={topBarLogo}
          />  
        </Link>
        <div className="nav-SignIn">
          <span><a className="nav-button-SignIn undecorated-link" href={`tel:${settingsPhone}`}>
            <PhoneIcon className="calendar-icon" style={{ fontSize: 30 }}/><span className="phone-number">
              {settingsPhone}
            </span></a></span>
        </div> 
      </div>       
      <div className="nav-nav-fill">
        <SearchBar />         
      </div>       
      <div className="nav-nav-right ">
        {feathersStore.isAuthenticated && feathersStore.user?.firstname === "default" &&
          <div className="nav-SignIn">
            <SignInButton 
              displayText={common.signIn}
              onClickSignIn={onClickSignIn} 
              aria-labelledby='Sign in'
            />
        </div> } 
        {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" &&
          <div className="nav-SignIn">
            <AccountButton aria-labelledby='Account'/>
          </div>}       
        {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" &&
          <div className="nav-SignIn cart-container">
            <WishListButton aria-labelledby='Wishlist'/>
          </div>
        }  
        <div className="nav-SignIn cart-container">
          <CartButton aria-label='cart'/>
        </div>       
        <div className="select-language">  
          <LanguageButton showCaption={false}/>
        </div> 
        <div className="nav-SignIn cart-container hamburger-show">
          <Hamburger aria-label='hamburger-menu' showResponsiveMenu={toggleResponsiveArea}/>
        </div>       
      </div>          
    </div> 

    <Collapse sx={{width: '100%'}} in={showResponsiveArea}>  
      <div id="responsiveDiv" className="responsive-area">
        <div className="responsive-element">
          <SearchBar  />
        </div>
          {feathersStore.isAuthenticated && feathersStore.user?.firstname === "default" &&
            <div className="responsive-element">
              <SignInButton 
                displayText={common.signIn}
                onClickSignIn={onClickSignIn} 
                aria-labelledby='Sign in'
              />
          </div> } 
          {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" &&
            <div className="responsive-element add-margin">
              <AccountButton aria-labelledby='Account'/>
            </div>}   
          <Link to="/checkout" className="language-styling responsive-element checkout-button add-margin">
            <span aria-labelledby='Sign In' className="nav-button-SignIn undecorated-link">
              <PaymentIcon className="calendar-icon" style={{ fontSize: 30 }}/>
              <span> {common.checkout}</span>
            </span>         
          </Link> 
          <div className="responsive-element">
            <LanguageButton showCaption={true}/>
          </div>
          <div className="responsive-element">
            <button className="swipe-button" onClick={toggleResponsiveArea}>
              <KeyboardArrowUpIcon className="calendar-icon" style={{ fontSize: 30 }}/>
            </button>          
          </div>
      </div>   
    </Collapse>     
  </>
  )
}

export default inject('feathersStore')(observer(TopBar));