import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import TreeItemCustom from './treeItemCustom';
//import useGoogleTranslate from '../../hooks/useGoogleTranslate';

const style = {
  root: {   
    maxHeight: 350,
    flexGrow: 1,
    maxWidth: 450  
  },
  positioning: {
    position: 'absolute',
    top: 10,
    left: -80
  }
};

const CategoriesMenu = ({node, feathersStore})=> {
  
  let navigate = useNavigate();
  //const { data, loading } = useGoogleTranslate(feathersStore.language, node.name);

  const [anchorEl, setAnchorEl] = useState(null);  
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePress = item => () => {       
    const payload =  {    
      categoryId: item.id,  
      title: item.name, 
      titleEnglish: item.options?.categoryNameEnglish,
      searchText: ""
    }; 
    feathersStore.setSearchCategory(payload);    
    navigate(`/Home/${feathersStore.translate('en', payload.title, payload?.titleEnglish)}`);
    handleClose();   
  }  

  const renderTree = (nodes) => {
  
    return(
           
      <TreeItemCustom 
        key={nodes.id} 
        nodeId={nodes.id.toString()}   
        lbl={feathersStore.translate('en', nodes.name, nodes.options.categoryNameEnglish)}
        onLabelClick={handlePress(nodes)}
        collapseIcon={<ExpandMoreIcon />}
        expandIcon={<ChevronRightIcon />}
        nodes={nodes} 
        renderTree={renderTree}      
      >         
      </TreeItemCustom>
    )
    
  } 
  ;
 
  return(
    <>
      <div className="dropdown">      
        <Menu
          id="cat-menu"
          anchorEl={anchorEl}          
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} 
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
     //     sx={style.positioning}
        >
          <TreeView            
            sx={style.root}            
            defaultExpanded={[]}            
          >
            {node.childrens ? 
              node.childrens?.map(renderTree) 
              :
              null
            }
          </TreeView>
        </Menu>
      </div> 
      <div 
        className="category-view-item"
      >
        <span 
          className={`category-text ${node.id === feathersStore.searchCategory?.categoryId && 'category-text-visited'}`}
            onClick={handlePress(node)}>{(feathersStore.language === 'en') && node.options?.categoryNameEnglish ?
            node.options.categoryNameEnglish : node.name}
        </span>
        {node.childrens.length > 0 && <span className="item-arrow"
          onClick={node.childrens.length > 0 ? handleClick : null}>
            <ArrowDropDownIcon />
          </span>}
        
      </div>
   </>
  )
}
export default inject('feathersStore')(observer(CategoriesMenu));
