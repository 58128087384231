import React from 'react';
import "./CategoriesImageButton.css";
import {API_URL} from "../../assets/globalConstants";

const CategoriesImageButton = ({image, onClick})=> {

    return(
        <button onClick={onClick} className="category-card" aria-label='Categories image'>
            <img aria-label='category image' alt="."  src={`${API_URL}/images/${image}`}/>
        </button>
    )
}

export default CategoriesImageButton;