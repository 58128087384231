import React, { useState, useEffect } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AppColors from '../../components/assets/colors';
import useTranslate from '../../hooks/useTranslate';
import { inject, observer } from "mobx-react";
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import axios from 'axios';
import Colors from "../../components/assets/colors";
import { API_URL } from "../../components/assets/globalConstants";
import Tooltip from '@mui/material/Tooltip';
import Compressor from 'compressorjs';
import SmallAlert from '../../components/modals/smallAlert';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";
import {accountEL} from "../../locales/el/account";
import {accountEN} from "../../locales/en/account";

const Camera = ({feathersStore})=> {

  const [imageToUpload, setImageToUpload] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  let account = useTranslate(feathersStore.language, accountEL, accountEN);  

  useEffect(() => {
    feathersStore.user?.avatar && 
      setImageToUpload({preview: `${API_URL}/images/${feathersStore.user?.avatar}`})
      //Google or Facebook picture
    !feathersStore.user?.avatar && feathersStore.user?.profilePicture &&
      setImageToUpload({preview: feathersStore.user.profilePicture})
  },[feathersStore.user])

  const handleChange = e => {
    if (e.target.files.length === 1) {
      const fileToCompress = e.target.files[0];      
      if(fileToCompress.size > 1048576){
        alert(`${account.bigFile}`);
        return;
      } 
      new Compressor(fileToCompress, {
        quality: 0.95, 
        width: 300,
        height: 300,
        success: (compressedResult) => {
          setImageToUpload({
            preview: URL.createObjectURL(fileToCompress),
            raw: new File([compressedResult], fileToCompress.name)
          }); 
        }
      });            
    }
  };

  const uploadPhoto = async(e) => {
    e.preventDefault();    
    try{
      setLoading(true);
      const { accessToken } = await feathersStore.getAccessToken();
      const formData = new FormData();        
      formData.append("uri", imageToUpload?.raw);         
      const res = await axios.post(`${API_URL}/uploads`, formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept': 'application/json',         
              'Authorization': accessToken 
            }}) 
      await feathersStore.patchUserAvatar(feathersStore.user?._id, imageToUpload.raw?.name) ;
      setLoading(false); 
      !loading && setShowAlert(true);
    }catch(err){
      setLoading(false);
      setShowError(true)
    };
   
  }

  return(
    <>
      <form className="column-flex-start-wrap">
        <label htmlFor="upload-button">
          {imageToUpload.preview ? (
            <Tooltip title={account.uploadAvatar} aria-label="upload image">
              <img aria-label='camera' src={imageToUpload.preview} alt="User's Image" width="200" height="200" />
            </Tooltip>
          ) : (
            <>
              <h5 className="upload-image">              
                {common.photoUpload}
              </h5>
            </>
          )}
        </label>
        <br />
        <input
          aria-label='upload photo'
          type="file"
          id="upload-button"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <label>{imageToUpload?.raw?.name}</label>
        <br />
        <GreenSubmitButton
          type="submit"
          title={account.submit} 
          loading={loading}
          disabled={!imageToUpload?.raw}
          onClick={uploadPhoto}
          >
          <span><PhotoCameraIcon style={{fontSize: 20, marginRight: 10}}/></span>        
          
        </GreenSubmitButton>
      </form>
      <SmallAlert
        show={showAlert} 
        styling="success"
        heading={common.updated} 
        onClose={() => setShowAlert(false)} 
        message={account.uploadSuccess} />
      <SmallAlert
        show={showError} 
        styling="warning"
        heading={common.updateError} 
        onClose={() => setShowError(false)} 
        message={account.uploadError} />
    </>
  )
}

export default inject('feathersStore')(observer(Camera));