import React from 'react'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite';
import Colors from '../assets/colors'


const AddToCartSmall = ({ onClick, favorite })=> {

    return(
        <button 
            aria-label='Add to cart'
            type="button"
            className="shoppingCart-button"
            onClick={onClick}
        >
            <FavoriteBorder style={{color: favorite ? '#a8dadc':'#ff9900'}}/>
        </button>
    )
}

export default AddToCartSmall;