import React, {useState, useEffect, useRef} from 'react';
import {
    FormControl,
    FormHelperText,
    Input, 
    Button, 
    Modal,
    InputAdornment,
    IconButton 
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils';
import FacebookLogin from 'react-facebook-login';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { DEFAULT_EMAIL, DEFAULT_PSW, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../assets/globalConstants";
import Box from '@mui/material/Box';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";


let emailElement;
let passwordElement;
let firstNameElement;
let lastNameElement;
let phoneElement;

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 600,  
  },
  height: 650,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const RegistrationCard = ({visible, onClick, close, feathersStore})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);

  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); 
  const [lastName, setLastName] = useState(""); 
  const [phone, setPhone] = useState("");  
  const [showPassword, setShowPassword] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false);  
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [secondTime, setSecondTime] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const isMountedRef = useRef(null);
    
  useEffect(() => {
    isMountedRef.current = true;    
  }, [visible]);  

  const firstNameChange = event => {    
    setFirstName(event.target.value);
    secondTime && firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    secondTime && lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    secondTime && phoneValidation(event.target.value);   
  };

  const emailChange = event => {    
    setEmail(event.target.value);
    secondTime && emailValidation(event.target.value);   
  };

  const passwordChange = event => {    
    setPassword(event.target.value);
    passwordValidation(event.target.value);   
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  }

  const emailValidation = val => {
     if (!Utils.validateEmail(val) ) {
      setEmailError(true);
    }else{
      setEmailError(false);
    }
  } 

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

  const passwordFocus = () => { 
    setSecondTime(true);   
  };

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        emailElement.focus();
    }
  }

  const enterEmailPressed = (ev) => {   
    if (ev.key === 'Enter') {
        phoneElement.focus();
    }
  }

  const enterPhonePressed = (ev) => {   
    if (ev.key === 'Enter') {
        passwordElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      createAccount();
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const createAccount = () => {
    feathersStore.sendApplication( firstName, lastName, email, phone,  password)     
    .then(async(application) =>{
      await feathersStore.logout();   
    onClick({
              applicationId: application._id,
              email,
              firstName,
              lastName,
              phone,
              password
        }); 
    });    
  };

  const responseFacebook = async(response) => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.facebookAuth(response.accessToken.toString())
      .then(async auth => {
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        await loginAsDefault();
        handleExit();
      });  
  }

  

  const responseGoogle = async(response) => {   
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.googleAuth(response.accessToken?.toString())
      .then(async auth => {
        await feathersStore.setObservables();
        signOut(); //--> SignOut from Google because the server handles auth and not Google with its cookies
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        signOut(); 
        await loginAsDefault();
        handleExit();
      });  
  }

  const responseGoogleFailed = (response) => {
    console.log(response);
  }

  const { signIn, loaded } = useGoogleLogin({
    onSuccess: responseGoogle,
    //onAutoLoadFinished,
    clientId: GOOGLE_CLIENT_ID,
    cookiePolicy: "single_host_origin",
    //loginHint,
    //hostedDomain,
    //autoLoad,
    isSignedIn: true,
    //fetchBasicProfile,
    //redirectUri,
    //discoveryDocs,
    onFailure: responseGoogleFailed,
   // uxMode,
  //  scope,
  //  accessType,
 //   responseType,
 //   jsSrc,
  //  onRequest,
  //  prompt
  })

  const loginAsDefault = async()=> {
    isMountedRef.current && setLoading(true);
    try{
      feathersStore.isAuthenticated && await feathersStore.logout();      
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
           // history.push('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  }  

  const onLogoutSuccess = res => {
    console.log("Logged out from Google")
  }

  const onFailure = () => {
    console.log("Failed to logout from Google")
  }
  
  const { signOut } = useGoogleLogout({
    //  jsSrc,
      onFailure,
      clientId: GOOGLE_CLIENT_ID,
   //   cookiePolicy,
    //  loginHint,
    //  hostedDomain,
   //   fetchBasicProfile,
    //  discoveryDocs,
   //   uxMode,
    //  redirectUri,
   //   scope,
   //   accessType,
      onLogoutSuccess
    })

  const handleExit = () => {
    setSecondTime(false);
    setEmailError(false);
    setPasswordError(false);
    setEmail('');
    setPassword('');
    close();
  }  

  return (
    <section>
      <Modal 
        open={visible} 
        onClose={close}
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
      <Box sx={style}>
        <div className="modalContainer">
          <div className="modalTitleContainer">
            <h1>{common.register}</h1>
          </div>
          <div className="modalRegisterFormContainer">
            <FormControl fullWidth={true}> 
              <Input
                autoFocus
                value={firstName}     
                error={firstNameError}
                inputRef={r => {
                  firstNameElement = r;
                }}
                aria-label='firstname'
                onChange={firstNameChange}              
                onKeyPress={enterFirstNamePressed}                
                placeholder={common.firstName + "*"}
              />
              {firstNameError && <FormHelperText id="component-error-text1" >{common.firstNameError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}>
              <Input
                value={lastName}     
                error={lastNameError}
                inputRef={r => {
                  lastNameElement = r;
                }}
                aria-label='lastname'
                onChange={lastNameChange}              
                onKeyPress={enterLastNamePressed}  
                placeholder={common.lastName + "*"}
              />
              {lastNameError && <FormHelperText id="component-error-text2" >{common.lastNameError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}> 
              <Input
                value={email}     
                error={emailError}
                inputRef={r => {
                   emailElement = r;
                }}
                aria-label='email'
                onChange={emailChange}              
                onKeyPress={enterEmailPressed}   
                placeholder={common.email + "*"}
              />
              {emailError && <FormHelperText id="component-error-text3" >{common.emailError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}>    
              <Input
                 value={phone}     
                 error={phoneError}
                 inputRef={r => {
                    phoneElement = r;
                 }}
                 aria-label='phone'
                 onChange={phoneChange}              
                 onKeyPress={enterPhonePressed}   
                placeholder={common.phone + "*"}
              />
              {phoneError && <FormHelperText id="component-error-text4" >{common.phoneError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}>
            <Input
              value={password}
              error={passwordError}              
              inputRef={r => {
                passwordElement = r;
              }}
              aria-label='password'
              type={showPassword ? 'text' : 'password'}
              onChange={passwordChange}
              onFocus={passwordFocus}          
              onKeyPress={submitEditing}              
              placeholder="Password*" 
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
             {passwordError && 
              <FormHelperText id="component-error-text5" color="secondary">{common.passwordHelperText}</FormHelperText>}
            </FormControl>
            <GreenSubmitButton
              aria-labelledby='Register'
              title={common.registerA}
              disabled = {!firstName || !email || !password || !lastName || !phone || firstNameError || lastNameError || phoneError || emailError || passwordError}
              onClick={createAccount}
              loading={loading}
             />
          </div>
          <div className="maybe">
            <div className="OR">
              <hr className="hr"/>
            </div>
            <a style={{margin: 5}}>{common.or}</a>
            <div className="OR">
              <hr className="hr"/>
            </div>
          </div>
          <div className="modalFacebookContainer">
            <FacebookLogin
              appId={FACEBOOK_APP_ID}              
              fields="name,email,picture"          
              callback={responseFacebook}             
              icon={<FacebookIcon />}
              textButton={common.facebookLogin}
              cssClass="my-facebook-button-class"
              aria-labelledby='facebook login'
            />          
            <Button
              sx={{
                marginLeft: "2vw",
                marginTop: "1vw"                
              }}
              onClick={signIn}
              aria-labelledby='google login'
              variant="contained" 
              color="warning"              
            >
              <GoogleIcon/><span>{common.googleLogin}</span>
            </Button>          
          </div>          
          <div className="modalExitButtonContainer">
              <p style={{fontSize: 12}}>{common.registerInfoA} <a href={'#'}>{common.registerInfoB}</a>, {common.registerInfoC} <a href={'#'}>{common.registerInfoD}</a> {common.registerInfoE} <a href={'#'}>{common.registerInfoF}</a>.</p>
            <Button 
              aria-labelledby='Exit'  
              style={{color: AppColors.red, fontSize: 18}} 
              onClick={handleExit}
            >{common.exit}</Button>
          </div>
        </div> 
        </Box>        
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(RegistrationCard));
