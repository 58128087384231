import React,{ useState } from 'react';
import CategoryPic from '../cards/categoryPic';
import ReactHtmlParser from 'react-html-parser';
import { inject, observer } from "mobx-react";
import { API_URL } from "../assets/globalConstants";


const About = ({ feathersStore })=> {
    
  return(
    <div className="settings-sub-container">
      { feathersStore.settings?.images &&
        <CategoryPic image={`${API_URL}/images/${feathersStore.settings?.images[0]}`}/>           
      }
      {feathersStore.settings?.shortDescription &&
        <div className="categoryView html-text ">
          {ReactHtmlParser(`<div>${feathersStore.translate('en', feathersStore.settings?.shortDescription, 
            feathersStore.settings?.shortDescriptionEnglish)}</div>`)} 
        </div>
      }
    </div>
  )
}

export default inject('feathersStore')(observer(About));