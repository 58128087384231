import React, {useEffect} from 'react';
import Modal from '@mui/material/Modal';
import { LoopCircleLoading } from 'react-loadingg';
import { inject, observer } from "mobx-react";
import Box from '@mui/material/Box';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 300,  
  },
  height: 270,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'},   
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
 
};

const LoadingOrderModal = ({visible, whenSent, whenError, feathersStore})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  
  useEffect(() => {
   visible && sendOrder();
  }, [visible]);

  const sendOrder = async() => {    
    const orderCreated = await feathersStore.createOrder(feathersStore.orderItem);
    if(orderCreated){
      emptyCart(); 
      feathersStore.setCartLength(0);
      whenSent();
    }else{
      alert(
        `${common.connectionIssue}`
      )
      whenError();
    }
    
  }

  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': "",
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fire_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }

  return (
    <section>
      <Modal 
        open={visible}        
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
        <Box sx={style}>
          <div className="body-container">
            <h3>{common.sendOrder}</h3>
            <div>{common.pleaseWait}</div>
            <div className="modalRegisterFormContainer">
                <LoopCircleLoading />
            </div>
          </div>
        </Box>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(LoadingOrderModal));
