import React from 'react';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";


const UnregisteredEmptyRating = ({ feathersStore })=> {
    let common = useTranslate(feathersStore.language, commonEL, commonEN); 

    return(
        <div>{common.onlyLogedInCustomers}</div>
    )
}

export default inject('feathersStore')(observer(UnregisteredEmptyRating));