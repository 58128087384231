import React from 'react'

const DeliveryOptionsTextEnglish = ()=> {
  return(
    <div className="terms-text">
      <h2>Delivery & shipping</h2>
      <p>
        In our company we make every effort to serve our buying public. 
        For this reason we have included as many ways of shipping and delivery of our products as possible, 
        including free shipping by the same means.
        Please read carefully the following shipping methods and any restrictions they may have.
      </p>    
      <h2>
        Local pickup
      </h2>
      <p>
        You can pick up your purchases from our store at 131 Mesogeion Street in Marousi (15126).
        <ul>
          <li>Monday-Wednesday-Saturday 7:00 – 16:00</li>
          <li>Tuesday-Thursday-Friday 7:00 – 19:00</li>
        </ul>       
      </p>
      <h2>
        Delivery by courier
      </h2>
      <p>
        Several of our products are shipped by courier company. 
        With EL.TA courier your order will be delivered directly to the address you have indicated to us. 
        The charge for each shipment depends on the combination of size and weight. 
        Shipping by courier does not appear on the completion page of your order 
        in case the weight of the order exceeds 7kg or in case you have included 
        even a bulky item that can not be sent by courier.
      </p>
      <h2>
        Shipping by transport (within Attica)
      </h2>
      <p>
        Shipping by shipments concerns orders that exceed the weight of 7kg and are divided into three categories:
        <ul>
          <li>0 to 5km, free shipping</li>
          <li>5 to 20km, 7.50 euros</li>
          <li>20km and up, 9.90 euros</li>
        </ul>
      </p>
      <h2>
        Shipping by transport outside Attica
      </h2>
      <h2>
        A. customer agency of choice
      </h2>
      <p>
        We deliver your order to the offices of the transport agency that you will indicate to us in Athens 
        without any financial burden from our projects to the agency. 
        The shipping costs of the agency are borne by the buyer. In this case cash on delivery does not apply.
      </p>
      <h2>
        B. with cooperating transport companies
      </h2>
      <p>
        In cooperation with transport companies or transport agencies, 
        we undertake to send your order to the city you have assigned to us and you receive it from the transport offices. 
        In cases where this is not possible (remote areas, cities outside the delivery network, etc.), 
        then we send your order to the transport offices in the nearest city in your area 
        that is on the delivery network from where you will pick it up. In this case cash on delivery does not apply.
      </p>
    </div>
  )
}
    
export default DeliveryOptionsTextEnglish;