const Colors = {
    amazonColor: '#ff9900',
    settingsText: '#393e46',
    primary: '#ea2c58',
    secondary: '#1fc281',
    process: '#c6e1c6',
    completed: '#c8d7e1',
    canceled: '#ff5c5c',
    refunded: '#ff9900',
    deleted: '#ff0000',
    red: '#b90039',
    reviewBtn: '#094d92'
}

export default Colors;