import { useState, useEffect } from 'react';


const useTranslate = (language, elSource, enSource)=> {
   
  const [source, setSource] = useState({})

  useEffect(()=> { 
    switch (language){
      case "el" : {
        setSource(elSource);
        break;
      }
      case "en" : {
        setSource(enSource);
        break;
      }
    }
  },[language]);

  return source

}

export default useTranslate;