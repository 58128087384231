import React from 'react'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import Colors from '../assets/colors'


const AddToCartSmall = ({ onClick })=> {


    return(
        <button aria-label='Add to cart' type="button" className="shoppingCart-button" onClick={onClick}>
            <ShoppingCart style={{color: Colors.amazonColor}}/>
        </button>
    )
}

export default AddToCartSmall;