import React from 'react';
import {Link} from 'react-router-dom';

const FaqTextEnglish = ()=> {
  return(
    <div className="terms-text">
      <h2>What payment methods do you accept?</h2>
      <p>
        You can find the payment methods <Link className="privacy-text" to="/paymentoptions">here</Link>.
      </p>    
      <h2>
        How can I receive my order?
      </h2>
      <p>
        You can find the shipping methods <Link className="privacy-text" to="/deliveryoptions">here</Link>.
      </p>
      <h2>
        Is it possible to return a product?
      </h2>
      <p>        
        If the customer is a natural person (consumer) who purchases the product for personal use and not for the service of his individual activity, then he is entitled to withdraw from the sale within 14 calendar days, returning the product to its original state. , charged with the return costs, according to § 10 of article 4 of Law 2251/1994.<br/>
        You have the right to withdraw from the distance purchase without justification, in writing, within 14 calendar days returning the product to its original condition. This right starts from the day of receipt of the products. Sending the withdrawal statement or sending the products within 14 calendar days will be considered sufficient for the timely exercise of the right of withdrawal. The statement is sent to the store with the brand woodpeckerart.gr in the form of an RMA form.<br/>
        The products are returned at the customer's expense or presented by the customer himself at the headquarters of the company based in MESOGEION 131 MAROUSI PC 15126.<br/>
        Consequences of withdrawal<br/>
        In the event of a valid exercise of the right of withdrawal, any impairment of the value of the goods should be remedied with monetary compensation, only in the case and to the extent that it is due to the treatment of the product which escapes the usual examination of features and functionality of the product. "Ordinary examination of the characteristics and functionality of the product" means the testing of the relevant goods, as it may have taken place and as is customary in a physical store.<br/>
        The customer will bear the return costs. Any financial obligation for the payment of amounts (eg transport costs, compensation) must be fulfilled within 30 days, starting from the time of sending the declaration of withdrawal by you or the return of the goods.<br/>
        The right of withdrawal from article 4 § 10 of Law 2251/1994 does not apply:<br/>
        In cases where the price of the products has been paid in our physical store (Iroon Polytechniou 77, Elefsina) and at the same time, they have been received from the physical store , as the sale is not considered to have been made remotely.<br/>
        In case of valid exercise of the right of withdrawal and provided that the product has been returned to the headquarters of woodpeckerart.gr, the value of the product will be returned to the customer. It is expressly agreed and the customer already provides the irrevocable order and consent to this, our company is entitled to withhold from the refund the return costs, which are borne by the customer as well as any compensation in case of damage or impairment of the product due to wear or damage to the product due to customer actions.<br/>
        Depending on the payment method chosen by the customer when ordering, the refund will be made accordingly:<br/>
        For an online Credit Card, the refund will be made on the card.<br/>
        For COD or bank deposit you must give us the IBAN of a bank account, the name of the beneficiary as well as the bank to which it relates.
      </p>
      <h2>
        Can I make changes to my order?
      </h2>
      <p>
        You can make any changes before sending your order.
        Call us at 2114115591 or contact us via the contact form here
        or to the email info.appdate@gmail.com stating the number of your order and the changes you want to make.
        We will confirm the new order with a new email.
      </p>
      <h2>
        Is there a physical store that I can visit?
      </h2>
      <p>
        You can visit us during store hours at our address Mesogeion 131 street 15126, Marousi.
      </p>
    </div>
  )
}
    
export default FaqTextEnglish;