import React, {useState} from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOrEditaddressModal from '../../components/modals/addOrEditAddressModal/AddOrEditAddressModal';
import AddressDeleteModal from '../../components/modals/addressDeleteModal';

const AddressComponent = ({
  onPress,
  item, 
  title,
  street,
  city,
  apartment,
  postcode,
  streetNumber,
  origin,
  active
}) => {

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  const [visibleAddNewAddress, setAddNewAddress] = useState(false);
  const [visibleDeleteAddress, setDeleteAddress] = useState(false);

  const  editAddress = () => {     
    setAddNewAddress(true); 
  };

  const closeNewAddress = () =>{
    setAddNewAddress(false); 
  }

  const  openDeleteAddressModal = () => {     
    setDeleteAddress(true);  
  };

  const closeDeleteAddressModal = () =>{
    setDeleteAddress(false); 
  }
  
  return (
    <div className="wishlist-object"> 
      <div className="address-object-radio">      
        <FormControlLabel 
          value={active} 
          control={<Radio />} 
          classes={{ label: 'addressLabel' }} 
          label={title} 
          checked={active}
          onChange={ onPress}/>     
      </div>
      <div className="adddress-object-info-container">
        {`${street} ${streetNumber}, ${city}, `}&nbsp;
        <a style={{color: 'grey'}}>{`Όροφος: ${apartment} ${postcode}`}</a>
      </div>
      <div className="address-object-end">
      <Button onMouseOver={changeCursor} onClick={editAddress} aria-label='Change address'>
        <EditIcon style={{color: 'grey'}}/>
      </Button>
      <Button onMouseOver={changeCursor} onClick={openDeleteAddressModal} aria-label='Delete address'>
        <CloseOutlinedIcon style={{color: 'grey'}}/>
      </Button>
      </div>
      <AddOrEditaddressModal visible={visibleAddNewAddress} close={closeNewAddress} itemToEdit={item}/>
      <AddressDeleteModal visible={visibleDeleteAddress} onClickNo={closeDeleteAddressModal} itemToDelete={item}/>
    </div>
  );
}

export default AddressComponent;
