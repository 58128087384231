import React, { useEffect } from 'react'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import {Link} from 'react-router-dom'
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../../../hooks/useTranslate';
import {commonEL} from "../../../../locales/el/common";
import {commonEN} from "../../../../locales/en/common";


const AccountButton = ({ feathersStore })=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);  

  return(
    <Link to="/account" className="undecorated-link">
      <span aria-labelledby='Sign In' className="nav-button-SignIn undecorated-link">
        <PermContactCalendarIcon className="calendar-icon" style={{ fontSize: 30 }}/>
        <span>{common.myAccount}</span>
      </span>
    </Link>
  )
}

export default inject('feathersStore')(observer(AccountButton));