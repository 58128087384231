import React,{useState, useEffect} from 'react'
import CartObject from './cartObject/CartObject';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { inject, observer } from "mobx-react";
import ConfirmationModal from '../../components/modals/confirmationModal';
import * as cloneDeep from 'lodash/cloneDeep';
import useTranslate from '../../hooks/useTranslate';
import GreenSubmitButton from "../../components/buttons/greenSubmitButton";
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";
import {cartEL} from "../../locales/el/cart";
import {cartEN} from "../../locales/en/cart";
import Colors from '../../components/assets/colors';

const CartTable = ({feathersStore})=> {

  let navigate = useNavigate();

  let common = useTranslate(feathersStore.language, commonEL, commonEN);  
  let cart = useTranslate(feathersStore.language, cartEL, cartEN);  

  const [total, setTotal] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [cart_products, setCart_products] = useState([]);
  const [itemToDelete, setItemToDelete] = useState({});
  const [coupon, setCoupon] = useState("");

  //Modal Controls
  const [visible, setVisible] = useState(false);  

  useEffect(() => {
    const cartFromStorage = JSON.parse(localStorage.getItem('fire_cart'))  
    if(cartFromStorage){
    setCart_products(cartFromStorage); 
    updateTotals(cartFromStorage);   
    }
  }, []);
 
const handleChangeQuantity = (e, item) => {    
  const newQ = e.target.value;
  const index = cart_products.indexOf(itemToDelete);
  let clonedCartProducts = [...cart_products];
  let productClone = cloneDeep(item);
  Object.assign(productClone, {quantity: newQ, totalPrice: (+item.price * +newQ).toFixed(2)});
  clonedCartProducts.splice(index, 1, productClone); 
  setCart_products(clonedCartProducts);  
  localStorage.setItem("fire_cart", JSON.stringify(clonedCartProducts));    
  updateTotals(clonedCartProducts);
}

   
  const removeItem = item => {
    setVisible(true);
    setItemToDelete(item)
  }

  const deleteItem = ()=> {
    const index = cart_products.indexOf(itemToDelete);
    let clonedCartProducts = [...cart_products];
    clonedCartProducts.splice(index, 1); 
    setCart_products(clonedCartProducts);     
    localStorage.setItem("fire_cart", JSON.stringify(clonedCartProducts));     
    updateTotals(clonedCartProducts);
    feathersStore.setCartLength(clonedCartProducts.length);    
    setVisible(!visible);
  }

  const updateTotals = cartProducts => {
    setTotal(cartProducts.map(element => +element.totalPrice)
      .reduce((t, sum) => sum + t, 0)); 
    setTotalQuantity(cartProducts.map(element => +element.quantity)
      .reduce((t, sum) => sum + t, 0));     
  };

  const closeConfirm = ()=> {
    setVisible(!visible);
  }

  const goToProduct = async (item) => {
    const index = cart_products.indexOf(item);        
    const prod = await feathersStore.getProduct(item.product_id);
    prod && navigate(`/product/${(feathersStore.language === 'en') && prod?.nameEnglish ?
      prod.nameEnglish : prod.name}`,{
        state: {id: `${prod._id}`,  payload: prod}     
    });    
  };

  const goToCheckout = () => {
    navigate('/checkout');
  }

  const renderCartProducts = cart_products.map((item, index) => (
    <CartObject
      key={index}
      item={item}             
      onChange={ e=> handleChangeQuantity(e, item)}      
      onClickDelete={()=> removeItem(item)}
      goToProduct={() => goToProduct(item)}
    />
  ))

  return(
  <>
    <>
      <ConfirmationModal 
        visible={visible}
        product={itemToDelete.name}
        onClickNo={closeConfirm}
        onClickYes={deleteItem}
      />     
    </>
    <div className="table-container">     
      <div className="table-header">
        <div className="header-icons"/>
        <div className="header-element">
          <h5>{common.product}</h5>
        </div> 
        <div className="header-element">
          <h5>{common.productSKU}</h5>
        </div>       
        <div className="header-element">
          <h5>{common.price}</h5>
        </div>
        <div className="header-element">
          <h5>{common.quantity}</h5>
        </div>
        <div className="header-element">
          <h5>{common.subTotal}</h5>
        </div>   
      </div>
      <div className="table-body">
        {renderCartProducts}
      </div>
      <div className="table-footer">          
        <input aria-label='upload photo' type="text" placeholder={cart.couponCode} className="flex-1 coupon-input"/>
        <GreenSubmitButton 
          disabled={!coupon}
          color={Colors.primary}
          aria-labelledby='Coupon apply' 
          title={cart.couponButton}
        ></GreenSubmitButton>
      </div>      
    </div>    
    <div className="cart-total-table">
      <div className="cart-total-header">
        <h2>{cart.cartTotals}</h2>
      </div>
      <div className="cart-total-body">
        <p>{common.products}: <span className="cart-footer-items">{feathersStore.cartLength}</span></p>
        <p>{common.items}: <span className="cart-footer-items">{totalQuantity}</span></p>
        <h4>{common.total}: <span className="cart-footer-items">{(+total).toFixed(2)} €</span></h4>
      </div>
      <div className="cart-total-footer">        
        <GreenSubmitButton aria-labelledby='Complete order' disabled={total === 0}
          color={Colors.secondary}
          title={cart.completeOrder} 
          onClick={goToCheckout} 
          ></GreenSubmitButton>        
      </div>
    </div>   
  </>
  )
}

export default inject('feathersStore')(observer(CartTable));