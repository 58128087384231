import React, { useEffect } from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TermsText from './termsText';
import TermsTextEnglish from './termsTextEnglish';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {footerEL} from "../../../locales/el/footer";
import {footerEN} from "../../../locales/en/footer";

const TermsNConditions = ({ feathersStore })=> {

  let footer = useTranslate(feathersStore.language, footerEL, footerEN);    
    
  return(
    <div className="settings-sub-container">
   {/*   <CategoryPic image={back}/>    */}
      <div className="pageIcon">
        <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
      </div>
      <p className="terms-title">{`-${footer.termsOfService}-`}</p>     
      <div className="categoryView">
        {feathersStore.language === 'en' ? <TermsTextEnglish/> : <TermsText/>}
      </div> 
    </div>
  )
}

export default inject('feathersStore')(observer(TermsNConditions));