import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const SmallAlert = ({ show, styling, heading, onClose, message}) =>{ 
    
  return(
    <>
    <div className="cart-updated-alert">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <Alert
         // variant="filled" 
          severity={styling}       
          onClose={onClose}
        >
          <AlertTitle>{heading}</AlertTitle> 
          <p>{message}</p>
        </Alert>
      </CSSTransition>
    </div>
  </>
  )
}

export default SmallAlert;