import { useState, useEffect } from 'react';
import { useLazyTranslate } from 'react-google-translate';

const useGoogleTranslate = (language, txt)=> {
   
  const [translate, { data, loading }] = useLazyTranslate({
    language 
  })

  useEffect(() => {
    
    txt &&  translate(txt, language);
   
  }, [translate, txt])

  return { data, loading }

}

export default useGoogleTranslate;