import React, {Fragment} from 'react'
import Close from '@mui/icons-material/Close';
import "./CartObject.css";
import { API_URL } from "../../../components/assets/globalConstants";
import { inject, observer } from "mobx-react";
import useTranslate from '../../../hooks/useTranslate';
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";

const CartObject = ({ item, onChange, onClickDelete, goToProduct, feathersStore}) => {
  
  let common = useTranslate(feathersStore.language, commonEL, commonEN);

  return(
  <div className="cart-object">
    <div className="cart-icons">
      <div>
        <button className="close-button" aria-label='Delete' onClick={onClickDelete}>
          <Close style={{color: 'red'}}/>
        </button> 
      </div>
      <div onClick={goToProduct}>
        <img aria-label='product' alt="" style={{width: 60, height: 50}} src={`${API_URL}/images/${item.image?.name}`} />
      </div>       
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        {common.product}:
      </div>
      <button className="link-button" aria-label='Go to product' onClick={goToProduct}>
        {feathersStore.translate('en', item.title, item.titleEnglish)}
        {item.variation && ("-" + item.variation) }
        {item?.extra.map((ex, index) => <Fragment key={index}>- {ex.name}</Fragment>)}
      </button>
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        {common.productSKU}:
      </div>
      {item.productSKU}
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        {common.price}:
      </div>
     {item.price} €
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        {common.quantity}:
      </div>
      <input aria-label='item stock' onChange={ e => onChange(e)} onKeyDown={(event) => {event.preventDefault();}} 
        type="number" min="1" max={item.handleStock ? item.stock : null} defaultValue={item.quantity} 
        className="quantity-input"/>
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        {common.subTotal}:
      </div>
      {item.totalPrice} €
    </div>    
  </div>
  )
}

export default inject('feathersStore')(observer(CartObject));