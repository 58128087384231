import React from 'react';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const CloseButton = (props)=> {

    function changeCursor(e) {
        e.target.style.cursor = 'pointer';
      }

    return(
        <button 
            aria-label='Close'
            className="closeButton"
            onMouseOver={changeCursor}
            onClick={props.onClick}>
            <CancelPresentationIcon style={{fontSize: 40, color: 'red'}}/>
        </button>
    )
}

export default CloseButton;