import React from 'react'

const PaymentOptionsTextEnglish = ()=> {
  return(
    <div className="terms-text">
      <h2>Order payment</h2>
      <p>
        In our company we make every effort for the best possible service of our customers. 
        We have therefore included as many payment methods as possible.
      </p>    
      <h2>
        Pay on delivery
      </h2>
      <p>
        By choosing cash on delivery you pay the full amount of the order, along with the shipping upon receipt of your order by the courier. 
        In this case the cash on delivery charge is 2.50 euros (including 24% VAT). 
        Also in the case of free shipping from us, you can pay for your order directly to our driver, who will deliver the order to you at no extra charge. 
        COD in that case does not apply.
      </p>
      <h2>
        Credit card – Debit card
      </h2>
      <p>
        In our store we accept credit, debit or prepaid cards of any bank. 
        In case of payment in our physical store, the charge is made using P.O.S. in case of purchase from the online store e-shop and payment by credit card, 
        the user is transferred to the secure environment of the cooperating e-banking platform (PayPal). 
        This site is secure and covered by an SSL certificate. 
        The details of the credit debit or prepaid card that will be used are not disclosed to us and are not kept in our company, 
        as they are not entered on our website. Read more about transaction security in terms of use.
      </p>
      <h2>
        Bank deposit
      </h2>
      <p>
        Payment by deposit in a bank account. 
        You can choose the bank that facilitates you to deposit the amount of your order.
      </p>
    </div>
  )
}
    
export default PaymentOptionsTextEnglish;