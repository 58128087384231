import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box'

import { Link } from 'react-router-dom';
import "../screenComponents/footer/Footer.css";

import LanguageButton from '../buttons/languageButton'

import { inject, observer } from "mobx-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout="2000" direction="up" ref={ref} {...props} />;
});


  
  const greekText = {
    privacyPolicy: "Προσωπικά Δεδομένα",    
    cookiesTitle: "Χρήση cookies",
    cookiesText: "Χρησιμοποιούμε μόνο τα απολύτως απαραίτητα cookies. Σε κανένα από αυτά δεν αποθηκεύονται τα προσωπικά σας δεδομένα.",
    cookiesAccept: "Αποδοχή"
  }

  const englishText ={
    privacyPolicy: "Privacy Policy",    
    cookiesTitle: "Cookies Usage",
    cookiesText: "We only use the minimum required cookies. Your personal data are not stored in any of them.",
    cookiesAccept: "Accept"  
  }  
  
  const style = {
    root: {
      margin: 0,
      padding: 2,
      backgroundColor: 'grey.500',
    },
    languageButton: {
      position: 'absolute',
      right: 1,
      top: 1,
      color: 'grey.500',
    },
  };  

  const DialogTitle = ({ children, onClose, ...other }) => {   
    return (
      <MuiDialogTitle disableTypography sx={style.root} {...other}>
        <Typography variant="h6">{children}</Typography> 
        <Box sx={style.languageButton}>
          <LanguageButton showCaption={false}/>
        </Box>
      
      </MuiDialogTitle>
    );
  };   
  
  const CookiesModal = ({ feathersStore }) => {

  useEffect(() => {
    if(feathersStore.showCookies){
      const showCookies = localStorage.getItem('show_cookies');
      showCookies === "false" && feathersStore.setShowCookies(false);      
    }    
  }, [feathersStore.showCookies])

  const okCookies = () => {
    feathersStore.setShowCookies(false)
    localStorage.setItem('show_cookies', 'false');    
  }

  const goToPrivacyPolicy = () => {
    feathersStore.setShowCookies(false);
  }

  const onClose = () => {

  }

  const toggleLanguage = lang => {
    feathersStore.setLanguage(lang);
  }

  return (
    <div>      
      <Dialog      
        open={feathersStore.showCookies}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >       
        <DialogTitle id="responsive-dialog-title">{feathersStore.language === "en" ? englishText.cookiesTitle : greekText.cookiesTitle}</DialogTitle>
        <MuiDialogContent sx={{padding: 2}}>
          <Typography gutterBottom>
            {feathersStore.language === "en" ? englishText.cookiesText : greekText.cookiesText}
          </Typography>
        </MuiDialogContent>
        <MuiDialogActions sx={{margin: 0, padding: 1}}>
          <Link onClick={goToPrivacyPolicy} className="privacy-text" to="/privacypolicy#voo">
            <span   color="primary">
              {feathersStore.language === "en" ? englishText.privacyPolicy : greekText.privacyPolicy}
            </span>
          </Link>
          <Button autoFocus onClick={okCookies} color="secondary">
            {feathersStore.language === "en" ? englishText.cookiesAccept : greekText.cookiesAccept}
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}

export default inject('feathersStore')(observer(CookiesModal));
