import React from 'react';
import qrCodeAndroid from '../img/qr-code-shoppingcart-android.png'
import MobileStoreButton from 'react-mobile-store-button';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const QrCode = ({ feathersStore }) => {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);

  const qrCodeIos = 'https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/c8879a033466c894458458258ccaa269/3ded0c5dfae2954b5927615ef497d274.png';
  const androidUrl = 'https://play.google.com/store/apps/details?id=com.fireshop';
  const urlIos = 'https://apps.apple.com/gr/app/shoppingcartios/id1574915363?itsct=apps_box_badge&amp;itscg=30200'
  const imageIos = 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1625702400&h=756295c0d4e95f66799873c9eee485ea'

  return (
    <div className="qr-code-container">    
        <h3 className='title-web'>{common.qrCodeBuy}</h3>
        <h3 className='title-mobile'>{common.qrCodeBuyMobile}</h3>
        <div className='qr-codes-wrap'>
          <div className="qr-code-android column-flex-center">
            <p className="scan-desk">{common.qrCodeScanAndroid}</p>
            <img aria-label='qr code android' src={qrCodeAndroid} alt="QR Code" width="120" height="120" />
          </div>
          <div className="qr-code-ios column-flex-center">
            <p className="scan-desk">{common.qrCodeScanIos}</p>
            <img aria-label='qr code ios' src={qrCodeIos} width="120" height="120"/>
          </div>
        </div>
        <div className="store-icons">
            <div className='android-icons'>
                <h5>{common.qrCodeForAndroid}</h5>
                <MobileStoreButton
                    store="android"
                    url={androidUrl}
                    linkProps={{ title: 'Android Store Button' }}
                    style={{ height: '60px', width: '140px' }}
                />               
            </div>
            <div className='ios-icons'>
                <h5>{common.qrCodeForIos}</h5>
                 <a href={urlIos}>
                  <img src={imageIos} alt="Download on the App Store" />
                </a>
            </div>
        </div>     
    </div>
    )
}

export default inject('feathersStore')(observer(QrCode));