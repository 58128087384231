import React, { useEffect, useState } from 'react'
import ScrollToTopBtn from "./scrollToTopButton";
import Phone from '@mui/icons-material/Phone'
import Email from '@mui/icons-material/MailOutline'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import HomeWork from '@mui/icons-material/HomeWork';
import "./Footer.css";
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';
import useGoogleTranslate from '../../../hooks/useGoogleTranslate';
import CookiesModal from '../../modals/cookiesModal';

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {footerEL} from "../../../locales/el/footer";
import {footerEN} from "../../../locales/en/footer";

const Footer = ({ feathersStore })=> {

  let footer = useTranslate(feathersStore.language, footerEL, footerEN);

  const [settingsPhone, setSettingsPhone] = useState("");
  const [settingsEmail, setSettingsEmail] = useState(""); 
  const [settingsSiteUrl, setSettingsSiteUrl] = useState("");
  const [settingsLatitude, setSettingsLatitude] = useState("38.0407648");
  const [settingsLongitude, setSettingsLongitude] = useState("38.0407648");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  useEffect(() => {
    const phoneNumber = feathersStore.settings?.phoneNumbers &&
      feathersStore.settings?.phoneNumbers.find(p => p.numberEnabled).phoneNumber;
    setSettingsPhone(phoneNumber);
    setSettingsEmail(feathersStore.settings?.email);   
    setSettingsSiteUrl(feathersStore.settings?.siteUrl);
    feathersStore.settings?.latitude && setSettingsLatitude(feathersStore.settings?.latitude);
    feathersStore.settings?.longitude && setSettingsLongitude(feathersStore.settings?.longitude);
    setFacebookUrl(feathersStore.settings?.facebookUrl);
    setInstagramUrl(feathersStore.settings?.instagramUrl);
    setYoutubeUrl(feathersStore.settings?.youtubeUrl);
    setTwitterUrl(feathersStore.settings?.twitterUrl);
  },[feathersStore.settings])

  return (
    <>
      <div className="footer">
        <span><ScrollToTopBtn /></span>
        <div className="footer-container">
          <div className="contact-info">
            <h3>{footer.contactHours}:</h3>
            <p className="hours">{footer.week}:<span className="hours-block">09:30 – 17:30</span></p>
            <p className="footer-row">
              <span className="block-element"><Phone /></span><span className="block-dots">:</span>
              <a href={`tel:${settingsPhone}`}>{settingsPhone}</a>
            </p>
            <p className="footer-row">
              <span className="block-element"><Email/></span><span className="block-dots">:</span> 
              {settingsEmail}
            </p>
            <p className="footer-row">
              <span className="block-element"><HomeWork/></span><span className="block-dots">:</span>  
              {feathersStore.translate('en', feathersStore.settings?.address, 
                feathersStore.settings?.addressEnglish)}
            </p>
            <p>{footer.findUs}            
              <a 
          //     href={`https://www.google.com/maps/@${settingsLatitude},${settingsLongitude},17z`} 
            //   href={`https://maps.googleapis.com/maps/api/staticmap?center=${settingsLatitude},${settingsLongitude}&zoom=12&size=400x400`}
                href={`https://www.google.com/maps/place/%CE%9C%CE%B5%CF%83%CE%BF%CE%B3%CE%B5%CE%AF%CF%89%CE%BD+131,+%CE%9C%CE%B1%CF%81%CE%BF%CF%8D%CF%83%CE%B9+151+26/@${settingsLatitude},${settingsLongitude},17z/data=!3m1!4b1!4m5!3m4!1s0x14a199245916ff01:0x37c67e9d27e6d085!8m2!3d38.0407648!4d23.8195597`} 
                target="_blank" 
                rel="noopener noreferrer">{footer.map}
              </a>.
            </p>
          </div>
          <div className="row-flex-center"> 
            <div className="social-media-icons">
              <h3>{footer.stayConnected}</h3>
              <div className="row-centered">
                {facebookUrl ? <a href={facebookUrl} target="_blank" rel="noopener noreferrer"><FacebookIcon /></a> : null}
                {instagramUrl ? <a href={instagramUrl} target="_blank" rel="noopener noreferrer"><InstagramIcon /></a> : null}
                {youtubeUrl ? <a href={youtubeUrl} target="_blank" rel="noopener noreferrer" className=""><YouTubeIcon /></a> : null}
                {twitterUrl ? <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className=""><TwitterIcon /></a> : null}
              </div>             
            </div>
            <div className="rules-use">
              <Link className="privacy-text" to="/about">{footer.aboutUs}</Link>
              <Link className="privacy-text" to="/contact">{footer.contact}</Link>
              <Link className="privacy-text" to="/privacypolicy">{footer.privacyPolicy}</Link>
              <Link className="privacy-text" to="/termsnconditions">{footer.termsOfService}</Link>
              <Link className="privacy-text" to="/paymentoptions">{footer.paymentOptions}</Link>
              <Link className="privacy-text" to="/deliveryoptions">{footer.deliveryOptions}</Link>
              <Link className="privacy-text" to="/faq">{footer.faq}</Link>
            </div>
          </div> 
        </div> 
        <span className="draw-line"></span>
        <div className="copyright">
          <p>POWERED BY <a href="https://appdate.gr" target="_blank" rel="noopener noreferrer">APPDATE.GR</a></p>
        </div>
      </div>
      {feathersStore.showCookies && <CookiesModal></CookiesModal> }
    </>
  )
}

export default inject('feathersStore')(observer(Footer));