import React from 'react'

const PaymentOptionsText = ()=> {
  return(
    <div className="terms-text">
      <h2>Πληρωμή παραγγελίας</h2>
      <p>
        Στην εταιρία μας κάνουμε κάθε δυνατή προσπάθεια για την καλύτερη δυνατή εξυπηρέτηση του αγοραστικού μας κοινού. 
        Ως εκ τούτου έχουμε συμπεριλάβει όσο το δυνατόν περισσότερους τρόπους πληρωμής.
      </p>    
      <h2>
        Αντικαταβολή
      </h2>
      <p>
        Επιλέγοντας πληρωμή με αντικαταβολή πληρώνετε όλο το ποσό της παραγγελίας, μαζί και με τα μεταφορικά κατά την παραλαβή της παραγγελίας σας από τον courier. 
        Σε αυτήν την περίπτωση η χρέωση της αντικαταβολής είναι 2,50 ευρώ (περιλαμβάνεται 24% Φ.Π.Α.). 
        Επίσης στις περιπτώσεις δωρεάν αποστολών από εμάς, μπορείτε να πληρώσετε την παραγγελία σας απευθείας στον οδηγό μας, 
        που θα σας παραδώσει την παραγγελία χωρίς άλλη χρέωση. Η αντικαταβολή σε εκείνη την περίπτωση δεν ισχύει.
      </p>
      <h2>
        Πιστωτική κάρτα – Χρεωστική κάρτα
      </h2>
      <p>
        Στο κατάστημά μας δεχόμαστε πιστωτικές, χρεωστικές ή prepaid κάρτες οποιασδήποτε τράπεζας. 
        Στην περίπτωση πληρωμής στο φυσικό μας κατάστημα, 
        η χρέωση γίνεται με την χρήση P.O.S. στην περίπτωση αγοράς από το ηλεκτρονικό κατάστημα e-shop 
        και πληρωμής με πιστωτική κάρτα, ο χρήστης μεταφέρεται στο ασφαλές περιβάλλον του συνεργαζόμενου τράπεζικού 
        οργανισμού (PayPal). Ο ιστότοπος αυτός είναι ασφαλής και καλύπτεται από πιστοποιητικό SSL. 
        Τα στοιχεία της πιστωτικής χρεωστικής ή prepaid κάρτας που θα χρησιμοποιηθεί δεν μας γνωστοποιούνται 
        κι δεν τηρούνται στην εταιρία μας, καθώς δεν εισάγονται στην δική μας ιστοσελίδα. 
        Διαβάστε περισσότερα για την ασφάλεια των συναλλαγών στους όρους χρήσης.
      </p>
      <h2>
        Κατάθεση σε τράπεζα
      </h2>
      <p>
        Πληρωμή μέσω κατάθεσης σε τραπεζικό λογαριασμό. 
        Μπορείτε να επιλέξετε την τράπεζα που σας διευκολύνει για την κατάθεση του ποσού της παραγγελίας σας.
      </p>
    </div>
  )
}
    
export default PaymentOptionsText;