import React, {useState, useEffect} from 'react';
import "./Product.css"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Colors from '../../components/assets/colors'
import NoneEvaluation from '../../components/screenComponents/noneEvaluation'
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import RegisteredEmptyRating from '../../components/cards/registeredEmptyRating'
import RatedCard from '../../components/cards/ratedCard'
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import UnregisteredEmptyRating from '../../components/cards/unregisteredEmptyRating'
import AddToWishListButton from '../../components/buttons/addToWishlistButton'
import MenuItem from '@mui/material/MenuItem';
import ProductCard from '../../components/cards/productCard/ProductCard';
import {LeftArrow, RightArrow} from '../../components/buttons/arrows';
import FormHelperText from '@mui/material/FormHelperText';
import { inject, observer } from "mobx-react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { API_URL } from "../../components/assets/globalConstants";
import SmallAlert from "../../components/modals/smallAlert";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { CSSTransition } from 'react-transition-group';
import { styled } from '@mui/material/styles';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

import useTranslate from '../../hooks/useTranslate';

let initialCartBiggestId = 0;
let cart = [];

const GoldRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

const Product = ({ feathersStore })=> {

  let navigate = useNavigate();
  let location = useLocation();
  const {name} = useParams();
  
  let paramProduct; 

  const [product, setProduct] = useState({});
  const [imageList, setImageList] = useState([]);
  const [randomProducts, setRandomProducts] = useState([]);
  const [variations, setVariations] = useState([])
  const [variation, setVariation] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [warning, setWarning] = useState(false);
  const [favorite, setFavorite] = useState(false);  
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [stars, setStars] = useState(1);
  const [reviewText, setReviewText] = useState("");
  const [purchased, setPurchased] = useState(false);  
  
  //Information Modal controls
  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);

  //Translation Hook  
  let common = useTranslate(feathersStore.language, commonEL, commonEN);  
  
  useEffect(() => {
    if(localStorage.getItem('fire_cart')){
      cart = JSON.parse(localStorage.getItem('fire_cart'));
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;
    }
  }, [paramProduct]);

  useEffect(() => {
    feathersStore.isAuthenticated && initProduct();  
  }, [ feathersStore.isAuthenticated, location?.state?.payload, 
    feathersStore?.favoritesArray, feathersStore.productUpdatedEvent, name]);

  useEffect(() => {    //--Real time updates
    paramProduct && realTimeUpdates();
  }, [feathersStore.productUpdatedEvent]);
  
  useEffect(() => {
    product && feathersStore.user?.firstname !== "default" && checkIfPurchased();
  }, [product, feathersStore.user]);  

  const initProduct = async() => {
    if(location?.state?.payload) {
      paramProduct = location.state.payload;
    }else{  //USAGE: for reloads
      const products = await feathersStore.products(name);
      if(products)paramProduct = products[0];   
    }
    setupProduct();
  }

  const setupProduct = () => {
    if(paramProduct?.hasVariation){       
      setVariations(paramProduct?.variations)
      setVariation(paramProduct?.variations[0])
    } 
    checkFavorite(); 
    setImageList(getImageArr(paramProduct));
    setProduct(paramProduct);
  }

  const realTimeUpdates = async() => {
    if( feathersStore.productUpdatedEvent._id === paramProduct._id){
      paramProduct = await feathersStore.getProduct(paramProduct._id);
      setupProduct();
    }
  }

  const getImageArr = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images
        .map(image => Object.assign(image, {variation: variation.name}))) //Add variation name to the image
        .flat();
    }else{
      imageArr = product.images              
    }  
    return imageArr;
  }

  const checkFavorite = () => {
    if(feathersStore.favoritesArray.includes(paramProduct._id)){
      setFavorite(true);
    }
  }

  const checkIfPurchased = async() => {
    const orders = await feathersStore.ordersPerUser();
    const check = orders?.map(order => order.items).flat().find(prod => prod.product_id === product._id);
    setPurchased(check);
  }

  const onPressAddToFavorites = async () => {
    let id = '';
    id = product._id;
    if(feathersStore.favoritesArray.includes(id)){
      const index = feathersStore.favoritesArray.indexOf(id);
      if (index > -1) {
        feathersStore.favoritesArray.splice(index, 1);
      }
      setFavorite(false);
    }else{
      feathersStore.favoritesArray.push(id);
      setFavorite(true);
    }   
    if(feathersStore.wishlistId === ""){
        const data = await feathersStore.createWishlist();
        data && (feathersStore.wishlistId = data._id); 
    }else await feathersStore.updateWishlist();
    setFavoritesUpdated(true);
  };
   
  useEffect(()=>{
    feathersStore.isAuthenticated && fetchRandomProducts();
  },[product, feathersStore.isAuthenticated, feathersStore.productUpdatedEvent, feathersStore.productCreatedEvent]);

  const fetchRandomProducts = async() => {
    const feathersProducts = await feathersStore.randomProducts(product?.category1?.id);
    setRandomProducts([...feathersProducts]);
  }

  const getMainImage = (product)=> {
    let list = getImageArr(product);
    const mainImg = list.find(img => img.focused);
    return mainImg;
  }

  const handlePress = (product)=> {
    navigate(`/product/${(feathersStore.language === 'en') && product?.nameEnglish ?
        product.nameEnglish : product.name}`,{
      state: {id: `${product._id}`,  payload: product}     
    }); 
  }
 
  const getPrice = (product)=> {
    if(product.hasVariation){
      let priceArr = [];
      priceArr = product.variations
        .map(variation => +variation.retailPrice)
        const low = Math.min(...priceArr)
        const high = Math.max(...priceArr)
        if(low === high){
            return high.toFixed(2)
        }else{
            return low.toFixed(2) + " - " + high.toFixed(2)
        }
    }else{
        return (+product.retailPrice).toFixed(2);
    }
  }

  const getBadges = product => {    
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.badges;
    }
    return product.badges;
  }

  const getOldPrice = product => {
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.oldPrice;
    }
    return product.oldPrice;
  }

  const renderRandomProducts = randomProducts.map((product, index) => {
    
    return (    
        <ProductCard
          key={product._id}
          itemId={index}
          stock={product.handleStock && product.stock}
          image={getMainImage(product)}
          title={(feathersStore.language === 'en') && product?.nameEnglish ? product.nameEnglish : product.name}          
          price={getPrice(product)}
          oldPrice={getOldPrice(product)}
          onClick={()=> handlePress(product)}
          onClickCart={() => handleAddToCart(product, true)}
          hasVariation={product.hasVariation}
          hasOtherFeatures={product.hasOtherFeatures}
          onClickForward={()=> handlePress(product)}
          badges={getBadges(product)}
        />    
    );
  });

  const style = {
    formControl: {
      margin: 1,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: 2,
    },
  }; 

  const renderImages = imageList.map((image, index)=>
    (<div key={image.name} style={{display: 'inline-grid'}}>
      <button style={{backgroundColor: image.focused && Colors.amazonColor}}  onClick={()=>selectImage(image)} aria-label='image focus'>
        <img aria-label='image item' alt="" src={`${API_URL}/images/${image?.name}`}/>
      </button>
    </div>)
  );

  const renderMainImage = imageList.map((image, index)=>(
    image.focused && <img aria-label='main image' className="hl-image" alt=""  key={image.name}
    src={`${API_URL}/images/${image?.name}`} />
  ))

  const selectImage = (image)=> {
    const index = imageList.indexOf(image)
    let newList = [...imageList]
    newList.forEach(item => item.focused = false)
    newList[index].focused = true
    setImageList([...newList])
    setVariation(variations.find(v => v.name === image.variation))
  }
 
  const handleChange = event => {
    let selectedVariation = event.target.value;
    setVariation(selectedVariation); 
    setQuantity(1)
    let newList = [...imageList]
    if(selectedVariation.image === null){
      setSelected(true)
    }else{
      newList.forEach(item => item.focused = false)
      const index = newList.findIndex(img => img.name === selectedVariation.images[0].name)
      console.log("index: ", index)
      newList[index].focused = true;
    } 
    setImageList([...newList]); 
  };

  const renderComments = product =>  product.commentsList
    .filter(rev => !rev.blocked)
    .map((review, index) =>
      <RatedCard 
        key={index}
        avatar={review.avatar}
        username={review.username}
        date={new Date(review.date).toLocaleDateString()}
        stars={review.stars} 
        comment={review.reviewText}
      />
  )

  const ratingStars =  product => {
    const starsCount = product.commentsList?.map(comm => comm.value)
      .reduce((prev, next)=> prev + next, 0);
    return starsCount / product.commentsList?.length;
  }
  
  const variationSelections = variations?.map((item, index) => (
      <MenuItem key={index} value={item}>
        {feathersStore.translate('en', item?.name, item?.nameEnglish)}
      </MenuItem>
  ))

  //-------FEATURES LOGIC ----------------------------------------//
 
  const [featuresSelected, setFeaturesSelected] = useState([])  
  const [error, setError] = useState(false)

  const renderFeatures = product => product.savedFeaturesTables?.map((table, index) => (
    <FormControl key={index} variant="outlined" sx={style.formControl} error={error}>
      <InputLabel aria-label='table title' id="demo-simple-select-outlined-label">
        {feathersStore.translate('en', table.title, table.titleEnglish)}       
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"  
        onChange={(e)=> handleFeatures(e, feathersStore.translate('en', table.title, table.titleEnglish))}
        label={feathersStore.translate('en', table.title, table.titleEnglish)}
      >
      {  table.savedFeaturesArray.map((i, index) => (
        <MenuItem disabled={i.active === false}
          key={index}
          value={feathersStore.translate('en', i.name, i.nameEnglish)}>
          {feathersStore.translate('en', i.name, i.nameEnglish)}            
        </MenuItem>
      ))}
      </Select>
      {!featuresSelected?.find(val => val.title === table.title)
        && <FormHelperText style={{width: 'auto', color: '#000'}}>Κάντε μια επιλογή</FormHelperText>}
      {error && <FormHelperText>Κάντε μια επιλογή</FormHelperText>}
    </FormControl>
  ))


  const handleFeatures = (e, title)=> {
      let name = e.target.value
      let item = {
          title,
          name
      }
      const featuresSelectedClone = [...featuresSelected];
      const index = featuresSelectedClone.findIndex(val => val.title === title);
      index !== -1 ? featuresSelectedClone.splice(index, 1, item) : featuresSelectedClone.push(item);   
      setFeaturesSelected([...featuresSelectedClone]);     
  } 

  const handleAddToCart = (prod, fromList) => { //fromList = prod is coming from the footer list and has no variations   
    let cartItem = {
      id: initialCartBiggestId,
      product_id: prod._id,
      title: prod?.name,
      titleEnglish: prod?.nameEnglish,
      productSKU: prod?.productSKU,
      image: fromList  ? prod.images?.find(img => img.focused) : imageList?.find(img => img.focused),
      price: prod.hasVariation ? variation?.retailPrice : prod?.retailPrice  ,
      quantity: fromList ? 1 : quantity,
      totalPrice: ((prod?.hasVariation ? +variation.retailPrice : +prod.retailPrice)*quantity).toFixed(2),
      variationTitle: feathersStore.translate('en', prod?.variationTitle, prod?.variationTitleEnglish),
      variation: prod.hasVariation ? variation?.name : "",
      stock: prod.hasVariation ? variation?.handleStock && variation?.stock : prod?.handleStock && prod?.stock,
      extra: [...featuresSelected],
      handleStock: prod.hasVariation ? variation?.handleStock : prod?.handleStock
    }

    const cartSimilarItems = cart.filter(item => item.product_id === cartItem.product_id );
    let cartSimilarItem;  
    if(prod.hasVariation)cartSimilarItem = cartSimilarItems.find(sim => sim.variation === cartItem.variation);
    else cartSimilarItem = cartSimilarItems[0];
    if(cartSimilarItem){  
      if(!prod.hasOtherFeatures){
        const newQ = +cartSimilarItem.quantity + +cartItem.quantity;
        if((prod.handleStock || variation.handleStock) && (newQ > cartItem.stock)){
          setWarning(true) ;
          return       
        }else{        
          const cartIndex = cart.findIndex(item => item.id === cartSimilarItem.id);
          cartItem.quantity = newQ;
          cartItem.totalPrice = (cartItem.price * newQ).toFixed(2);
          cart.splice(cartIndex, 1, cartItem);
        }
      }else{  //featured Product
        let existingTotalQuantity;
        if(prod.hasVariation){
          existingTotalQuantity = cartSimilarItems.filter(sim => sim.variation === cartItem.variation)
              .map(item => item.quantity)
              .reduce((a, b) => a + b);
        }else{
          existingTotalQuantity = cartSimilarItems
              .map(item => item.quantity)
              .reduce((a, b) => a + b);
        }
        const newQ = +existingTotalQuantity + +cartItem.quantity;
        if((prod.handleStock || variation.handleStock) && (newQ > cartItem.stock)){
          setWarning(true) ;
          return       
        }else{        
          cart.push(cartItem);
          feathersStore.setCartLength(feathersStore.cartLength + 1);
        }
      }          
    }else{   
      cart.push(cartItem);
      feathersStore.setCartLength(feathersStore.cartLength + 1);
    } 
    if(!warning){
      localStorage.setItem("fire_cart", JSON.stringify(cart));
      setCartIncreased(true);
    }
    //!fromList && !warning && resetProduct();
  }

  const setNewQuantity = (e)=> {
      let count = e.target.value;
      setQuantity(count)
  }
  
  const saveReview = async() => {
    const review = {
      date: new Date(),
      userId : feathersStore.user?._id,
      avatar: feathersStore.user?.avatar,
      username: feathersStore.user?.firstname + " " + feathersStore.user?.lastname,
      stars: stars,
      reviewText: reviewText,
      productImage: (product.hasVariation  ? product.images?.find(img => img.focused) : imageList?.find(img => img.focused)).name,
      blocked: false
    }  
    let newCommentsList = [...(product.commentsList || [])];
    newCommentsList[product.commentsList?.length || 0] = review;
    const newStarAverage = calculateStarAverage(newCommentsList); 
    await feathersStore.patchProduct(product._id, {commentsList: newCommentsList, starAverage: newStarAverage})
    setStars(1);
    setReviewText("");
  }

  const calculateStarAverage = (commentsArray) => {
    let cleanArray = commentsArray
        .filter(comm => !comm.blocked);
    let starsTotal = cleanArray.map(c => c.stars)
        .reduce((a,b) => a+b, 0);
    return starsTotal / cleanArray.length;
  }
   
  

  const [selected, setSelected] = useState(0)
  
  // ----> ScrollMenu controls

  const Left = () => {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
  
    return (      
      <LeftArrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}/>    
    );
  }
  
  const Right = () => {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  
    return (
      <RightArrow disabled={isLastItemVisible} onClick={() => scrollNext()}/>       
    );
  }
  
  
  return(
    <>
      <div className="product-mainBody">
        <div className="product-images-container">         
          <div className="product-main-image">
            {renderMainImage}
            {((product?.badges || variation?.badges)?.includes('new')) &&  (
              <div className="new-label-container-big">
                <span className="product-label">NEW</span>
              </div>)
            }
            {(((product?.badges || variation?.badges)?.includes('sale')) || product?.oldPrice || variation?.oldPrice) &&  (
              <div className="discount-label-container-big">
                <span className="product-label">SALE</span>
              </div>)
            }
            {((product?.badges || variation?.badges)?.includes('hot')) &&  (
              <div className="hot-label-container-big">
                <span className="product-label">HOT</span>
              </div>)
            }
          </div>
          <div className="product-collection-images">
            {renderImages}
          </div>         
        </div>
          
          <div className="product-info">
            <div className="product-title">
              <h3>{(feathersStore.language === 'en') && product?.nameEnglish ?
                product.nameEnglish : product.name}</h3>
            </div>
            <div className="product-subheader">
              {common.productSKUFull} {product.productSKU}
            </div>
            <div className="productview-price">
              {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
                <AddToWishListButton 
                  aria-labelledby='add to wishlist'
                  onClick={onPressAddToFavorites}
                  favorite={favorite}
                />}
            </div>
            <div className="productview-price">
              {(variation?.oldPrice || product?.oldPrice) &&
                <h4  className="product-old-price">
                  <span>{((product?.hasVariation ? +variation.oldPrice : +product.oldPrice)*quantity).toFixed(2)} € </span>
                </h4>
              }
              <h4>{((product?.hasVariation ? +variation.retailPrice : +product.retailPrice)*quantity).toFixed(2)} €</h4>             
            </div>
            <div className="html-text">
                {ReactHtmlParser(`<div>${(feathersStore.language === 'en') && product?.htmlContentEnglish ?
                  product.htmlContentEnglish : product.htmlContent}</div>`)} 
            </div>
           
              {product?.hasVariation &&  
                <div className="productVariation">
                  <FormControl variant="outlined" sx={style.formControl}>
                    <InputLabel aria-label='product title' id="native-select">
                      {feathersStore.translate('en', product?.variationTitle, product?.variationTitleEnglish)}
                    </InputLabel>
                    <Select 
                      labelId="native-select"
                      value={variation}
                      onChange={handleChange}
                      id='ns'
                      label={feathersStore.translate('en', product?.variationTitle, product?.variationTitleEnglish)}
                    >
                      {variations?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {feathersStore.translate('en', item?.name, item?.nameEnglish)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              }
            
            {
              product?.hasOtherFeatures && 
              <div className="productVariation" style={{marginBottom: 20}}>
                {product?.hasVariation ? renderFeatures(variation) : renderFeatures(product)}</div> 
            }

            {product?.handleStock && !product.hasVariation &&
              <div className="productStock">
                <div>{product?.stock !==0 ? <p>{product.stock} σε απόθεμα</p> : <p style={{color: 'red'}}>Εξαντλημένο</p>}</div>
              </div>
            }
              
            {product?.hasVariation && !variation && 
              <div className="productStock"><p style={{color: '#000'}}>Κάντε μια επιλογή</p></div>
            }

            {product?.hasVariation && variation?.handleStock && <div className="productStock">
              {variation.stock !== 0 ? <p>{variation.stock} σε απόθεμα</p> : <p style={{color: 'red'}}>Εξαντλημένο</p>}
            </div>}

          <SmallAlert 
            show={warning} 
            styling="warning"
            heading={common.warning} 
            onClose={() => setWarning(false)} 
            message={common.stockWarning} />

          <div className="productAddToCart">
            <input value={quantity} aria-label='quantity' onChange={setNewQuantity} type="number" min="1" className="quantity-input"
                max={product?.handleStock || variation?.handleStock ? product?.stock || variation?.stock : null} />
            <GreenSubmitButton 
              aria-label='add cart' 
              disabled={
                ((product?.handleStock || variation?.handleStock) && ((product?.stock || variation?.stock) - quantity < 0)) 
                || (product?.hasOtherFeatures && ((product?.hasVariation ? 
                  variation?.savedFeaturesTables?.length  : product?.savedFeaturesTables?.length) !== featuresSelected?.length))               
                || warning} 
              onClick={() => handleAddToCart(product, false)} 
              color={Colors.secondary}
              title={common.addCart}
              >                  
            </GreenSubmitButton>
          </div>
        </div>
      </div>

      <div className="product-evaluation-container">
        <div className="product-evaluation-title">
          <p>{common.reviews} ({product.commentsList?.filter(c => !c.blocked).length || 0}) / </p>
          {product.commentsList && 
            <GoldRating style={{marginLeft: 5}} name="read-only" 
            precision={0.1} value={product?.starAverage} readOnly />
          }
        </div>
        {feathersStore.isAuthenticated && 
          <div className="product-evaluation-body">
          { product.commentsList?.length === 0 && <NoneEvaluation/>}
          { feathersStore.user?.firstname === "default" || !purchased && <UnregisteredEmptyRating/> }
          { product.commentsList?.length === 0 && feathersStore.user?.firstname !== "default" && <RegisteredEmptyRating/>}
          { product.commentsList && renderComments(product)}
        </div>
        }
        {purchased && feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
         <>
          <div className="change-password">
            <div className="row-flex-start-no-wrap">
              <ListAltIcon style={{color: Colors.settingsText, fontSize: 30}}/>
              <h3 style={{color: Colors.settingsText}}>{common.leaveAComment}</h3>
            </div>
            {showCommentForm ?
              <ExpandLess style={{color: Colors.settingsText, fontSize: 35, marginRight: 10}} onClick={() => setShowCommentForm(false)} />
            :            
              <ExpandMore style={{color: Colors.settingsText, fontSize: 35, marginRight: 10}} onClick={() => setShowCommentForm(true)} />
            }
          </div>
          <CSSTransition
            in={showCommentForm}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            <div className="product-evaluation-body">
              <Avatar
                avatar={feathersStore.user?.avatar}
              />
              <p>{feathersStore.user?.firstname} {feathersStore.user?.lastname}</p>
              <GoldRating
                name="simple-controlled"
                value={stars}
                onChange={(event, newValue) => {
                  setStars(newValue);
                }}
              />
              <TextareaAutosize 
                style={{paddingLeft: 5, fontSize: 18}} 
                aria-label="leave a comment" 
                minRows={3}
                maxRows={4}
                placeholder={common.leaveAComment} 
                value={reviewText}
                onChange={(event) => {
                  setReviewText(event.target.value);
                }}
              />
              <GreenSubmitButton aria-label='save review' color={Colors.reviewBtn}
                onClick={saveReview} title={common.saveReview}></GreenSubmitButton>
            </div>
          </CSSTransition>
        </>}
      </div>
      {feathersStore.isAuthenticated  &&
      <div className="random-products-container">
        <h2 style={{textAlign: 'center'}}>{common.seeMoreProducts}</h2>                   
          <ScrollMenu            
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}              
          >
            {renderRandomProducts}
          </ScrollMenu>
      </div> }
      <SmallAlert 
        show={cartIncreased} 
        styling="success"
        heading={common.updated} 
        onClose={() => setCartIncreased(false)} 
        message={common.cartUpdated} />
      <SmallAlert 
        show={favoritesUpdated} 
        styling="success"
        heading={common.updated}
        onClose={() => setFavoritesUpdated(false)} 
        message={common.wishlistUpdated} />
   </>
  )
}

export default inject('feathersStore')(observer(Product));