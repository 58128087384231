import React, { useEffect } from "react";
import NavigateNext from '@mui/icons-material/NavigateNext'
import NavigateBefore from '@mui/icons-material/NavigateBefore';

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <button
      disabled={disabled} 
      onClick={() => scrollPrev()}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        border: "none",
        backgroundColor: "transparent"   
      }}        
      >
      <NavigateBefore style={{fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>
    </button>
  );
}

export const RightArrow = () => {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <button
      disabled={disabled} 
      onClick={() => scrollNext()}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        border: "none",
        backgroundColor: "transparent"     
      }}        
    >
      <NavigateNext style={{fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>
    </button>
  );
}
