import React, { useEffect } from 'react'
import Colors from "../assets/colors";
import { inject, observer } from "mobx-react";
import useTranslate from '../../hooks/useTranslate';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {checkoutEL} from "../../locales/el/checkout";
import {checkoutEN} from "../../locales/en/checkout";
import GreenSubmitButton from './greenSubmitButton';

const RegisteredCustomer = ({onClickSignIn, feathersStore})=> { 

  let checkout = useTranslate(feathersStore.language, checkoutEL, checkoutEN); 

  return(    
    <GreenSubmitButton
      aria-labelledby='Register'
      className="btn btn-secondary dropdown-toggle"  
      aria-haspopup="true" 
      aria-expanded="false"
      onClick={onClickSignIn}
      color={Colors.reviewBtn}
      title={`${checkout.alreadyCustomer} >`}
      >    
    </GreenSubmitButton> 
  )
}

export default inject('feathersStore')(observer(RegisteredCustomer));