//import carousel2 from '../img/carousel_01-min.jpg'
//import carousel3 from '../img/carousel_03-min.jpg'
//import carousel4 from '../img/carousel_04-min.jpg'

import carousel2 from '../img/carousel_1.jpg'
import carousel3 from '../img/carousel_2.jpg'
import carousel4 from '../img/carousel_3.jpg'

export const CarouselData = [
  {
    image: carousel2,
    text1: 'Καλώς ήρθατε στo νέo μας ηλεκτρονικό κατάστημα! Εδώ μπορείτε να βρείτε διάφορα ξύλινα χειροποίητα προϊόντα',
    text1EN: 'Welcome to our new e-shop. Here you can find many wooden handmade artifacts'
  },
  {
    image: carousel3,
    text1: 'Αγοράστε είδη Βάπτισης και Διακοσμητικά',
    text1EN: 'Buy Accessories and Christiening items'
  },
  {
    image: carousel4,
    text1: 'Εδώ μπορείτε να αγοράσετε μπρελόκ και να εκτυπώσετε το δικό σας σχέδιο σε κούπες είτε μαξιλάρια',
    text1EN: 'Here you can buy key holders and print your drawing in mugs and pillows'
  },
]