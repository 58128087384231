import React from 'react';
import CartTable from './cartTable';
import PageTitle from '../../components/screenComponents/pageTitle';
import { inject, observer } from "mobx-react";
import "./Cart.css";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {cartEL} from "../../locales/el/cart";
import {cartEN} from "../../locales/en/cart";

const Cart = ({feathersStore})=> {
  
  let cart = useTranslate(feathersStore.language, cartEL, cartEN); 

  return(
    
      <div className="cart-body">
        <PageTitle title={cart.cart}/>
        {feathersStore.cartLength !== 0 ? <CartTable/> : <div className="empty-cart"/>}
      </div>
   
  )
}

export default inject('feathersStore')(observer(Cart));