import React, {useState, useEffect, useRef} from 'react';
import Colors from '../../components/assets/colors';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TermsNConditions from '../../components/screenComponents/terms/termsnconditions';
import About from '../../components/screenComponents/about';
import LogoutComponent from './logoutComponent';
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { DEFAULT_EMAIL, DEFAULT_PSW } from "../../components/assets/globalConstants";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const Settings = ({ feathersStore })=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN); 

  const [termsVisible, setTermsVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMountedRef = useRef(null);

  let navigate = useNavigate();
  
  useEffect(() => {
    isMountedRef.current = true; 
   // setAddresses()   
    return () => isMountedRef.current = false;
  }, [])

  const toggleTerms = () => {
    setTermsVisible(!termsVisible);
  }

  const toggleAbout = () => {
    setAboutVisible(!aboutVisible);
  }

  const toggleLogout = () => {
    setLogoutVisible(!logoutVisible);
  }

  const logout = async()=> {
    isMountedRef.current && setLoading(true);
    try{
        await feathersStore.logout();      
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
            navigate('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  }   

  return(

    <div className="settings-container">
      <div className="change-password-container" style={{margin: 0}}>
        <div className="changePassTitle">
          <ListAltIcon style={{color: Colors.settingsText, fontSize: 30}}/>
          <h4 style={{color: Colors.settingsText}}>{common.termsOfServiceNoun}</h4>
        </div>
        {termsVisible ? 
        <button onClick={() => toggleTerms()} aria-labelledby='cancel presentation'>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => toggleTerms()} aria-labelledby='expand more'>
          <ExpandMore style={{color: 'red', fontSize: 35}}/>
        </button>   
        }
      </div>
      <CSSTransition
        in={termsVisible}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <TermsNConditions/>
      </CSSTransition>      
      <div className="change-password-container" style={{margin: 0}}>
        <div className="changePassTitle">
          <InfoIcon style={{color: Colors.settingsText, fontSize: 30}}/>
          <h4 style={{color: Colors.settingsText}}>{common.aboutUs}</h4>
        </div>
        {aboutVisible ? 
        <button onClick={() => toggleAbout()} aria-labelledby='cancel presentation'>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => toggleAbout()} aria-labelledby='expand more'>
          <ExpandMore style={{color: 'red', fontSize: 35}}/>
        </button>   
        }
      </div>
      <CSSTransition
        in={aboutVisible}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <About/>
      </CSSTransition>
      <div className="change-password-container" style={{margin: 0}}>
        <div className="changePassTitle">
          <ExitToAppIcon style={{color: Colors.settingsText, fontSize: 30}}/>
          <h4 style={{color: Colors.settingsText}}>{common.logout}</h4>
        </div>
        {logoutVisible ? 
        <button onClick={() => toggleLogout()} aria-labelledby='cancel presentation'>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => toggleLogout()}>
          <ExpandMore style={{color: 'red', fontSize: 35}} aria-labelledby='expand more'/>
        </button>   
        }
      </div>
      <CSSTransition
        in={logoutVisible}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <LogoutComponent            
          onClickNo={toggleLogout}
          deleteItem={logout}
          loading={loading}
        />
      </CSSTransition>
    </div>  
  )
}

export default inject('feathersStore')(observer(Settings));