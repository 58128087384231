import React,{useState, useEffect, useRef, Suspense, lazy} from 'react'
import PageTitle from '../../components/screenComponents/pageTitle'
import CheckoutComponent from '../../components/cards/checkoutComponent'
import {SlideDown} from 'react-slidedown';
import Registration from '../../components/reusable/registration/Registration'
import LoadingOrderModal from '../../components/modals/loadingOrderModal';
import OrderCompleteModal from '../../components/modals/orderCompleteModal';
import 'react-slidedown/lib/slidedown.css';
import { inject, observer } from "mobx-react";
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Colors from '../../components/assets/colors';
import { PayPalButton } from "react-paypal-button-v2";
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import "./Checkout.css";
import { PHONE_REGEX, PRODUCTION_CLIENT_ID } from "../../components/assets/globalConstants";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";
import {checkoutEL} from "../../locales/el/checkout";
import {checkoutEN} from "../../locales/en/checkout";
import { CheckBoxOutlineBlank } from '@mui/icons-material';

const NbgFrame = lazy(() => import("./NbgFrame"));

const Checkout = ({ feathersStore })=> {

  let navigate = useNavigate();  
 
  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  let checkout = useTranslate(feathersStore.language, checkoutEL, checkoutEN);
  
  const formRef = useRef();

  const [origins, setOrigins] = useState([]);
  const [cartSub, setCartSub] = useState(0);  
  const [isLoading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);  
  const [transportation, setTransportation] =useState({}); //Object containing fields regarding transportation settings 
  const _isMounted = useRef(true); //used in line 405 to check if component is mounted
  
  const cartItems = JSON.parse(localStorage.getItem('fire_cart'));

  useEffect(() => {
   
    return () => { // ComponentWillUnmount in Class Component
        _isMounted.current = false;
    }
  }, []);

  useEffect(()=> {
    setOrigins(feathersStore.settings?.origins);    
  },[feathersStore.isAuthenticated && feathersStore.settings]);

  useEffect(()=> {
    const _subTotal =  cartItems.map(item=> item.totalPrice)
      .reduce((prev, curr)=> +prev + +curr, 0).toFixed(2);
    setCartSub(_subTotal)
  },[cartItems])

  useEffect(()=> {
    setTransportation(feathersStore.settings?.transportation);    
  },[feathersStore.settings?.transportation]);

  useEffect(()=> {
    if(feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default'
      && formRef.current){
        formRef.current.setFieldValue('firstName', feathersStore.user?.firstname);
        formRef.current.setFieldValue('lastName', feathersStore.user?.lastname);
        feathersStore.user?.addresses && formRef.current.setFieldValue('street',feathersStore.user?.addresses.find(addr => addr.active)?.street);
        feathersStore.user?.addresses && formRef.current.setFieldValue('streetNumber',feathersStore.user?.addresses.find(addr => addr.active)?.streetNumber);
        feathersStore.user?.addresses && formRef.current.setFieldValue('city',feathersStore.user?.addresses.find(addr => addr.active)?.city);
        feathersStore.user?.addresses && formRef.current.setFieldValue('origin',feathersStore.user?.addresses.find(addr => addr.active)?.origin || 1);
        feathersStore.user?.addresses && formRef.current.setFieldValue('postcode',feathersStore.user?.addresses.find(addr => addr.active)?.postcode);
        formRef.current.setFieldValue('phone',feathersStore.user?.phone);
        formRef.current.setFieldValue('email',feathersStore.user?.email);      
      }else{
        formRef.current.resetForm();
        formRef.current.validateForm();
      }   
  },[feathersStore.user]);
 
  const setTitle = (item)=> {
    if(item.variation === null){
        return feathersStore.translate('en', item.title, item.titleEnglish);
    }else{
        return feathersStore.translate('en',item.title , item.titleEnglish)+ " - " + item.variation
        + item.extra?.map(feature => " - " + feature.name);
    }
  }  

  const BanksIterator = () => {
    return(
      <table className="banks-container">
        <thead>
          <tr>
            <th>{checkout.bank}</th>
            <th>IBAN</th>
          </tr>       
        </thead>
        <tbody>       
          {feathersStore.settings?.bankAccounts
            ?.filter(item => item.bankEnabled)
            ?.map((bank, index) => 
              <tr key={bank.bankIBAN}>
                <td>
                  {bank.bankName}
                </td> 
                <td>
                  {bank.bankIBAN}
                </td>
              </tr> 
          )}  
        </tbody>     
      </table>
    )}
  

  const createOrder = (data, actions) => {
    let values;
    if (formRef.current) {
      values = formRef.current.values;
    }
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency: "EUR",
              value: values.grandTotal,
            },
          },
        ],
        application_context: {
           shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
         }
      })
      .then((orderID) => {       
        feathersStore.setOrderItem({paypalOrderID: orderID});     
        return orderID;
      });
  }

  const approved = (data, actions) =>  {
    let values;
    if (formRef.current) {
      values = formRef.current.values;
    }
    // This function captures the funds from the transaction.
    return actions.order.capture().then(function(details)  {
      // This function shows a transaction success message to your buyer.
      sendOrder(values);
      alert('Transaction completed by ' + details.payer.name.given_name);
     });
   }
  
  const onInit = (data, actions) =>  {
    let formikRef;
    if (formRef.current) {
      formikRef = formRef.current;
    }

    // Disable the buttons
    actions.disable();
    if (formikRef.isValid)  {
      actions.enable();
      } else  {
      actions.disable();
    }
  }

  const onClick = () => {
    let formikRef;
    if (formRef.current) {
      formikRef = formRef.current;
    }

    if (!formikRef.isValid) {    
      alert("Please fill all fields");
    }
  }

  const sendOrder = values => {   
    const day = new Date().getDate(); //Current Date
    const month = new Date().getMonth() + 1; //Current Month
    const year = new Date().getFullYear(); //Current Year
    const hours = new Date().getHours(); //Current Hours
    const min = new Date().getMinutes(); //Current Minutes
    const initDate = day + '/' + month + '/' + year + ' ' + hours + ':' + min;
    feathersStore.setOrderItem({
        'customerId': feathersStore.user._id || "",
        'bookingDate': initDate,
        'status': 'Pending',
        'items': [...cartItems],
        'total': values.grandTotal,
        'transportationCost': values.transportationCost,
        'handoverCost': values.handoverCost,
        'processed': false,     
        'latitude': 0,
        'longitude': 0,
        'firstName': values.firstName,
        'lastName': values.lastName,
        'street': values.street,
        'streetNumber': values.streetNumber,
        'city': values.city, 
        'origin': values.origin, 
        'apartment' : feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.phone : "", 
        'postcode': values.postcode,        
        'phone': values.phone,
        'email': values.email,
        'isCompany': values.isCompany,
        'companyAfm': values.isCompany ? values.companyAfm : "",
        'companyName': values.isCompany ? values.companyName : "",
        'companyDoy': values.isCompany ? values.companyDoy : "",
        'companyProfession': values.isCompany ? values.companyProfession : "",
        'hasOtherAddress': values.hasOtherAddress,
        'deliveryFirstName': values.hasOtherAddress ? values.deliveryFirstName : "",
        'deliveryLastName': values.hasOtherAddress ? values.deliveryLastName : "",
        'deliveryStreet': values.hasOtherAddress ? values.deliveryStreet : "",
        'deliveryCity': values.hasOtherAddress ? values.deliveryCity : "", 
        'deliveryOrigin': values.hasOtherAddress ? values.deliveryOrigin : "", 
        'deliveryPostcode': values.hasOtherAddress ? values.deliveryPostcode : "", 
        'deliveryPhone': values.hasOtherAddress ? values.deliveryPhone : "",
        'comments':values.comments || "",
        'paymentMethod':values.paymentMethod 
    });
    setLoading(true);
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(255, checkout.less255)
      .required(checkout.required),
    lastName: Yup.string()
      .max(255, checkout.less255)
      .required(checkout.required),
    street: Yup.string()
      .max(50, checkout.less50)
      .required(checkout.required),
    streetNumber: Yup.string()
      .max(10, checkout.less10)
      .required(checkout.required),
    city: Yup.string()
      .max(50, checkout.less50)
      .required(checkout.required),
    grandTotal: Yup.number(),
    transportationCost: Yup.number(),
    handoverCost: Yup.number(),
    origin: Yup.number()
      .required(checkout.required),
    postcode: Yup.string()
      .max(10, checkout.less10)
      .required(checkout.required),  
    email: Yup.string()
      .email(checkout.invalidEmail)
      .required(checkout.required), 
    phone: Yup.string()
      .matches(PHONE_REGEX, checkout.invalidPhone)
      .required(checkout.required),
    hasOtherAddress:  Yup.boolean()
      .required(checkout.required),
    isCompany:  Yup.boolean()
      .required(checkout.required),
    comments: Yup.string()
      .max(500, checkout.textareaMax),
    paymentMethod:  Yup.string() 
      .required(checkout.required)
      .oneOf(["Cash", "ClickAway", "BankTransfer", "NBG", "PayPal"], checkout.invalidPayment),
     acceptedTerms: Yup.boolean()
      .required(checkout.required)
      .oneOf([true], checkout.terms),
    
      //---- Company sub - form validation
    companyAfm:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(20, checkout.less20)
        .required(checkout.required)
    }),
    companyDoy:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),
    companyName:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),
    companyProfession:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),

      //----- Other - Address - Sub - form validation ----------
    deliveryFirstName:   Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }), 
    deliveryLastName:   Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),          
    deliveryStreet:Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),
    deliveryCity: Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),        
    deliveryOrigin: Yup.number().when('hasOtherAddress', {
      is: true,
      then:  Yup.number()         
        .required(checkout.required)
    }),
    deliveryPostcode: Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(10, checkout.less10)
        .required(checkout.required)
    }),
    deliveryPhone:Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .matches(PHONE_REGEX, checkout.invalidPhone)        
        .required(checkout.required)
    })         
  })

  const grandTotalComponent = ({field, form}) => {
    return(
    <span>{field.value}€</span>
    )
  }

  //----------------- LoadingOrderModal controls -----------------

  const whenError = () => {
    setLoading(!isLoading); 
  }

  const CompleteOrder = ()=> {
    setLoading(!isLoading);
    setComplete(!isComplete);     
  }

  const setOrder = ()=> {
    setComplete(!isComplete);      
    navigate("/") ;     
  }

  const renderCartItems = cartItems.map( (item, index) => (
    <CheckoutComponent
      key={index}
      sku={item.productSKU}
      title={setTitle(item)}
      quantity={item.quantity}
      total={item.totalPrice}
    />
  ))
  
  return(   
    <>    
      <LoadingOrderModal
        visible={isLoading}
        whenSent={CompleteOrder}
        whenError={whenError}
      />
      <OrderCompleteModal
        visible={isComplete}
        onClickFinish={setOrder}        
      /> 
      <div className="header-section">      
        <PageTitle  title={common.checkout}/>          
        {_isMounted.current && feathersStore.user?.firstname === "default" &&  
          <div className="registered-customer"><Registration/></div>}
      </div>

      <>
      <Formik 
        innerRef={formRef}  
        initialValues={{
          firstName: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.firstname : "",
          lastName: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.lastname : "",
          street: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.street : "",
          streetNumber: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.streetNumber : "",
          city: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.city : "", 
          origin: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.origin : 1, 
          postcode: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.postcode : "",       
          phone: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.phone : "",
          email: feathersStore.isAuthenticated && feathersStore.user?.lastname !== 'default' ? feathersStore.user?.email : "",
          isCompany: false,
          companyAfm: "",
          companyDoy: "",
          companyName: "",
          companyProfession: "",
          hasOtherAddress: false,
          deliveryFirstName: '',
          deliveryLastName: '',
          deliveryStreet: '',
          deliveryCity: '', 
          deliveryOrigin: 1, 
          deliveryPostcode: '',       
          deliveryPhone: '',
          comments:'',
          paymentMethod:'Cash',
          acceptedTerms: false,
          grandTotal:0,
          transportationCost: 0,
          handoverCost: 0      
        }}
      
      validationSchema= {validationSchema}
      
      onSubmit={(values, { setSubmitting }) => {  
        sendOrder(values);
        setSubmitting(false);
        ;
      }}
    >
    {formik => (
    <Form>
      <div className="column-flex-center main-container">
        <div className="row-flex-start-start-wrap form-container" >
          <div className="column-flex-start left-container">           
            <div className=" row-container" >
              <div className="two-quarters-container">                
                <label htmlFor="firstName" style={{fontWeight: 'bold'}}>{common.firstName}</label><span style={{color: 'red'}}>*</span>                
                <Field name="firstName" type="text" className="form-control" />
                <ErrorMessage name="firstName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
              <div className="two-quarters-container-right">                
                <label htmlFor="lastName" style={{fontWeight: 'bold'}}>{common.lastName}</label><span style={{color: 'red'}}>*</span>                
                <Field name="lastName" type="text" className="form-control" />
                <ErrorMessage name="lastName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>                
            </div>
            <div className="row-container">
              <div className="three-quarters-container">               
                <label style={{fontWeight: 'bold'}} htmlFor="street">{common.street}</label><span style={{color: 'red'}}>*</span>               
                <Field name="street" type="text" className="form-control" />
                <ErrorMessage name="street" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
              <div className="one-quarter-container-right">                
                <label style={{fontWeight: 'bold'}} htmlFor="streetNumber">{common.streetNumber}</label><span style={{color: 'red'}}>*</span>               
                <Field name="streetNumber" type="text" className="form-control" />
                <ErrorMessage name="streetNumber" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
            </div>
            <div className="row-container" >
              <div className="two-quarters-container">              
                <label style={{fontWeight: 'bold'}} htmlFor="city">{common.city}</label><span style={{color: 'red'}}>*</span>              
                <Field name="city" type="text" className="form-control" />
                <ErrorMessage name="city" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
            </div>
            <div className="row-container" >
              <div className="three-quarters-container">                
                <label htmlFor="origin" style={{fontWeight: 'bold'}}>{common.origin}</label><span style={{color: 'red'}}>*</span>                
                <Field name="origin" as="select" className="form-control">
                  {origins?.map((i, index)=> (              
                    <option key={index} className="option-item" value={i.id}>{i.label}</option>
                  ))}             
                </Field>
                <ErrorMessage name="origin" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
              <div className="one-quarter-container-right">                
                <label htmlFor="postcode" style={{fontWeight: 'bold'}}>{common.postcode}</label><span style={{color: 'red'}}>*</span>                
                <Field name="postcode" type="text" className="form-control" />
                <ErrorMessage name="postcode" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
            </div>
              <div className="row-container">   
                <div className="two-quarters-container">              
                  <label htmlFor="phone" style={{fontWeight: 'bold'}}>{common.phone}</label><span style={{color: 'red'}}>*</span>               
                  <Field name="phone" type="text" className="form-control" />
                  <ErrorMessage name="phone" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                </div>
              </div>
              <div className="row-container">  
                <div className="two-quarters-container">                
                  <label style={{fontWeight: 'bold'}} htmlFor="email">{common.email}</label><span style={{color: 'red'}}>*</span>              
                  <Field name="email" type="email" className="form-control" />
                  <ErrorMessage name="email" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>      
                  <small id="emailHelp" className="form-text text-muted">{common.emailMessage}</small>
                </div>
              </div>             
              <div className="row-container">
                <Field type="checkbox"   name="isCompany" 
                  className="form-check-input"   style={{width: 20, height: 20}}/>
                <label className="form-check-label" htmlFor="isCompany" >
                  {checkout.isCompany}
                </label>
              </div>
              <SlideDown className='my-dropdown-slidedown'>
                {formik.values.isCompany && 
                <div style={{marginTop: 20}}>
                  <div className="row-container">
                    <div className="two-quarters-container">                      
                      <label htmlFor="companyAfm" style={{fontWeight: 'bold'}}>{checkout.companyAfm}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="companyAfm" />
                      <ErrorMessage name="companyAfm" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="two-quarters-container-right">                     
                      <label htmlFor="companyName" style={{fontWeight: 'bold'}}>{checkout.companyName}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="companyName" type="text" className="form-control" />
                      <ErrorMessage name="companyName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div>
                  <div className="row-container">
                    <div className="two-quarters-container">                      
                      <label htmlFor="companyDoy" style={{fontWeight: 'bold'}}>{checkout.companyDoy}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="companyDoy" />
                      <ErrorMessage name="companyDoy" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="two-quarters-container-right">                     
                      <label htmlFor="companyProfession" style={{fontWeight: 'bold'}}>{checkout.companyProfession}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="companyProfession" type="text" className="form-control" />
                      <ErrorMessage name="companyProfession" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div>                   
                </div>}
              </SlideDown>  
            </div>  
            <div className="column-flex-start right-container">
              <div className="row-container">
                <Field type="checkbox"   name="hasOtherAddress" 
                  className="form-check-input"   style={{width: 20, height: 20}}/>
                <label className="form-check-label" htmlFor="hasOtherAddress" >
                  {checkout.differentDeliveryAddress}
                </label>
              </div>
              <SlideDown className='my-dropdown-slidedown'>
                {formik.values.hasOtherAddress && 
                <div style={{marginTop: 20}}>
                  <div className="row-container">
                    <div className="two-quarters-container">                      
                      <label htmlFor="deliveryFirstName" style={{fontWeight: 'bold'}}>{common.firstName}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="deliveryFirstName" />
                      <ErrorMessage name="deliveryFirstName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="two-quarters-container-right">                     
                      <label htmlFor="deliveryLastName" style={{fontWeight: 'bold'}}>{common.lastName}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="deliveryLastName" type="text" className="form-control" />
                      <ErrorMessage name="deliveryLastName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div>
                  <div>
                    <div className="row-container">
                      <label style={{fontWeight: 'bold'}} htmlFor="deliveryStreet">{common.streetAndNumber}</label><span style={{color: 'red'}}>*</span>
                    </div>
                    <Field name="deliveryStreet" type="text" className="form-control" />
                    <ErrorMessage name="deliveryStreet" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                  <div>
                    <div className="row-container">
                      <label style={{fontWeight: 'bold'}} htmlFor="deliveryCity">{common.city}</label><span style={{color: 'red'}}>*</span>
                    </div>
                    <Field name="deliveryCity" type="text" className="form-control" />
                    <ErrorMessage name="deliveryCity" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                  <div className="row-container">
                    <div className="three-quarters-container">                      
                      <label htmlFor="deliveryOrigin" style={{fontWeight: 'bold'}}>{common.origin}</label><span style={{color: 'red'}}>*</span>                      
                      <Field name="deliveryOrigin" as="select" className="form-control" >
                        {origins?.map((i, index)=> (              
                          <option key={index} className="option-item" value={i.id}>{i.label}</option>
                        ))}             
                      </Field>
                      <ErrorMessage name="deliveryOrigin" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>
                    <div className="one-quarter-container-right">                      
                      <label htmlFor="deliveryPostcode" style={{fontWeight: 'bold'}}>{common.postcode}</label><span style={{color: 'red'}}>*</span>                     
                      <Field name="deliveryPostcode" type="text" className="form-control" />
                      <ErrorMessage name="deliveryPostcode" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>
                  </div>
                  <div>
                    <div className="row-container">
                      <label htmlFor="deliveryPhone" style={{fontWeight: 'bold'}}>{common.phone}</label><span style={{color: 'red'}}>*</span>
                    </div>
                    <Field name="deliveryPhone" type="text" className="form-control" />
                    <ErrorMessage name="deliveryPhone" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                </div>
                }
              </SlideDown>
              <div style={{display: 'flex', flexFlow: 'column', alignItems: 'baseline', justifyContent: 'left', marginTop: 20, width: '100%'}}>
                <label htmlFor="comments">
                  {checkout.orderComments}
                </label>
                <Field as="textarea" className="form-control" name="comments" rows="3"/>
              </div>      
            </div>
          </div>
        </div>   
        <div className="payment-container column-flex-start-wrap">
          <div className="checkout-order-table-title">
            <p>{checkout.payment}</p>
          </div>          
          <div className="checkout-order-table">
            <div className="checkout-order-table-paymentType">
              <Field type="radio" name="paymentMethod" value="Cash" />
              <span style={{fontWeight: 'bold', marginLeft: 5}}>{checkout.cash}</span>
              <span style={{marginLeft: 15}}>({checkout.cashText}).</span>
            </div>
            <div className="checkout-order-table-paymentType">
              <Field type="radio" name="paymentMethod" value="ClickAway" />
              <span style={{fontWeight: 'bold', marginLeft: 5}}>{checkout.clickAway}</span>
              <span style={{marginLeft: 15}}>({checkout.clickAwayText}).</span>
            </div>
            <div className="checkout-order-table-paymentType">
              <Field type="radio" name="paymentMethod" value="BankTransfer" />
              <span style={{fontWeight: 'bold', marginLeft: 5}}>{checkout.bankTransfer}</span>
              <span style={{marginLeft: 15}}>({checkout.bankTransferText}).</span>
            </div>
            {formik.values.paymentMethod === "BankTransfer" && 
              <div className="banks-container column-flex-center">
                <div className="bank-table-container">
                  <BanksIterator/>
                </div>
                <p>
                  {checkout.bankLargeText}
                </p>
              </div>
            }
             <div className="checkout-order-table-paymentType">
              <Field type="radio" name="paymentMethod" value="NBG"/>
              <span style={{fontWeight: 'bold', marginLeft: 5}}>{checkout.nbgRadio}</span>              
            </div>
            {formik.values.paymentMethod === "NBG" && 
              <div className="banks-container column-flex-center">
                <Suspense fallback={<div>Loading...</div>}>
                  <NbgFrame />
                </Suspense>               
              </div>
            }
           
            <div className="checkout-order-table-paymentType">
              <Field type="radio" name="paymentMethod"  value="PayPal"/>
              <span style={{fontWeight: 'bold', marginLeft: 5}}>{checkout.paypalRadio}</span>
              <span 
                hidden={formik.values.paymentMethod !== "PayPal" || formik.isValid} 
                className="paypal-error">&nbsp;&nbsp;&nbsp;&nbsp;{checkout.paypalError}</span>
            </div>
            <div className="checkout-order-table-terms">
              <p>
              {checkout.personalDataInfo}
              </p>
              <p>
              {checkout.readThe} <a href="#">{common.privacyStatement}</a>.
              </p>
            </div>
            <div className="checkout-order-table-acceptTerms">
              <span>
              <Field type="checkbox" name="acceptedTerms" />
              <span style={{fontWeight: 'bold', marginLeft: 5}}>
              {checkout.iHaveRead} <a href="#">{common.termsOfService}</a> 
                &nbsp;{checkout.ofThisSite}<span style={{color: 'red'}}>*</span></span></span>
            </div>
          </div>         
        </div>
      
        <div className="payment-container">
          <div className="checkout-order-table-title">
            <p>{checkout.yourOrder}</p>
          </div>        
            <div className="checkout-order-table">
              <div className="checkout-order-table-header">
                <span style={{fontWeight: 'bold'}}>{common.product}</span>
                <span style={{fontWeight: 'bold'}}>{common.subTotal}</span>
              </div>
              <div className="checkout-order-table-body">
                {renderCartItems}
              </div>
              <div className="checkout-order-table-subtotal">
                <span style={{fontWeight: 'bold'}}>{common.subTotal}</span>
                <span>{cartSub}€</span>
              </div>
              <div className="checkout-order-table-subtotal">
                <span style={{fontWeight: 'bold'}}>{common.transportation}</span>
                <Field name="transportationCost" 
                  type="number" 
                  value={formik.values.transportationCost = (cartSub >= +transportation?.free || formik.values.paymentMethod === 'ClickAway' ?
                    0 : 
                    +formik.values.origin === 1 ? +transportation?.downtown : +transportation?.countryside).toFixed(2)} 
                  component={grandTotalComponent}>             
                </Field>               
              </div>
              <div className="checkout-order-table-subtotal">
                <span style={{fontWeight: 'bold'}}>{checkout.cash}</span>
                <Field name="handoverCost" 
                  type="number" 
                  value={formik.values.handoverCost = (formik.values.paymentMethod === 'Cash' ? 
                    +transportation?.handover : 0).toFixed(2)} 
                  component={grandTotalComponent}>             
                </Field>             
              </div>
              <div className="checkout-order-table-subtotal">
                <span style={{fontWeight: 'bold'}}>{common.total}</span>
                <Field name="grandTotal" 
                  type="number" 
                  value={formik.values.grandTotal = (+cartSub + (formik.values.paymentMethod === 'Cash' ? 
                    +transportation?.handover : 0)
                    + (+cartSub >= +transportation?.free || formik.values.paymentMethod === 'ClickAway' ? 0 : 
                    (+formik.values.origin === 1 ? +transportation?.downtown : +transportation?.countryside))).toFixed(2)} 
                  component={grandTotalComponent}>             
                </Field>
              </div>
              <div className="checkout-order-table-submit">            
                {formik.values.paymentMethod === 'Cash' 
                  || formik.values.paymentMethod === 'ClickAway' 
                  || formik.values.paymentMethod === 'BankTransfer' ? 
                <GreenSubmitButton 
                  type="submit"
                  aria-label='Submit' 
                  disabled={formik.isSubmitting || !formik.isValid }
                  title={checkout.sendOrder}
                  color={Colors.secondary}
                ></GreenSubmitButton>
                :
              null} 
              </div>
            </div>      
          </div> 
          {formik.values.paymentMethod === 'PayPal' &&
          <div className= {`checkout-paypal ${!formik.isValid && "disable-paypal-buttons"}`}>  
            <div className="checkout-paypal-buttons">                            
              <PayPalButton
              aria-labelledby='Paypal'
                onClick={onClick}                 
                onApprove={approved}
                createOrder={createOrder}
                options={{                 
                  clientId: PRODUCTION_CLIENT_ID
                }}
              />
            </div>            
          </div>}
          
      </Form>  
      )}
      
      </Formik> 
     
      </>
    <div className="checkout-order-table-footer"/>      
  </>
  )
}

export default inject('feathersStore')(observer(Checkout));