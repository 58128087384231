import React, {useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import TopBar from '../../components/screenComponents/topBar/TopBar';
import CategoriesBar from '../../components/screenComponents/categoriesBar/CategoriesBar';
import Footer from '../../components/screenComponents/footer/Footer'
import RegistrationCard from '../../components/modals/registrationCard'
import Prompt from '../../components/modals/prompt'
import VerificationCode from '../../components/modals/verificationCode'
import { inject, observer } from "mobx-react";
import "./Home.css";
import AccessibilityBtn from '../../components/buttons/accessibilityBtn';
import MessagingBtn from '../../components/buttons/messagingBtn';


const Home = ({feathersStore})=> {
  
  useEffect(() => {
    const cart = localStorage.getItem('fdwa_cart');
    cart && feathersStore
      .setCartLength(JSON.parse(localStorage.getItem('fdwa_cart')).length);
  }, [])

  const [visiblePrompt, setVisiblePrompt] = useState(false);
  const [visibleRegister, setRegistrationModal] = useState(false);
  const [visibleVerification, setVisibleVerification] = useState(false);
  const [payload, setPayload] = useState({});  
   
  const togglePrompt = ()=> {  
    setVisiblePrompt(oldValue => !oldValue);      
  }

  const openRegistrationModal = ()=> {
    setVisiblePrompt(false);
    setRegistrationModal(true);
  }

  const closeRegistrationModal = ()=> {
    setRegistrationModal(false);
  }

  const openVerification = pload => {
    setRegistrationModal(false);
    setVisibleVerification(true);
    setPayload(pload);
  }
    
    const closeVerification = ()=> {
      setVisibleVerification(false);    
    }   

  const clickEnter = ()=> {      
    setVisiblePrompt(false)
  }  
  
  return(
    
    <div className="app-base">
      <Prompt 
        visible={visiblePrompt}      
        close={togglePrompt}
        onClickEnter={clickEnter}
        onClickAway={togglePrompt}
        onClickRegister={openRegistrationModal}      
        />
      <RegistrationCard
        visible={visibleRegister} 
        close={closeRegistrationModal}
        onClickAway={closeRegistrationModal}
        onClick={openVerification}
        />      
        <VerificationCode
          visible={visibleVerification}
          onClickCloseCode={closeVerification}         
          payload={payload}
        />      
      <TopBar onClickSignIn={()=> togglePrompt()}/>    
      <CategoriesBar/>  
      <Outlet/>
      <Footer/>
      <div className="accessibility-btn-container">
        <AccessibilityBtn/>
      </div>
      <div className="messaging-btn-container">
        <MessagingBtn/>
      </div>
    </div>
   
  )
}

export default inject('feathersStore')(observer(Home));