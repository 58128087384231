import React,{ useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '../../components/screenComponents/topBar/TopBar.css';
import language2 from '../img/languages/language-2.png';
import language1 from '../img/languages/language-1.png';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const style = { 
  positioning: {
    position: 'absolute',
    top: 30,
  }
};

const LanguageButton = ({feathersStore, showCaption})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleLanguage = lang => {
    feathersStore.setLanguage(lang);
    handleClose();
  }

  return(
  <>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}      
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} 
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
  //    sx={style.positioning} 
    >
      <MenuItem  onClick={() => toggleLanguage("el")}>  
        <span>
          <img aria-label='language greek' src={language2}  alt="..."/>
        </span>
        Ελληνικά
      </MenuItem>
      <MenuItem onClick={() => toggleLanguage("en")}>
        <span>
          <img aria-label='language english' src={language1}  alt="..."/>
        </span>
        English
      </MenuItem> 
    </Menu>       
    <button
      aria-labelledby='Dropdown'     
      className="nav-button-SignIn undecorated-link"              
      onClick={handleClick}
    >
      <span>
        {feathersStore.language === "en" ?
          <img aria-label='english' src={language1}  alt="English"/> :
          <img aria-label='greek' src={language2}  alt="Greek"/>
        }
        <span>{<ArrowDropDownIcon />}</span>       
      </span> 
      {showCaption && <span>{common.language}</span>}
    </button>  
  </> 
  )

}

export default inject('feathersStore')(observer(LanguageButton));