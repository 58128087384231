export const checkoutEL = { 
  less255: "Μέγιστο 255 χαρακτήρες",
  less50: "Μέγιστο 50 χαρακτήρες",
  less20: "Μέγιστο 20 χαρακτήρες",
  less10: "Μέγιστο 10 χαρακτήρες",
  required: "Απαιτείται",
  invalidEmail: "Λανθασμένο email",
  invalidPhone: "Λανθασμένος αριθμός",
  textareaMax: "Το πεδίο πρέπει να περιέχει λιγότερους από 1000 χαρακτήρες",
  invalidPayment: "Λανθασμένος τρόπος πληρωμής",
  terms: "Πρέπει να αποδεχθείτε τους όρους χρήσης",
  createAccount : "Δημιουργία λογαριασμού",
  differentDeliveryAddress : "Αποστολή σε διαφορετική διεύθυνση",
  orderComments : "Σχόλια παραγγελίας",
  payment : "Πληρωμή",
  cash : "Αντικαταβολή",
  cashText : "Πληρωμή με μετρητά κατά την παράδοση",
  clickAway : "Click Away",
  clickAwayText : "Παραλαβή από το κατάστημα",
  paypalRadio : "Πιστωτική κάρτα ή PayPal",
  paypalError : "Παρακαλώ συμπληρώστε σωστά όλα τα πεδία !",
  personalDataInfo : "Τα προσωπικά σας δεδομένα θα χρησιμοποιηθούν μόνο για τις ανάγκες αυτού του ιστοτόπου και για την διαχείριση του λογαριασμού σας.",
  readThe : "Διαβάστε την",
  iHaveRead : "Έχω διαβάσει και συμφωνώ με τους",
  ofThisSite : "του ιστοτόπου",
  yourOrder : "Η παραγγελία σας",
  sendOrder : "Αποστολη Παραγγελιας",
  alreadyCustomer : "Εγγεγραμμενος πελατης",
  orderCompleteTitle: "ΟΛΟΚΛΗΡΩΣΗ ΠΑΡΑΓΓΕΛΙΑΣ!",
  orderCompleteLine1: "Η παραγγελία σας καταχωρήθηκε με επιτυχία.",
  orderCompleteLine2: "Ευχαριστούμε για την προτίμησή σας.",
  isCompany: "Έκδοση Τιμολογίου",
  companyAfm: "Α.Φ.Μ.",
  companyDoy: "Δ.Ο.Υ.",
  companyName: "Επωνυμία",
  companyProfession: "Επάγγελμα",
  bankTransfer: "Άμεση Τραπεζική Μεταφορά",
  bankTransferText: "Κατάθεση σε έναν από τους τραπεζικούς μας λογαριασμούς",
  bankLargeText: "Κάντε την πληρωμή σας απευθείας στον τραπεζικό μας λογαριασμό. Παρακαλούμε χρησιμοποιήστε το ID της παραγγελίας σας ως αποδεικτικό πληρωμής. Η παραγγελία σας δε θα αποσταλεί μέχρι να γίνει εκκαθάριση του ποσού στο λογαριασμό μας.",
  bank: "Τράπεζα",
  nbgRadio: "Πιστωτική κάρτα μέσω Εθνικής Τράπεζας"
}