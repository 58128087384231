import React, {useEffect} from 'react'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import "./CartButton.css"


const CartButton = ({ feathersStore }) => {

  useEffect(() => {
    const cart = localStorage.getItem('fire_cart');
    cart && feathersStore
      .setCartLength(JSON.parse(localStorage.getItem('fire_cart')).length);
  }, []);

  return (
    <>
      <Link to="/cart" className="undecorated-link">
        <span className="nav-button-SignIn undecorated-link" aria-label='Cart button'>
          <ShoppingCartOutlinedIcon className="calendar-icon" style={{ fontSize: 30 }}/>
          <span className="nav-number">{feathersStore.cartLength}</span>
        </span>
      </Link>    
    </>
  )
}


export default inject('feathersStore')(observer(CartButton));