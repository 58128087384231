import React from 'react'
import Person from '@mui/icons-material/Person';


const SignInButton = ({onClickSignIn, displayText})=> {

  return(
    <button aria-labelledby='Sign in' type="button" className="nav-button-SignIn undecorated-link" onClick={onClickSignIn}>
      <Person className="calendar-icon" style={{ fontSize: 30 }}/>
      <span>{displayText}</span>
    </button>
  )
}

export default SignInButton;