import React from 'react';
import Colors from '../../components/assets/colors'
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const HistoryComponent = ({date, list, total, onClick, status, orderNumber, feathersStore})=> {
  
  let common = useTranslate(feathersStore.language, commonEL, commonEN);  

  const renderListItems = list.map((item, index)=> {
    return(        
      <div key={index} className="history-object-body">        
        <button aria-labelledby='Link to product'
        onClick={()=> item.enabled ? onClick(item.product_id) : {}} className="history-object-link">
          <span style={item.enabled ? {color: 'grey'} : { color : 'red' }}>{feathersStore.translate('en', item.title ,item.titleEnglish)} x {item.quantity}</span></button>       
        <span style={item.enabled ? {color: 'grey'} : { color : 'red' }}>{item.totalPrice}€</span>
      </div>
    )
  })

  return (
    <div className="history-object">
      <div className="history-object-topHeader">
        <span style={{color: Colors.settingsText}}>{common.order} #{orderNumber}&nbsp;{date}</span>
      </div>
      <div className="history-object-header">
        <span>{common.product}</span>
        <span>{common.subTotal}</span>
      </div>
      <div className="history-object-body-container">
        {renderListItems}
      </div>
      <div className="history-object-footer">
        <span style={{fontWeight: 'bold'}}>{common.total}:</span>
        <span>{total}€</span>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(HistoryComponent));