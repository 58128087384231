import React, {useEffect, useRef, useState} from 'react';
import { Routes, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { DEFAULT_EMAIL, DEFAULT_PSW } from "../components/assets/globalConstants";
import Home from './home/Home';
import HomeBody from '../components/screenComponents/homeBody/HomeBody';
import TermsNConditions from '../components/screenComponents/terms/termsnconditions';
import About from '../components/screenComponents/about';
import WishList from '../components/screenComponents/wishList/wishList';
import Contact from './contact/Contact';
import PrivacyPolicy from '../components/screenComponents/terms/privacypolicy';
import Faq from '../components/screenComponents/terms/faq';
import DeliveryOptions from '../components/screenComponents/terms/deliveryOptions';
import PaymentOptions from '../components/screenComponents/terms/paymentOptions';
import Cart from './cart/Cart'
import Product from './product/Product';
import Checkout from './checkout/Checkout'
import Account from './account/Account';

const Main = ({feathersStore})=> {

  const[computeFontSize, setComputeFontSize] = useState("html-font-size-0")

  useEffect(() => {    
    connectToServer();
    createPaymentMethodLS();
    getLanguage();   
  }, []);

  const connectToServer = async() => {
    await feathersStore.connect();    
    if(!feathersStore.isAuthenticated)feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
    .then(async(auth) => {         
          await feathersStore.setObservables();                          
    })
    .catch(error => window.alert(`Λανθασμένο login: ${error.message}`));   
  }

  const createPaymentMethodLS = () => {
    !localStorage.getItem('Payment_Method') &&      
      localStorage.setItem('Payment_Method',JSON.stringify({
          name: 'Μετρητά κατά την παράδοση',
          index: 0
      }))
  }

  const getLanguage = () => {
    if(!localStorage.getItem('Language')){ 
      const userLanguage = window.navigator.userLanguage || window.navigator.language;    
      console.log(userLanguage); 
      localStorage.setItem('Language', userLanguage.slice(0, 2));
    }
    feathersStore.setLanguage(localStorage.getItem('Language'))
  }
  
  const counter = useRef(0);

  useEffect(() => {
    if(feathersStore.htmlIncreaseFontSize){
      counter.current < 3 && counter.current++;
      feathersStore.setHtmlIncreaseFontSize(false);
    }else if(feathersStore.htmlDecreaseFontSize){
      counter.current > -3 && counter.current--;
      feathersStore.setHtmlDecreaseFontSize(false);
    }else if (feathersStore.htmlIncreaseFontSize === undefined){ //reset
      counter.current = 0;
      feathersStore.setHtmlIncreaseFontSize(false);      
    }
    counter.current < 4 && counter.current > -4 && setComputeFontSize(counter.current < 0 ?`html-font-size-minus-${-counter.current}` : `html-font-size-${counter.current}`)   
  }, [feathersStore.htmlIncreaseFontSize, feathersStore.htmlDecreaseFontSize])

  return(    
    <div className={`body-container 
        ${feathersStore.htmlGrayscale && 'html-greyscale'} 
        ${feathersStore.htmlBold && 'html-bold'} 
        ${feathersStore.htmlHighContrast && 'html-high-contrast'} 
        ${feathersStore.htmlNegativeContrast && 'html-negative-contrast'}
        ${computeFontSize}
        ${feathersStore.htmlLightBackground && 'html-light-background'}
        ${feathersStore.htmlAUnderline && 'html-a-underline'}       
      `}
    >
      <Routes>
        <Route path="/" element={<Home/>}>    
          <Route path="cart" element={<Cart/>}/>
          <Route path="about" element={<About/>}/>
          <Route path="product/:name" element={<Product/>}/>       
          <Route path="checkout" element={<Checkout/>}/>
          <Route path="wishList" element={<WishList/>}/>
          <Route path="contact" element={<Contact/>}/>
          <Route path="termsnconditions" element={<TermsNConditions/>}/>
          <Route path="privacypolicy" element={<PrivacyPolicy/>}/> 
          <Route path="paymentoptions" element={<PaymentOptions/>}/>
          <Route path="faq" element={<Faq/>}/>
          <Route path="deliveryoptions" element={<DeliveryOptions/>}/>      
          <Route  path="account"  element={<Account/>} />
          <Route path="Home/:name" element={<HomeBody/>} />
          <Route  path="Home" element={<HomeBody/>} />   
          <Route  index element={<HomeBody/>} />         
        </Route>        
      </Routes> 
    </div> 
  )
}

export default inject('feathersStore')(observer(Main));