import React from 'react'

const DeliveryOptionsText = ()=> {
  return(
    <div className="terms-text">
      <h2>Παράδοση & αποστολή</h2>
      <p>
        Στην εταιρία μας καταβάλλουμε κάθε δυνατή προσπάθεια για να εξυπηρετήσουμε το αγοραστικό μας κοινό. 
        Για αυτό το λόγο έχουμε συμπεριλάβει όσο το δυνατόν περισσότερους τρόπους αποστολής και παράδοσης των προϊόντων μας, 
        μεταξύ των οποίων και η δωρεάν μεταφορά με τα ίδια μέσα.
        Παρακαλούμε διαβάστε με προσοχή τους παρακάτω τρόπους απoστολής και τους περιορισμούς που ενδέχεται να έχουν.
      </p>    
      <h2>
        Παραλαβή από το κατάστημα μας
      </h2>
      <div>
        Μπορείτε να παραλαμβάνετε τις αγορές σας από το κατάστημά μας στην οδό Μεσογείων 131 στο Μαρούσι (Τ.Κ. 15126).
        <ul>
          <li>Δευτέρα-Τετάρτη-Σάββατο 7:00 – 16:00</li>
          <li>Τρίτη-Πέμπτη-Παρασκευή 7:00 – 19:00</li>
        </ul>
      </div>
      <h2>
        Αποστολή με courier
      </h2>
      <p>
        Αρκετά από τα προϊόντα μας αποστέλλονται με ταχυμεταφορική εταιρία courier. 
        Με την ΕΛ.ΤΑ courier η παραγγελία σας θα παραδοθεί κατευθείαν στη διεύθυνση που μας έχετε υποδείξει. 
        Η χρέωση κάθε αποστολής εξαρτάται από τον συνδυασμό μεγέθους και βάρους. Η αποστολή με courier δεν εμφανίζεται 
        στη σελίδα ολοκλήρωσης της παραγγελίας σας στην περίπτωση που το βάρος της παραγγελίας ξεπερνάει τα 7kg 
        ή στην περίπτωση που έχετε συμπεριλάβει έστω και ένα ογκώδες αντικείμενο που δεν μπορεί να σταλεί με courier.
      </p>
      <h2>
        Αποστολή με μεταφορική (εντός Αττικής)
      </h2>
      <div>
        Η αποστολή με μεταφορική αφορά παραγγελίες που ξεπερνούν το βάρος των 7kg και χωρίζονται σε τρεις κατηγορίες:
        <ul>
          <li>0 έως 5km, δωρεάν αποστολή</li>
          <li>5 έως 20km, 7,50 ευρώ</li>
          <li>20km και πάνω, 9,90 ευρώ</li>
        </ul>
      </div>
      <h2>
        Αποστολή με μεταφορική εκτός Αττικής
      </h2>
      <h2>
        Α. πρακτορείο μεταφορών επιλογής του πελάτη
      </h2>
      <p>
        Παραδίδουμε την παραγγελία σας έως τα γραφεία του πρακτορείου μεταφορών που θα μας υποδείξετε στην Αθήνα χωρίς καμία οικονομική επιβάρυνση από την έργα μας ως το πρακτορείο. 
        Τα έξοδα αποστολής του πρακτορείου βαραίνουν τον αγοραστή. Σε αυτήν την περίπτωση δεν ισχύει η αντικαταβολή.
      </p>
      <h2>
        Β. με συνεργαζόμενες μεταφορικές εταιρίες
      </h2>
      <p>
        Σε συνεργασία με μεταφορικές εταιρίες ή με πρακτορεία μεταφορών αναλαμβάνουμε να αποστείλουμε την παραγγελία σας έως την πόλη που μας έχετε ορίσει 
        και την παραλαμβάνετε εσείς από τα γραφεία της μεταφορικής. 
        Στις περιπτώσεις που αυτό δεν είναι εφικτό (απομακρυσμένες περιοχές, πόλεις εκτός δικτύου παράδοσης, κ.α), 
        τότε στέλνουμε την παραγγελία σας στα γραφεία της μεταφορικής στην πιο κοντινή πόλη της περιοχής σας 
        που υπάρχει στο δίκτυο παράδοσης απ’ όπου και θα την παραλάβετε. 
        Σε αυτήν την περίπτωση δεν ισχύει η αντικαταβολή.
      </p>
    </div>
  )
}
    
export default DeliveryOptionsText;