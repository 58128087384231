import React from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FaqText from './faqText';
import FaqTextEnglish from './faqTextEnglish';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {footerEL} from "../../../locales/el/footer";
import {footerEN} from "../../../locales/en/footer";

const Faq = ({ feathersStore })=> {

  let footer = useTranslate(feathersStore.language, footerEL, footerEN);
    
    return(
        <div className="settings-sub-container">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
            </div>
            <p className="terms-title">{`-${footer.faq}-`}</p>
            <div className="terms-container">
                {feathersStore.language === 'en' ? <FaqTextEnglish/> : <FaqText/>}
            </div> 
        </div>
    )
}

export default inject('feathersStore')(observer(Faq));