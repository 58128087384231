export const footerEL = {
    contactHours : "Ωράριο Επικοινωνίας",
    week : "Δευτέρα – Παρασκευή",
    contactUs : "Επικοινωνία",
    address : "Οδός Μεσογείων 131, 15126 Μαρούσι",
    findUs : "Βρείτε μας στον ",
    map : "χάρτη",
    contact: "Στείλτε μας μήνυμα",
    privacyPolicy: "Προσωπικά Δεδομένα",
    termsOfService: "Όροι Χρήσης",
    stayConnected: "Βρείτε μας",
    cookiesTitle: "Χρήση cookies",
    aboutUs : "Σχετικά με εμάς",
    cookiesText: "Χρησιμοποιούμε μόνο τα απολύτως απαραίτητα cookies. Σε κανένα από αυτά δεν αποθηκεύονται τα προσωπικά σας δεδομένα.",
    cookiesAccept: "Αποδοχή",  
    accessibilityTitle:  "Προσβασιμότητα",
    fontIncrease: "Αύξηση γραμμάτων",
    fontDecrease: "Μείωση γραμμάτων",
    greyscale: "Γκρι αποχρώσεις",
    highContrast: "Υψηλή αντίθεση",
    negativeContrast: "Αρνητική αντίθεση",
    lightBackground: "Φωτεινό φόντο",
    linksUnderline: "Υπογράμμιση συνδέσμων",
    readableFont: "Ευανάγνωστη γραμματοσειρά",
    reset: "Επαναφορά",
    paymentOptions: "Τρόποι Πληρωμής",
    deliveryOptions: "Τρόποι Αποστολής" ,
    messagingTitle: "Υποστήριξη",
    messagingSubTitle: "Στείλτε μας μήνυμα για οποιαδήποτε απορία σας!",
    message: "Μήνυμα",
    faq: "Συχνές Ερωτήσεις"      
}