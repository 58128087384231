import React, {useState, useEffect} from 'react'
import ProductCard from '../cards/productCard/ProductCard'
import ProductPageCounter from './productPageCounter';
import { inject, observer } from "mobx-react";
import { useNavigate } from 'react-router-dom';
import * as cloneDeep from 'lodash/cloneDeep';
import SmallAlert from '../modals/smallAlert';
import Popular from './popular';
import Pagination from '@mui/material/Pagination';
import QrCode from './QrCode';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

let initialCartBiggestId = 0;
let cart = [];

const ListProducts = ({ feathersStore })=> {  

  let common = useTranslate(feathersStore.language, commonEL, commonEN); 
  let navigate = useNavigate();

  const [products, setProducts] = useState([]);  
  const [popularProducts, setPopularProducts] = useState([]);
  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);
  
  useEffect(() => {
    if(localStorage.getItem('fire_cart')){
      cart = JSON.parse(localStorage.getItem('fire_cart'));    
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }
  }, []);
  
  useEffect(()=>{
    feathersStore.isAuthenticated && fetchProducts();
  },[feathersStore?.searchCategory, feathersStore.isAuthenticated, feathersStore?.favoritesArray,
    feathersStore.productUpdatedEvent, feathersStore.productCreatedEvent]);  

  const fetchProducts = async() => {       
    let feathersProducts;    
    if(feathersStore.searchCategory?.categoryId){
      const { categoryId, searchText } = feathersStore?.searchCategory;
      feathersProducts = await feathersStore.productsPerCategory(+categoryId, searchText);     
    }
    else {
      feathersProducts = await feathersStore.products(feathersStore.searchCategory?.searchText);          
    }
    setProducts([...feathersProducts]);
   
    const feathersPopularProducts = await feathersStore.popularProducts();
    setPopularProducts([...feathersPopularProducts]);
  }
  

  const getMainImage = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images)
        .flat()
        .filter(image => image.focused);        
    }else{
      imageArr = product.images
      .filter(image => image.focused);       
    }  
    return imageArr && imageArr[0];
  }

  const getBadges = product => {    
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.badges;
    }
    return product.badges;
  }

  const getOldPrice = product => {
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.oldPrice;
    }
    return product.oldPrice;
  }

  const getPrice = (product) => {      
    if(product.hasVariation){
      let priceArr = [];
      priceArr = product.variations
        .map(variation => +variation.retailPrice)
        const low = Math.min(...priceArr)
        const high = Math.max(...priceArr)
        if(low === high){
            return high.toFixed(2)
        }else{
            return low.toFixed(2) + " - " + high.toFixed(2)
        }          
    }else{
        return (+product.retailPrice).toFixed(2)
    }
  }

  const handlePress = (product)=> {
    navigate(`/product/${feathersStore.translate('en', product.name, product.nameEnglish)}`,{
      state: {id: `${product._id}`,  payload: product}     
    }); 
  }

  const onPressAddToFavorites = async product => {    
    let id = '';
    id = product._id;
    if(feathersStore.favoritesArray.includes(id)){
      const index = feathersStore.favoritesArray.indexOf(id);
      if (index > -1) {
        feathersStore.favoritesArray.splice(index, 1);
      }     
    }else{
      feathersStore.favoritesArray.push(id);  
    }   
    if(feathersStore.wishlistId === ""){
        const data = await feathersStore.createWishlist();        
        data && (feathersStore.wishlistId = data._id); 
    }else await feathersStore.updateWishlist();
    setFavoritesUpdated(true);
  };

  const handleAddToCart = (prod, index) => { 
   
    let cartItem = {
      id: initialCartBiggestId,
      product_id: prod._id,
      title: prod?.name,
      titleEnglish: prod?.nameEnglish,
      productSKU: prod?.productSKU,
      image: prod.images?.find(img => img.focused),
      price: prod?.retailPrice,
      quantity: 1,
      totalPrice: (+prod.retailPrice).toFixed(2),
      variationTitle: null,
      variation: null,
      stock: prod?.handleStock && prod?.stock,
      extra: [],
      handleStock: prod?.handleStock
    }
  
    const cartSimilarItem = cart.find(item => item.product_id === cartItem.product_id );  
    if(cartSimilarItem){  
      const newQ = +cartSimilarItem.quantity + 1;            
      if(prod.handleStock && (newQ > cartItem.stock)){
        setWarning(index);   
        return;
      }else{
        const cartIndex = cart.findIndex(item => item.id === cartSimilarItem.id);
        cartItem.quantity = newQ;
        cartItem.totalPrice = (+cartItem.price * newQ).toFixed(2);
        cart.splice(cartIndex, 1, cartItem);
      }          
    }else{   
      cart.push(cartItem);
      feathersStore.setCartLength( feathersStore.cartLength + 1);    
    } 
    if(!prod.warning) {
      localStorage.setItem("fire_cart", JSON.stringify(cart));
      setCartIncreased(true);      
    }   
  }

  const setWarning = (index) => {
    let productsClone = [...products];
    let productClone = cloneDeep(products[index]);
    Object.assign(productClone, {warning: true});
    productsClone.splice(index, 1, productClone);
    setProducts([...productsClone]);
  }

  const removeWarning = (index) => {
    let productsClone = [...products];
    let productClone = cloneDeep(products[index]);
    Object.assign(productClone, {warning: false});
    productsClone.splice(index, 1, productClone);
    setProducts([...productsClone]);
  }

//----------------Pagination Logic---------------------------------
  const [productsPerPage, setProductsPerPage] = useState(12)
  const [currentPage, setCurrentPage] = useState(0)  
  const [pageCount, setPageCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [tableData, setTableData] = useState([]);  
  
  useEffect(()=>{
    setCurrentPage(0);   
    setOffset(0);    
  },[feathersStore?.searchCategory]);  
  
  useEffect(()=> {      
      setPageCount(Math.ceil(products.length / productsPerPage))        
  },[products.length, productsPerPage])
  
  useEffect(()=> {
    const productsClone = [...products];      
    setTableData(productsClone.slice(offset, offset + productsPerPage))
  },[products, currentPage])

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
    setOffset((value - 1) * productsPerPage)
  };
//---------------------------------------------------------------------

  const renderProducts = tableData.map( (product, index) => (

    
      <ProductCard
        key={product._id}
        stock={product.handleStock && product.stock}
        image={getMainImage(product)}
        title={(feathersStore.language === 'en') && product?.nameEnglish ? product.nameEnglish : product.name}
        price={getPrice(product)}
        oldPrice={getOldPrice(product)}
        onClick={()=> handlePress(product)}
        onClickCart={()=> handleAddToCart(product, index)}
        hasVariation={product.hasVariation}
        hasOtherFeatures={product.hasOtherFeatures}
        onClickForward={()=> handlePress(product)}
        warning={product.warning}
        onCloseWarning={() => removeWarning(index)}
        favorite={feathersStore.favoritesArray.includes(product._id)}
        onPressAddToFavorites={() => onPressAddToFavorites(product)}
        badges={getBadges(product)}
      />
  
  )
);
    
  return(
    <>       
      <ProductPageCounter
        currentPage={currentPage}
        productsPerPage={productsPerPage}
        dataLength={tableData.length}
        listlength={products.length}
      />
      <div className="listproducts-container">
        {renderProducts}
      </div> 
      <Pagination
        style={{margin: '2em auto 3em'}}
        count={pageCount}     
        page={currentPage + 1}
        onChange={handleChange}
        variant="outlined" 
        shape="rounded" 
      />      
      {feathersStore.isAuthenticated && popularProducts?.length > 0 &&
      <div className="popular-container">
        <Popular
          handleAddToCart={handleAddToCart}
          getPrice={getPrice}
          getOldPrice={getOldPrice}
          getBadges={getBadges}
          handlePress={handlePress}
          popularProducts={popularProducts} 
          onPressAddToFavorites={onPressAddToFavorites}         
        />
      </div>}
      <QrCode />
      <SmallAlert 
        show={cartIncreased} 
        styling="success"
        heading={common.updated} 
        onClose={() => setCartIncreased(false)} 
        message={common.cartUpdated} />
      <SmallAlert 
        show={favoritesUpdated} 
        styling="success"
        heading={common.updated} 
        onClose={() => setFavoritesUpdated(false)} 
        message={common.wishlistUpdated} />
    </>
  )
}

export default inject('feathersStore')(observer(ListProducts));