import React, {useState, useEffect} from 'react';
import {
  FormControl,
  FormHelperText,
  Input,   
  InputAdornment,
  IconButton 
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils';

let passwordElement;
let newPasswordElement;
let repeatPasswordElement;

const ChangePassword = ({ feathersStore })=> {

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);  
  const [secondTime1, setSecondTime1] = useState(false);
  const [secondTime2, setSecondTime2] = useState(false);

  useEffect(() => {
    passwordElement.focus();
  }, []);

  const passwordChange = event => {    
    setPassword(event.target.value);
   secondTime1 && passwordValidation(event.target.value);   
  };

  const newPasswordChange = event => {    
    setNewPassword(event.target.value);
    secondTime2 && newPasswordValidation(event.target.value);   
  };

  const repeatPasswordChange = event => {    
    setRepeatPassword(event.target.value);      
  };

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

 const newPasswordValidation = val => {
  if (!Utils.validatePassword(val) ) {
    setNewPasswordError(true);
  }else{
    setNewPasswordError(false);
    }
  } 

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const enterPasswordPressed = (ev) => {   
    if (ev.key === 'Enter') {
        newPasswordElement.focus();
    }
  }

  const enterNewPasswordPressed = (ev) => {   
    if (ev.key === 'Enter') {
        repeatPasswordElement.focus();
    }
  }

  const enterRepeatPasswordPressed = (ev) => {   
    if (ev.key === 'Enter') {
        
    }
  }
  
  const newPasswordFocus = () => {     
    setSecondTime1(true);
    passwordValidation(password);   
  };

  const repeatPasswordFocus = () => { 
    setSecondTime2(true);
    newPasswordValidation(newPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClick = async () => {
    setLoading(true);
    if (newPassword === repeatPassword){
      try{
        await feathersStore.changePassword(newPassword);
        setLoading(false);
        alert('Επιτυχής αλλαγή password');
      }catch (error){
        setLoading(false);
        alert(`Ανεπιτυχής αλλαγή password, ${error.message}`)
        resetFields();  
      }
    }else{
     // setRepeatPasswordError(true);
      setLoading(false);
      resetFields();      
      alert("Το επαναληπτικό password δεν ταιριάζει");
           
    }
  }

  const resetFields = () => {
    setPassword("");
    setNewPassword("");
    setRepeatPassword("");
    setSecondTime1(false);
    setSecondTime2(false);
    setPasswordError(false);
    setNewPasswordError(false);
    setRepeatPasswordError(false);
    setShowPassword(false);
    setShowNewPassword(false);
    setShowRepeatPassword(false);
  }

  return(
    <div className="column-flex-center">
      <div className="settingsFieldSub">
        <FormControl fullWidth={false}>
          <Input
            value={password}
            error={passwordError}
            fullWidth={true}              
            inputRef={r => {
              passwordElement = r;
            }}
            aria-label='password'
            type={showPassword ? 'text' : 'password'}
            onChange={passwordChange}                 
            onKeyPress={enterPasswordPressed}              
            placeholder="Password" 
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {passwordError && 
          <FormHelperText id="component-error-text" color="secondary">at least 8 chars including numbers, uppercase and lowercase</FormHelperText>}
        </FormControl>
        <FormControl fullWidth={false}>
          <Input
            value={newPassword}
            error={newPasswordError} 
            fullWidth={true}             
            inputRef={r => {
              newPasswordElement = r;
            }}
            aria-label='new password'
            type={showNewPassword ? 'text' : 'password'}
            onChange={newPasswordChange}
            onFocus={newPasswordFocus}          
            onKeyPress={enterNewPasswordPressed}              
            placeholder="Νέο Password" 
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {newPasswordError && 
          <FormHelperText id="component-error-text" color="secondary">at least 8 chars including numbers, uppercase and lowercase</FormHelperText>}
        </FormControl>
        <FormControl fullWidth={false}>
          <Input
            value={repeatPassword}
            error={repeatPasswordError}   
            fullWidth={true}           
            inputRef={r => {
              repeatPasswordElement = r;
            }}
            aria-label='repeat password'
            type={showRepeatPassword ? 'text' : 'password'}
            onChange={repeatPasswordChange}
            onFocus={repeatPasswordFocus}          
            onKeyPress={enterRepeatPasswordPressed}              
            placeholder="Επανάληψη Νέου Password" 
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRepeatPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          /> 
        {repeatPasswordError && 
          <FormHelperText id="component-error-text" color="secondary">No match</FormHelperText>}       
        </FormControl>     
        <GreenSubmitButton 
        aria-labelledby='Save'
          title={'ΑΠΟΘΗΚΕΥΣΗ'}
          loading={loading}
          disabled={!password || !newPassword || !repeatPassword || passwordError || newPasswordError || repeatPasswordError}
          onClick={onClick}/>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(ChangePassword));