import React from 'react'
import AddToCartSmall from '../../buttons/addToCartSmall'
import Colors from '../../assets/colors'
import AddToWishSmall from '../../buttons/addToWishSmall'
import NavigateNext from '@mui/icons-material/NavigateNext';
import { inject, observer } from "mobx-react";
import './ProductCard.css';
import { API_URL } from "../../assets/globalConstants";
import SmallAlert from "../../modals/smallAlert";
import soldOutImage from '../../img/sold-out.png';

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";

const ProductCard = ({stock, hasVariation, hasOtherFeatures, onClickForward, title, onPressAddToFavorites, itemId,
  warning, price, oldPrice, image, onClick, onClickCart, onCloseWarning, favorite, feathersStore, badges})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN); 
    
  return(
    <div className="product-card">     
      <button onClick={onClick} className="shoppingCart-button" aria-labelledby='Shopping button'>
        <img aria-label='product' alt="" src={`${API_URL}/images/${image?.name}`} />
        {(badges?.includes('new')) &&  (
          <div className="new-label-container">
            <p className="label">NEW</p>
          </div>)
        }
        {((badges?.includes('sale')) || oldPrice) &&  (
          <div className="discount-label-container">
            <p className="label">SALE</p>
          </div>)
        }
        {(badges?.includes('hot')) &&  (
          <div className="hot-label-container">
            <p className="label">HOT</p>
          </div>)
        }          
      </button>      
      <div className="title-container">
        <p>{title}</p>
      </div>
      <div className="product-price-container">
        <div className="prices-container">
          {oldPrice &&
            <p className="old-price">
              <span>{oldPrice}€</span></p>
          }
          <p style={{color: Colors.amazonColor}}>
            {price}€</p> 
        </div>    
        {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
          <AddToWishSmall
            onClick={onPressAddToFavorites}
            favorite={favorite}
          />}          
        {stock !== 0? <>{hasVariation || hasOtherFeatures ?       
          <button className="shoppingCart-button" onClick={onClickForward} aria-labelledby='Next'>
            <NavigateNext style={{color: Colors.amazonColor, fontSize: 36, marginBottom:-10}}/>
          </button>
          :                
          <AddToCartSmall onClick={onClickCart} aria-labelledby='Add to cart'/> }</>
          :
          <img aria-label='sold out' alt="" className="soldout-img" src={soldOutImage} />
        }
      </div>
      <SmallAlert
        show={warning} 
        styling="warning"
        heading={common.warning} 
        onClose={onCloseWarning} 
        message={common.stockWarning}
     />    
      
  </div>
  )
}

export default inject('feathersStore')(observer(ProductCard));