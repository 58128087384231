import React from 'react'
import LocalShipping from '@mui/icons-material/LocalShipping'
import Payment from '@mui/icons-material/Payment'
import Colors from '../../assets/colors';
import "./SiteOffers.css";

export const FreeShipping = ({ header, body })=> {

    return(
        <div className="offer-box">
            <LocalShipping style={{fontSize: 40, color: Colors.amazonColor}}/>
            <div className="offers-title">{header}</div>
            <div className="offers-text">{body}</div>
        </div>
    )
}

export const CashOnDelivery = ({ header, body })=> {

    return(
        <div className="offer-box">
            <Payment style={{fontSize: 40, color: Colors.amazonColor}}/>
            <div className="offers-title">{header}</div>
            <div className="offers-text">{body}</div>
        </div>
    )
}