import React, {useState, useEffect} from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddressComponent from './addressComponent';
import AddIcon from '@mui/icons-material/Add';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import RoundedButton from '../../components/buttons/roundedButton';
import { inject, observer } from "mobx-react";
import AddOrEditaddressModal from '../../components/modals/addOrEditAddressModal/AddOrEditAddressModal';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {accountEL} from "../../locales/el/account";
import {accountEN} from "../../locales/en/account";

const Address = ({onClickClose, onClickAddNew, onClickLocation, feathersStore}) => {

  let account = useTranslate(feathersStore.language, accountEL, accountEN);

  const [addresses, setAddresses] = useState([]);  
  const [showButtons, setShowButtons] = useState(false);
  const [visibleAddNewAddress, setAddNewAddress] = useState(false);
  
  useEffect(() => {
    feathersStore.user?.addresses && setAddresses([...feathersStore.user?.addresses]);
  }, [feathersStore.user?.addresses])

  const openNewAddress = () => {     
    setAddNewAddress(true); 
  };

  const closeNewAddress = () =>{
    setAddNewAddress(false); 
  }

  const ClickAddAddress = ()=> {
      setShowButtons(!showButtons);
  }

  const setDeliveryAddress = item => () => {    
    const index = addresses.indexOf(item);
    const activeIndex = addresses.findIndex(e => e.active === true);
    let clonedAddresses = [...addresses];

    if (activeIndex !== index) {
      clonedAddresses[activeIndex].active = false;
      clonedAddresses[index].active = true;      
      updateUser(clonedAddresses);   
    }
  };

  const updateUser = async newAddresses => {
    setAddresses([...newAddresses]); 
    let clonedUser = JSON.parse(JSON.stringify(feathersStore.user));
    clonedUser.addresses = [...newAddresses];
    feathersStore.setUser(clonedUser); 
    await saveActiveAddress(newAddresses); 
  }

  const saveActiveAddress = async val => {   
    await feathersStore.patchAddresses( feathersStore.user._id, [...val]);   
  };
   
  const renderAddressItem = ( item, index ) => (
   
    <AddressComponent
      key={index}
      onPress={setDeliveryAddress(item)}     
      title={item.title === "Οικία" ? account.home : item.title === "Έργασία" ? account.work : account.otherLocation}    
      street={item.street}
      city={item.city}
      apartment={item.apartment}
      postcode={item.postcode}
      streetNumber={item.streetNumber}
      active={item.active} 
      item={item}    
    />
    );
    
  return (
    <div className="history-container">
    <div className="history-object-container"> 
      <FormControl component="fieldset" style={{width: '100%'}}>
        <FormLabel component="legend"/>
        <RadioGroup aria-label="addresses" name="addresses"  onChange={setDeliveryAddress}>
          {addresses.length !=0 ? addresses.map(renderAddressItem) 
          : <div className="empty-component-text">{account.addAddress}</div> }
        </RadioGroup>
      </FormControl>
      </div>
      <div className="addressAddContainer">
        {showButtons &&
        <div className="addAddressOptions">
          <div className="addAddressOptionsButton">
            <div className="addAddressOptionsButton-label">{account.addNewAddress}</div>
            <RoundedButton
              aria-label='Open new address'
              color={'#00b970'}
              icon={<HomeIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
              onClick={openNewAddress}
            />
          </div>
          <div className="addAddressOptionsButton">
            <div className="addAddressOptionsButton-label">{account.myLocation}</div>
            <RoundedButton
            aria-label='new location'
              color={'#ffa400'}
              icon={<LocationOnIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
              onClick={onClickLocation}
            />
          </div>
        </div>}
        <RoundedButton
        aria-labelledby='Close Add'
          color={'#0069b9'}
          icon={showButtons?
            <CloseIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>
            :
            <AddIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
          onClick={ClickAddAddress}
        />
      </div> 
      <AddOrEditaddressModal visible={visibleAddNewAddress} close={closeNewAddress} itemToEdit={{}}/>   
    </div>

  
  );
}

export default inject('feathersStore')(observer(Address));
