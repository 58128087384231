import React, { useEffect, useState } from 'react'
import HistoryComponent from './historyComponent';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";

// Translations
import useTranslate from '../../hooks/useTranslate';
import {accountEL} from "../../locales/el/account";
import {accountEN} from "../../locales/en/account";


const History = ({ feathersStore })=> {

  let account = useTranslate(feathersStore.language, accountEL, accountEN);  
  
  let navigate = useNavigate();

  const [orders, setOrders] = useState(null);
  
  useEffect (() => {
    initOrders();
  }, []);

  const initOrders = async() => {
    let orders = await feathersStore.ordersPerUser();         
    setOrders(orders);
  }      

  const goToProduct = product_id => {   

    feathersStore.getProduct(product_id).then(prod => {
      prod && navigate(`/product/${(feathersStore.language === 'en') && prod?.nameEnglish ?
      prod.nameEnglish : prod.name}`,{
        state: {id: `${prod._id}`,  payload: prod}     
    });  
    });
  }

  const renderItem = orders?.map(item =>      
    <HistoryComponent 
      key={item._id}
      orderNumber={item._id.slice(-6)}
      date={new Date(item.createdAt).toLocaleDateString()}
      list={item.items}
      total={item.total}
      onClick={goToProduct}
      status={item.status}
    />
  )

  return(    
    <div className="history-object-container">
      {orders?.length === 0 ? <div className="empty-component-text">{account.emptyHistory}</div> : renderItem}
    </div>   
  )
}

export default inject('feathersStore')(observer(History));