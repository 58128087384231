import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';

const TreeItemCustom = ({key, nodeId, lbl, onLabelClick , collapseIcon, expandIcon, feathersStore, nodes, renderTree}) => {

    const { data, loading } = useGoogleTranslate(feathersStore.language, lbl);

    const CustomContent = React.forwardRef(function CustomContent(props, ref) {
      const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
      } = props;
    
      const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
      } = useTreeItem(nodeId);
    
      const icon = iconProp || expansionIcon || displayIcon;
    
      const handleMouseDown = (event) => {
        preventSelection(event);
      };
    
      const handleExpansionClick = (event) => {
        handleExpansion(event);
      };
    
      const handleSelectionClick = (event) => {
        handleSelection(event);
      };
    
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={clsx(className, classes.root, {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.focused]: focused,
            [classes.disabled]: disabled,
          })}
          onMouseDown={handleMouseDown}
          ref={ref}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div onClick={handleExpansionClick} className={classes.iconContainer}>
            {icon}
          </div>
          <Typography
            onClick={handleExpansionClick}
            component="div"
            className={classes.label}
          >
            {label}<span><Button onClick={onLabelClick}><ArrowForwardIcon/></Button></span>
          </Typography>
        </div>
      );
    });
    
    CustomContent.propTypes = {
      /**
       * Override or extend the styles applied to the component.
       */
      classes: PropTypes.object.isRequired,
      /**
       * className applied to the root element.
       */
      className: PropTypes.string,
      /**
       * The icon to display next to the tree node's label. Either a parent or end icon.
       */
      displayIcon: PropTypes.node,
      /**
       * The icon to display next to the tree node's label. Either an expansion or collapse icon.
       */
      expansionIcon: PropTypes.node,
      /**
       * The icon to display next to the tree node's label.
       */
      icon: PropTypes.node,
      /**
       * The tree node label.
       */
      label: PropTypes.node,
      /**
       * The id of the node.
       */
      nodeId: PropTypes.string.isRequired,
    };
    

    return(
        <TreeItem 
          key={key} 
          nodeId={nodeId} 
          label={loading ? 'Loading...' : data}
          ContentComponent={CustomContent}        
        //  onClick={onLabelClick}
          collapseIcon={collapseIcon}
          expandIcon={expandIcon}         
        >
            {Array.isArray(nodes.childrens) ? nodes.childrens.map((node) => renderTree(node)) : null}
        </TreeItem>
      )

}

export default inject('feathersStore')(observer(TreeItemCustom));