import React from 'react'

const CheckoutComponent = ({sku, title, quantity, total})=> {

    return(
        <div className="checkout-order-table-component">
            <span id="ch-tb-price">{sku} - {title} x {quantity}</span>
            <span>{total}€</span>
        </div>
    )
}

export default CheckoutComponent;