import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";
import Box from '@mui/material/Box';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const AddressDeleteModal = ({visible, onClickNo, itemToDelete, feathersStore})=> {

  let common = useTranslate(feathersStore.language, commonEL, commonEN);
  
  const [loading, setLoading] = useState(false); 

  const deleteAddress = async () => {
    setLoading(true);  
    let clonedAddresses=[...feathersStore.user?.addresses]; 
    if(itemToDelete){
      const index = clonedAddresses.indexOf(itemToDelete);
      clonedAddresses.splice(index, 1);
    } 
    try{
      const user = await feathersStore.patchAddresses( feathersStore.user._id, [...clonedAddresses]);
      feathersStore.setUser(user);
      setLoading(false);   
      alert("Επιτυχής Διαγραφή Διεύθυνσης");
    }catch (error){
      setLoading(false);   
      alert(`Αποτυχία Ενημέρωσης:  ${error.message}`)
    }
  };    
    
  return (     
    <Dialog     
      open={visible}
      onClose={onClickNo}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"     
    >     
      <DialogTitle id="alert-dialog-title">{common.danger}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {common.deleteAddress}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GreenSubmitButton
          title={common.no}
          loading={false}
          disabled={false}
          onClick={onClickNo}
          autoFocus
          aria-labelledby='No'
        />
        <h3 style={{color: 'grey'}}>|</h3>
        <GreenSubmitButton
          title={common.yes}
          loading={loading}
          disabled={false}
          onClick={deleteAddress}
          aria-labelledby='No'
        />
      </DialogActions>     
    </Dialog>
  );
}

export default inject('feathersStore')(observer(AddressDeleteModal));