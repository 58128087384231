import React, {useEffect, useState}from 'react'
import CategoriesImageButton from '../../buttons/categoriesImageButton/CategoriesImageButton';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { useNavigate } from 'react-router-dom';

const ImageCategories = ({ feathersStore })=> {

  let navigate = useNavigate();

  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const childrenNodes = feathersStore.categories?.map(node => node.childrens).flat();
    const allNodes = [...feathersStore?.categories, ...childrenNodes];
    const nodesToShow = allNodes.filter(node => node.options.frontPage);
    setNodes([...nodesToShow]);    
  }, [feathersStore?.isAuthenticated, feathersStore?.categories])

    
  const handlePress = node => {     
    const payload =  {    
      categoryId: node.id,  
      title: node.name, 
      titleEnglish: node.options.categoryNameEnglish,
      searchText: ""
    }; 
    feathersStore.setSearchCategory(payload);
    navigate(`/Home/${node.name}`);    
  }

  let renderImageCategories = nodes.map((node)=>{
    return (     
      <CategoriesImageButton
        key={node.id}
        image={node.options.href}
        onClick={()=> handlePress(node)}
      />     
    )
  });

  return(
      <>
      {renderImageCategories}
      </>
  )
}

export default inject('feathersStore')(observer(ImageCategories));