import React, {useEffect} from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PrivacyText from './privacyText';
import PrivacyTextEnglish from './privacyTextEnglish';
import { inject, observer } from "mobx-react";
import "../../../screens/account/Account.css";

// Translations
import useTranslate from '../../../hooks/useTranslate';
import {footerEL} from "../../../locales/el/footer";
import {footerEN} from "../../../locales/en/footer";

const PrivacyPolicy = ({ feathersStore })=> {

  let footer = useTranslate(feathersStore.language, footerEL, footerEN);

    useEffect(()=> {     
      return(() => {
        if(!feathersStore.showCookies){
          const showCookies = localStorage.getItem('show_cookies');
          showCookies !== "false" && feathersStore.setShowCookies(true);      
        } 
      })   
    },[]);
    
    return(
        <div className="settings-sub-container">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
            </div>
            <p className="terms-title">{`-${footer.privacyPolicy}-`}</p>
            <div className="categoryView">
                {feathersStore.language === 'en' ? <PrivacyTextEnglish/> : <PrivacyText/>}
            </div> 
        </div>
    )
}

export default inject('feathersStore')(observer(PrivacyPolicy));