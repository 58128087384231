import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import Box from '@mui/material/Box';

// Translations
import useTranslate from '../../hooks/useTranslate';
import {commonEL} from "../../locales/el/common";
import {commonEN} from "../../locales/en/common";

const style = {
  position: 'absolute',
   width: {
    xs: '100%',
    sm: '80%', 
    md: 400,  
  },
  height: 6150,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'},   
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24  
};

const VerificationErrorModal = ({visible, exit, resend, feathersStore})=> {  

  let common = useTranslate(feathersStore.language, commonEL, commonEN); 
    
  return (
    <section>
      <Modal 
        open={visible} 
        onClose={exit}
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
      <Box sx={style}>
        <div className="modalContainer">
          <div className="modalTitleContainer">
            <p style={{color: AppColors.secondary}}>{common.errorCode}</p>
          </div>
          <div className="confirmationModalButtonsView">
            <Button 
              aria-labelledby='Resend'
              style={{color: AppColors.amazonColor, fontSize: 18}} 
              onClick={resend}
            >{common.repeat}</Button>
            <h3 style={{color: AppColors.secondary, marginTop: 10}}>|</h3>
            <Button
              aria-labelledby='Exit'
              style={{color: AppColors.red, fontSize: 18}} 
              onClick={exit}
            >{common.exit}</Button>
          </div>
        </div>
      </Box>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(VerificationErrorModal));