import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import './CategoriesBar.css';
import { CircularProgress } from '@mui/material';
import CategoriesMenu from "../../cards/categoriesMenu";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { useLazyTranslate } from 'react-google-translate';
import useGoogleTranslate from '../../../hooks/useGoogleTranslate';
import useTranslate from '../../../hooks/useTranslate';
import {commonEL} from "../../../locales/el/common";
import {commonEN} from "../../../locales/en/common";

const CategoriesBar = ({feathersStore})=> {

  let navigate = useNavigate();
  //const { data, loading } = useGoogleTranslate(feathersStore.language, 'Home');
  let common = useTranslate(feathersStore.language, commonEL, commonEN);

  const resetProducts = () => {
    feathersStore.setSearchCategory({searchText: "", title: commonEL.all, titleEnglish: commonEN.all}); 
   navigate("/"); 
  } 
   
  const renderCategoryItem = (node) => (   
    <div key={node.id}>
      <CategoriesMenu node={node}/>
    </div>
  );

  return(
    <>
      <div className="cat-bar-container">
        <div 
          onClick={() => resetProducts()} 
          className={`category-view-item category-text ${!feathersStore.searchCategory?.title && 'category-text-visited'}`}
        >      
          <span >          
              <HomeIcon />
          </span>  
          <span>
            {common.home}           
          </span>   
        </div>
        {feathersStore.categories ? 
          feathersStore.categories?.map(renderCategoryItem) 
          :
          <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}            
          />
        }        
      </div>      
    </>
  )


}

export default inject('feathersStore')(observer(CategoriesBar));